import React from "react";
import ReactDOM from "react-dom";
import { Grid, TextField } from "@material-ui/core";
import {
  FormControl,
  InputLabel,
  FormHelperText,
  MenuItem,
  Select as MuiSelect,
} from "@material-ui/core";
import {
  Background,
  ModalWrapper,
  Head,
  IconContainer,
  ButtonContainer,
  StyledForm,
} from "./Styles";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

/* Componentes */
import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";

/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { Inputs } from "../../Input/Inputs";
import { crearPesaje } from "../../../Services";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  entrada: yup.string().required("Campo requerido"),
  fecha_hora: yup.date(),
});

export const ModalCrearPesaje = ({ onClose }) => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      peso: "",
      entrada: "",
      fecha_hora: "",
    },

    validationSchema: validationSchema,

    onSubmit: (values) => {
      if (values.fecha_hora !== "") {
        // Convertimos la fecha y hora ingresadas por el usuario en un objeto Date
        const fechaHoraSeleccionada = new Date(values.fecha_hora);

        // Obtenemos los componentes de la fecha y hora
        const year = fechaHoraSeleccionada.getFullYear();
        const month = fechaHoraSeleccionada.getMonth() + 1; // Los meses van de 0 a 11, por eso sumamos 1
        const day = fechaHoraSeleccionada.getDate();
        const hours = fechaHoraSeleccionada.getHours();
        const minutes = fechaHoraSeleccionada.getMinutes();
        const seconds = fechaHoraSeleccionada.getSeconds();

        // Formateamos los componentes de la fecha y hora con ceros a la izquierda si es necesario
        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

        // Construimos la cadena con el formato deseado
        const formattedDateTime = `${year}-${formattedMonth}-${formattedDay}T${formattedHours}:${formattedMinutes}:${formattedSeconds}`;

        // Reemplazamos la fecha y hora en el objeto values con la versión formateada
        values.fecha_hora = formattedDateTime;
      } else {
        // Si no se ha ingresado una fecha y hora, asignamos null
        values.fecha_hora = null;
      }
      console.log(values);
      dispatch(crearPesaje(values.peso, values.entrada, values.fecha_hora));
      onClose();
    },
  });
  const handleClose = () => {
    onClose();
  };

  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper onClose={() => handleClose()}>
        <Head>
          <p>Ingresar un pesaje offline</p>
          <IconContainer>
            <FontAwesomeIcon icon={faTimes} onClick={onClose} />
          </IconContainer>
        </Head>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid container md={12} sm={8} justify="center">
            <Inputs
              label="Peso (Tn.)"
              name="peso"
              type="number"
              step="0.01"
              value={formik.values.peso}
              onChange={formik.handleChange}
              error={formik.touched.peso && Boolean(formik.errors.peso)}
            />
            {formik.touched.peso && formik.errors.peso && (
              <FormHelperText error={formik.errors.peso && formik.touched.peso}>
                {formik.errors?.peso}
              </FormHelperText>
            )}

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="entrada">Balanza</InputLabel>
                <MuiSelect
                  labelId="entrada"
                  name="entrada"
                  value={formik.values.entrada}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.entrada && Boolean(formik.errors.entrada)
                  }
                >
                  <MenuItem value={true}>Entrada</MenuItem>
                  <MenuItem value={false}>Salida</MenuItem>
                </MuiSelect>
                {formik.touched.entrada && formik.errors.entrada && (
                  <FormHelperText
                    error={formik.errors.entrada && formik.touched.entrada}
                  >
                    {formik.errors?.entrada}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Fecha y hora"
                InputLabelProps={{
                  shrink: true,
                }}
                name="fecha_hora"
                type="datetime-local"
                value={formik.values.fecha_hora}
                onChange={formik.handleChange}
                error={
                  formik.touched.fecha_hora && Boolean(formik.errors.fecha_hora)
                }
              />
              {formik.touched.fecha_hora && formik.errors.fecha_hora && (
                <FormHelperText
                  error={formik.errors.fecha_hora && formik.touched.fecha_hora}
                >
                  {formik.errors?.fecha_hora}
                </FormHelperText>
              )}
            </Grid>

            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  type="button"
                  textArea="Cancelar" /* Acà se puede validar el tipo de boton */
                  onClick={() => handleClose()}
                  variant="outlined"
                  color="primary"
                  minwidth="true"
                />
              </ButtonContainer>
            </Grid>
            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  // disabled={validated}
                  type="submit"
                  textArea="Ingresar"
                  variant="contained"
                  color="primary"
                  mright="true"
                />
              </ButtonContainer>
            </Grid>
          </Grid>
        </StyledForm>
      </ModalWrapper>
    </Background>,
    document.getElementById("root")
  );
};
