import {
  GET_TIPOS_TALONARIOS,
  GET_TIPOSASOCIABLES_TALONARIOS,
} from "../../constants/actionsTypes";

export const getTalonariosTiposAsociablesCls = (payload) => {
  return {
    type: GET_TIPOSASOCIABLES_TALONARIOS,
    payload,
  };
};

export const getTalonariosTiposCls = (payload) => {
  return {
    type: GET_TIPOS_TALONARIOS,
    payload,
  };
};
