import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Grid } from "@material-ui/core";
import { FormControl, FormHelperText } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {
  Background,
  ModalWrapper,
  Head,
  IconContainer,
  ButtonContainer,
  StyledForm,
} from "./Styles";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

/* Componentes */
import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";

/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Inputs } from "../../Input/Inputs";
import { editarChofer, getChoferes } from "../../../Services";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  nombre: yup.string().required("Campo requerido"),
  apellido: yup.string().required("Campo requerido"),
  dni: yup.number().required("Campo requerido"),
  transportista_id: yup.string().required("Campo requerido"),
  id: yup.number(),
});

export const ModalEditarChofer = ({ onClose }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getChoferes());
  }, [dispatch]);
  const { transportistas } = useSelector((state) => state.transportistas);
  const { choferes } = useSelector((state) => state.choferes);
  const [selectedChofer, setSelectedChofer] = React.useState(null);
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    const selectedOption = choferes.find(
      (chofer) => chofer.id === selectedValue
    );

    setSelectedChofer(selectedOption);

    formik.setValues((prevValues) => ({
      ...prevValues,
      id: selectedValue,
      nombre: selectedOption?.nombre || "",
      apellido: selectedOption?.apellido || "",
      dni: selectedOption?.dni || "",
      transportista_id: selectedOption?.transportista.nombre || "",
    }));
  };

  const formik = useFormik({
    initialValues: {
      nombre: "",
      apellido: "",
      dni: "",
      transportista_id: "",
      id: "",
    },

    validationSchema: validationSchema,

    onSubmit: (values) => {
      const transportistaId = transportistas.find(
        (transportista) => transportista.nombre === values.transportista_id
      )?.id;
      const updatedValues = {
        ...values,
        transportista_id: transportistaId,
      };
      dispatch(editarChofer(updatedValues));
      onClose();
    },
  });
  const handleClose = () => {
    onClose();
  };
  const SelectInput = ({
    label,
    name,
    value,
    onChange,
    options,
    error,
    disabled,
  }) => {
    const handleSelectChange = (event, selectedOption) => {
      onChange({
        target: {
          name,
          value: selectedOption ? selectedOption.value : "",
        },
      });
    };

    return (
      <FormControl fullWidth>
        <Autocomplete
          options={options}
          getOptionLabel={(option) => option.label}
          value={options.find((option) => option.value === value) || null}
          onChange={handleSelectChange}
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={error}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  };
  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper onClose={() => handleClose()}>
        <Head>
          <p>Editar un Chofer</p>
          <IconContainer>
            <FontAwesomeIcon icon={faTimes} onClick={onClose} />
          </IconContainer>
        </Head>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid container md={12} sm={8} justify="center">
            <SelectInput
              label="Chofer"
              name="id"
              value={formik.values.id}
              onChange={handleSelectChange}
              options={choferes.map((chofer) => ({
                value: chofer.id,
                label: `${chofer.nombre} ${chofer.apellido}`,
              }))}
              error={formik.errors.id}
            />
            <Inputs
              label="Nombre"
              name="nombre"
              value={formik.values.nombre}
              onChange={formik.handleChange}
              error={formik.errors.nombre}
            />
            <Inputs
              label="Apellido"
              name="apellido"
              value={formik.values.apellido}
              onChange={formik.handleChange}
              error={formik.errors.apellido}
            />
            <Inputs
              label="DNI"
              name="dni"
              value={formik.values.dni}
              onChange={formik.handleChange}
              error={formik.errors.dni}
            />
            <SelectInput
              label="Transportista"
              name="transportista_id"
              value={formik.values.transportista_id}
              onChange={formik.handleChange}
              options={transportistas.map((transportista) => ({
                value: transportista.nombre,
                label: transportista.nombre,
              }))}
              error={formik.errors.transportista_id}
            />

            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  type="button"
                  textArea="Cancelar" /* Acà se puede validar el tipo de boton */
                  onClick={() => handleClose()}
                  variant="outlined"
                  color="primary"
                  minwidth="true"
                />
              </ButtonContainer>
            </Grid>
            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  // disabled={validated}
                  type="submit"
                  textArea="Editar"
                  variant="contained"
                  color="primary"
                  mright="true"
                />
              </ButtonContainer>
            </Grid>
          </Grid>
        </StyledForm>
      </ModalWrapper>
    </Background>,
    document.getElementById("root")
  );
};
