import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Container,
  Head,
  Buton,
  StyledForm,
  Title,
  FormGroup,
  GridItem,
  ButtonContainer,
} from "./Styles";
import { Grid, FormHelperText } from "@material-ui/core";

/* Iconos */
import { ReactComponent as HomeIcon } from "../../../assets/img/homeIcon.svg";

/* componentes */
import { Inputs } from "../../Input/Inputs";
import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";
// import { ModalPlantaApprove } from "../ModalPlantaApprove/ModalPlantaApprove";

/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
import { habilitarPlanta, plantasInactivos } from "../../../Services";
import { addHours } from "../../../utils/DateTimeConverter/DateTimeConverter";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  nombre_planta: yup.string().required("Este campo es requerido"),
  geothresh: yup
    .number()
    .min(0, "El valor debe ser mayor que cero")
    .max(1, "El valor debe ser menor a 1")
    .required("Este campo es requerido"),
  longitud: yup
    .number()
    .lessThan(-60, "El valor debe estar entre -60 y -70")
    .moreThan(-70, "El valor debe estar entre -60 y -70")
    .required("Campo requerido"),
  latitud: yup
    .number()
    .lessThan(-30, "El valor debe estar entre -30 y -40")
    .moreThan(-40, "El valor debe estar entre -30 y -40")
    .required("Campo requerido"),
  camiones_M100: yup.number().min(0, "El valor debe ser mayor que cero"),
  camiones_M3070: yup.number().min(0, "El valor debe ser mayor que cero"),
  contrato_M100: yup.object({
    toneladas_contratadas: yup
      .number()
      .min(0, "El valor debe ser mayor o igual a cero"),
    toneladas_diarias: yup
      .number()
      .min(0, "El valor debe ser mayor o igual a cero"),
  }),
  contrato_M3070: yup.object({
    toneladas_contratadas: yup
      .number()
      .min(0, "El valor debe ser mayor o igual a cero"),
    toneladas_diarias: yup
      .number()
      .min(0, "El valor debe ser mayor o igual a cero"),
  }),
  // tiempo_promedio_viaje: yup.number(),
});

export const HabilitarPlantaForm = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  // const camiones = useSelector(state => state.plantasInactivos.plantas_inactivos)
  const hoyArgentina = addHours(-3, new Date()).toISOString();
  const { Name, Role, Email } = useSelector((state) => state.dataUser.user);
  const [validated, setValidated] = useState(true);

  function cancelar() {
    history.push("/home");
  }

  const formik = useFormik({
    initialValues: {
      nombre_planta: "",
      geothresh: 0,
      longitud: "",
      latitud: "",
      camiones_M100: 0,
      camiones_M3070: 0,
      contrato_M100: {
        toneladas_contratadas: 0,
        toneladas_diarias: 0,
      },
      contrato_M3070: {
        toneladas_contratadas: 0,
        toneladas_diarias: 0,
      },
      // tiempo_promedio_viaje: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        habilitarPlanta({
          ...values,
          user_name: Name,
          user_email: Email,
          user_rol: Role,
          datetime: hoyArgentina,
        })
      );
    },
  });

  useEffect(() => {
    dispatch(plantasInactivos());
  }, []);

  useEffect(() => {
    if (
      formik.values.nombre_planta &&
      formik.values.geothresh &&
      formik.values.latitud &&
      formik.values.longitud &&
      ((formik.values.camiones_M100 && formik.values.camiones_M3070) ||
        (formik.values.contrato_M100.toneladas_contratadas &&
          formik.values.contrato_M100.toneladas_diarias) ||
        (formik.values.contrato_M3070.toneladas_diarias &&
          formik.values.contrato_M3070.toneladas_contratadas))
      // || formik.values.tiempo_promedio_viaje
    ) {
      setValidated(false);
    } else setValidated(true);
  }, [setValidated, formik]);

  return (
    <Container>
      <Grid item md={12} sm={12}>
        <Head>
          <Buton
            variant="outlined"
            color="primary"
            textArea="Home"
            onClick={() => history.push("/home")}
          >
            <HomeIcon />
          </Buton>
        </Head>
      </Grid>
      <StyledForm onSubmit={formik.handleSubmit}>
        <Grid item xs={12} md={12} sm={12}>
          <Title>Nueva planta</Title>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={6} sm={6}>
            <FormGroup width>
              <Inputs
                type="text"
                required
                autoComplete="off"
                name="nombre_planta"
                id="nombre_planta"
                label="Ingresar nombre de planta*"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.nombre_planta}
                error={formik.errors.nombre_planta}
              />
              {formik.errors.nombre_planta && formik.touched.nombre_planta && (
                <FormHelperText
                  error={
                    formik.errors.nombre_planta && formik.touched.nombre_planta
                  }
                >
                  {formik.errors?.nombre_planta}
                </FormHelperText>
              )}
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            <FormGroup width>
              <Inputs
                type="number"
                step="0.01"
                required
                autoComplete="off"
                name="geothresh"
                id="geothresh"
                label="Geocerca*"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.geothresh}
                error={formik.errors.geothresh}
              />
              {formik.errors.geothresh && formik.touched.geothresh && (
                <FormHelperText
                  error={formik.errors.geothresh && formik.touched.geothresh}
                >
                  {formik.errors?.geothresh}
                </FormHelperText>
              )}
            </FormGroup>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} md={6} sm={6}>
            <FormGroup width>
              <Inputs
                type="number"
                required
                autoComplete="off"
                name="longitud"
                id="longitud_1"
                label="Longitud*"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.longitud}
                error={formik.errors.longitud}
              />
              {formik.errors.longitud && formik.touched.longitud && (
                <FormHelperText
                  error={formik.errors.longitud && formik.touched.longitud}
                >
                  {formik.errors?.longitud}
                </FormHelperText>
              )}
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={6} sm={6}>
            <FormGroup width>
              <Inputs
                type="number"
                required
                autoComplete="off"
                name="latitud"
                id="latitud_1"
                label="Latitud*"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.latitud}
                error={formik.errors.latitud}
              />
              {formik.errors.latitud && formik.touched.latitud && (
                <FormHelperText
                  error={formik.errors.latitud && formik.touched.latitud}
                >
                  {formik.errors?.latitud}
                </FormHelperText>
              )}
            </FormGroup>
          </Grid>
        </Grid>

        {/* <Grid item xs={12} md={6} style={{ margin: 0 }}>
          <FormGroup>
            <Inputs
              type="number"
              step="0.01"
              required
              autoComplete="off"
              name="tiempo_promedio_viaje"
              id="tiempo_promedio_viaje"
              label="Ajuste roundtrip"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.latitud}
              error={formik.errors.latitud}
            />
          </FormGroup>
        </Grid> */}
        <Grid item xs={12} md={12} sm={6}>
          <Title>Camiones asignados</Title>
        </Grid>
        <Grid container>
          <GridItem item xs={12} md={6} sm={6}>
            <FormGroup width>
              <Inputs
                type="number"
                required
                autoComplete="off"
                name="camiones_M100"
                id="camiones_M100"
                label="Camiones con arena M100"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.camiones_M100}
                error={formik.errors.camiones_M100}
              />
              {formik.errors.camiones_M100 && formik.touched.camiones_M100 && (
                <FormHelperText
                  error={
                    formik.errors.camiones_M100 && formik.touched.camiones_M100
                  }
                >
                  {formik.errors?.camiones_M100}
                </FormHelperText>
              )}
            </FormGroup>
          </GridItem>
          <GridItem item xs={12} md={6} sm={6}>
            <FormGroup width>
              <Inputs
                type="number"
                required
                autoComplete="off"
                name="camiones_M3070"
                id="camiones_M3070"
                label="Camiones con arena M3070"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.camiones_M3070}
                error={formik.errors.camiones_M3070}
              />
              {formik.errors.camiones_M3070 &&
                formik.touched.camiones_M3070 && (
                  <FormHelperText
                    error={
                      formik.errors.camiones_M3070 &&
                      formik.touched.camiones_M3070
                    }
                  >
                    {formik.errors?.camiones_M3070}
                  </FormHelperText>
                )}
            </FormGroup>
          </GridItem>
        </Grid>

        <Grid item xs={12} md={12} sm={6}>
          <Title>Contrato M100</Title>
        </Grid>
        <Grid container>
          <GridItem item xs={12} md={6} sm={6}>
            <FormGroup width>
              <Inputs
                type="number"
                required
                autoComplete="off"
                name="contrato_M100.toneladas_contratadas"
                id="contrato_M100.toneladas_contratadas"
                label="Toneladas totales contratadas"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.contrato_M100.toneladas_contratadas}
                error={formik.errors?.contrato_M100?.toneladas_contratadas}
              />
              {formik.errors?.contrato_M100?.toneladas_contratadas &&
                formik.touched?.contrato_M100?.toneladas_contratadas && (
                  <FormHelperText
                    error={
                      formik.errors?.contrato_M100?.toneladas_contratadas &&
                      formik.touched?.contrato_M100?.toneladas_contratadas
                    }
                  >
                    {formik.errors?.contrato_M100?.toneladas_contratadas}
                  </FormHelperText>
                )}
            </FormGroup>
          </GridItem>
          <GridItem item xs={12} md={6} sm={6}>
            <FormGroup width>
              <Inputs
                type="number"
                required
                autoComplete="off"
                name="contrato_M100.toneladas_diarias"
                id="contrato_M100.toneladas_diarias"
                label="Toneladas diarias"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.contrato_M100.toneladas_diarias}
                error={formik.errors?.contrato_M100?.toneladas_diarias}
              />
              {formik.errors?.contrato_M100?.toneladas_diarias &&
                formik.touched?.contrato_M100?.toneladas_diarias && (
                  <FormHelperText
                    error={
                      formik.errors?.contrato_M100?.toneladas_diarias &&
                      formik.touched?.contrato_M100?.toneladas_diarias
                    }
                  >
                    {formik.errors?.contrato_M100?.toneladas_diarias}
                  </FormHelperText>
                )}
            </FormGroup>
          </GridItem>
        </Grid>
        <Grid item xs={12} md={12} sm={12}>
          <Title>Contrato M3070</Title>
        </Grid>
        <Grid container>
          <GridItem item xs={12} md={6} sm={6}>
            <FormGroup width>
              <Inputs
                required
                type="number"
                autoComplete="off"
                name="contrato_M3070.toneladas_contratadas"
                id="contrato_M3070.toneladas_contratadas"
                label="Toneladas totales contratadas"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.contrato_M3070.toneladas_contratadas}
                error={formik.errors?.contrato_M3070?.toneladas_contratadas}
              />
              {formik.errors?.contrato_M3070?.toneladas_contratadas &&
                formik.touched?.contrato_M3070?.toneladas_contratadas && (
                  <FormHelperText
                    error={
                      formik.errors?.contrato_M3070?.toneladas_contratadas &&
                      formik.touched?.contrato_M3070?.toneladas_contratadas
                    }
                  >
                    {formik.errors?.contrato_M3070?.toneladas_contratadas}
                  </FormHelperText>
                )}
            </FormGroup>
          </GridItem>
          <GridItem item xs={12} md={6} sm={6}>
            <FormGroup width>
              <Inputs
                required
                type="number"
                autoComplete="off"
                name="contrato_M3070.toneladas_diarias"
                id="contrato_M3070.toneladas_diarias"
                label="Toneladas diarias"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.contrato_M3070.toneladas_diarias}
                error={formik.errors?.contrato_M3070?.toneladas_diarias}
              />
              {formik.errors?.contrato_M3070?.toneladas_diarias &&
                formik.touched?.contrato_M3070?.toneladas_diarias && (
                  <FormHelperText
                    error={
                      formik.errors?.contrato_M3070?.toneladas_diarias &&
                      formik.touched?.contrato_M3070?.toneladas_diarias
                    }
                  >
                    {formik.errors?.contrato_M3070?.toneladas_diarias}
                  </FormHelperText>
                )}
            </FormGroup>
          </GridItem>
        </Grid>

        <ButtonContainer container align="center">
          <ButtonPrimary
            type="button"
            textArea="Cancelar"
            onClick={cancelar}
            variant="outlined"
            color="primary"
            minwidth="true"
          />
          <ButtonPrimary
            type="submit"
            disabled={validated}
            textArea="Guardar"
            variant="contained"
            color="primary"
            minwidth="true"
          />
        </ButtonContainer>
      </StyledForm>
    </Container>
  );
};
