import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Grid, FormHelperText, InputLabel, Input } from "@material-ui/core";
import {
  Background,
  ModalWrapper,
  Head,
  IconContainer,
  FormGroup,
  ButtonContainer,
  StyledForm,
} from "./Styles";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

/* Componentes */
import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";

/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  actualizarReporteDiario,
  getParteDiarioComentarios,
} from "../../../Services";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  // supervisor: yup.string(),
  // c_planta: yup.string(),
  // c_laboratorio: yup.string(),
  // c_glp: yup.string(),
  // c_qshe: yup.string(),
  // c_notas: yup.string(),
  // infinia_manual: yup.number(),
  // glp_manual: yup.number(),
});

export const ModalEditarParteDiario = ({ onClose }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getParteDiarioComentarios());
  }, [dispatch]);
  const comentarios = useSelector((state) => state.parte_diario);
  console.log(comentarios);
  const formik = useFormik({
    initialValues: {
      supervisor: comentarios?.supervisor,
      c_planta: comentarios?.c_planta,
      c_laboratorio: comentarios?.c_laboratorio,
      c_glp: comentarios?.c_glp,
      c_qshe: comentarios?.c_qshe,
      c_notas: comentarios?.c_notas,
      infinia_manual: comentarios?.infinia_manual,
      glp_manual: comentarios?.glp_manual,
    },

    validationSchema: validationSchema,

    onSubmit: async (values) => {
      await dispatch(actualizarReporteDiario(values));
      dispatch(getParteDiarioComentarios());
      onClose();
    },
  });

  const handleClose = () => {
    onClose();
  };

  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper onClose={() => handleClose()}>
        <Head>
          <p>Edite el Parte Diario</p>
          <IconContainer>
            <FontAwesomeIcon icon={faTimes} onClick={onClose} />
          </IconContainer>
        </Head>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormGroup>
                <InputLabel>Supervisor</InputLabel>
                <Input
                  fullWidth
                  id="supervisor"
                  name="supervisor"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.supervisor}
                  error={formik.errors.supervisor}
                />
                <FormHelperText error id="supervisor">
                  {formik.errors.supervisor}
                </FormHelperText>
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <InputLabel>Comentarios Planta</InputLabel>
                <Input
                  fullWidth
                  id="c_planta"
                  name="c_planta"
                  type="text"
                  // multiline
                  // rows={4}
                  onChange={formik.handleChange}
                  value={formik.values.c_planta}
                  error={formik.errors.c_planta}
                />
                <FormHelperText error id="c_planta">
                  {formik.errors.c_planta}
                </FormHelperText>
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <InputLabel>Comentarios Laboratorio</InputLabel>
                <Input
                  fullWidth
                  id="c_laboratorio"
                  name="c_laboratorio"
                  type="text"
                  multiline
                  rows={4}
                  onChange={formik.handleChange}
                  value={formik.values.c_laboratorio}
                  error={formik.errors.c_laboratorio}
                />
                <FormHelperText error id="c_laboratorio">
                  {formik.errors.c_laboratorio}
                </FormHelperText>
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <InputLabel>Comentarios GLP</InputLabel>
                <Input
                  fullWidth
                  id="c_glp"
                  name="c_glp"
                  type="text"
                  multiline
                  rows={4}
                  onChange={formik.handleChange}
                  value={formik.values.c_glp}
                  error={formik.errors.c_glp}
                />
                <FormHelperText error id="c_glp">
                  {formik.errors.c_glp}
                </FormHelperText>
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <InputLabel>Comentarios QSHE</InputLabel>
                <Input
                  fullWidth
                  id="c_qshe"
                  name="c_qshe"
                  type="text"
                  multiline
                  rows={4}
                  onChange={formik.handleChange}
                  value={formik.values.c_qshe}
                  error={formik.errors.c_qshe}
                />
                <FormHelperText error id="c_qshe">
                  {formik.errors.c_qshe}
                </FormHelperText>
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <InputLabel>Notas</InputLabel>
                <Input
                  fullWidth
                  id="c_notas"
                  name="c_notas"
                  type="text"
                  multiline
                  rows={4}
                  onChange={formik.handleChange}
                  value={formik.values.c_notas}
                  error={formik.errors.c_notas}
                />
                <FormHelperText error id="c_notas">
                  {formik.errors.c_notas}
                </FormHelperText>
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <InputLabel htmlFor="infinia_manual">
                  Carga Manual de Infinia (Lts)
                </InputLabel>
                <Input
                  id="infinia_manual"
                  name="infinia_manual"
                  type="number"
                  value={formik.values.infinia_manual}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.infinia_manual &&
                    Boolean(formik.errors.infinia_manual)
                  }
                />
                {formik.touched.infinia_manual && (
                  <FormHelperText error>
                    {formik.errors.infinia_manual}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <InputLabel htmlFor="glp_manual">
                  Carga Manual de GLP (Lts)
                </InputLabel>
                <Input
                  id="glp_manual"
                  name="glp_manual"
                  type="number"
                  value={formik.values.glp_manual}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.glp_manual &&
                    Boolean(formik.errors.glp_manual)
                  }
                />
                {formik.touched.glp_manual && (
                  <FormHelperText error>
                    {formik.errors.glp_manual}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
          </Grid>

          <Grid container md={12} sm={8} justify="center">
            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  type="button"
                  textArea="Cancelar"
                  onClick={() => handleClose()}
                  variant="outlined"
                  color="primary"
                  minwidth="true"
                />
              </ButtonContainer>
            </Grid>
            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  type="submit"
                  textArea="Editar"
                  variant="contained"
                  color="primary"
                  mright="true"
                />
              </ButtonContainer>
            </Grid>
          </Grid>
        </StyledForm>
      </ModalWrapper>
    </Background>,
    document.getElementById("root")
  );
};
