import { GET_DESTINATARIOS_CLS } from "../../constants/actionsTypes";

const initialState = {
  destinatarios: [], // Inicializa como array vacío
};

export const destinatariosReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_DESTINATARIOS_CLS: {
      return {
        ...state,
        destinatarios: payload, // Almacena el payload bajo la clave destinatarios
      };
    }
    default:
      return state;
  }
};
