import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Grid } from "@material-ui/core";
import { FormControl, FormHelperText } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {
  Background,
  ModalWrapper,
  Head,
  IconContainer,
  ButtonContainer,
  StyledForm,
} from "./Styles";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

/* Componentes */
import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";

/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Inputs } from "../../Input/Inputs";
import { editarCarga, getCargas } from "../../../Services";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  nombre: yup.string().required("Campo requerido"),
  id: yup.number().required("Campo requerido"),
  tipo: yup.string().required("Campo requerido"),
});

export const ModalEditarCarga = ({ onClose }) => {
  const [selectedCarga, setSelectedCarga] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCargas());
  }, [dispatch]);
  const { cargas } = useSelector((state) => state.cargas);
  const formik = useFormik({
    initialValues: {
      nombre: "",
      id: "",
      tipo: "",
    },

    validationSchema: validationSchema,

    onSubmit: (values) => {
      console.log(values);
      dispatch(editarCarga(values));
      onClose();
    },
  });
  const handleClose = () => {
    onClose();
  };
  const SelectInput1 = ({
    label,
    name,
    value,
    onChange,
    options,
    error,
    disabled,
  }) => {
    const handleSelectChange = (event, selectedOption) => {
      onChange({
        target: {
          name,
          value: selectedOption ? selectedOption.value : "",
        },
      });
    };

    return (
      <FormControl fullWidth>
        <Autocomplete
          options={options}
          getOptionLabel={(option) => option.label}
          value={options?.find((option) => option?.value === value) || null}
          onChange={handleSelectChange}
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={error}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  };

  const handleSelectChange = (event, selectedOption) => {
    const selectedCargaInfo = cargas.find(
      (carga) => carga.id === selectedOption.value
    );
    setSelectedCarga(selectedCargaInfo);
    formik.setFieldValue("id", selectedCargaInfo?.id || "");
    formik.setFieldValue("nombre", selectedCargaInfo?.nombre || "");
    formik.setFieldValue("tipo", selectedCargaInfo?.tipo || "");
  };

  const SelectInput = ({
    label,
    name,
    value,
    onChange,
    options,
    error,
    disabled,
  }) => {
    return (
      <FormControl fullWidth>
        <Autocomplete
          options={options}
          getOptionLabel={(option) => option.label}
          value={options?.find((option) => option?.value === value) || null}
          onChange={(event, selectedOption) =>
            handleSelectChange(event, selectedOption)
          }
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={error}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  };
  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper onClose={() => handleClose()}>
        <Head>
          <p>Editar un tipo de carga</p>
          <IconContainer>
            <FontAwesomeIcon icon={faTimes} onClick={onClose} />
          </IconContainer>
        </Head>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid container md={12} sm={8} justify="center">
            <SelectInput
              label="Carga"
              name="id"
              type="number"
              value={formik.values.id}
              onChange={formik.handleChange}
              error={formik.errors.nombre && formik.touched.nombre}
              options={cargas.map((carga) => ({
                value: carga.id,
                label: carga.nombre,
              }))}
            />

            {formik.errors.nombre && formik.touched.nombre && (
              <FormHelperText error={formik.errors.nombre}>
                {formik.errors?.nombre}
              </FormHelperText>
            )}
            <Inputs
              label="Nombre de carga"
              name="nombre"
              value={formik.values.nombre}
              onChange={formik.handleChange}
              error={formik.errors.nombre && formik.touched.nombre}
            />
            {formik.errors.nombre && formik.touched.nombre && (
              <FormHelperText error={formik.errors.nombre}>
                {formik.errors?.nombre}
              </FormHelperText>
            )}

            <SelectInput1
              label="Tipo de carga"
              name="tipo"
              value={formik.values.tipo}
              onChange={formik.handleChange}
              options={[...new Set(cargas?.map((carga) => carga?.tipo))].map(
                (tipo) => ({
                  value: tipo,
                  label: tipo,
                })
              )}
              error={formik.errors.tipo && formik.touched.tipo}
            />

            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  type="button"
                  textArea="Cancelar" /* Acà se puede validar el tipo de boton */
                  onClick={() => handleClose()}
                  variant="outlined"
                  color="primary"
                  minwidth="true"
                />
              </ButtonContainer>
            </Grid>
            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  // disabled={validated}
                  type="submit"
                  textArea="Editar"
                  variant="contained"
                  color="primary"
                  mright="true"
                />
              </ButtonContainer>
            </Grid>
          </Grid>
        </StyledForm>
      </ModalWrapper>
    </Background>,
    document.getElementById("root")
  );
};
