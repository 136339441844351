import React from "react";
import ReactDOM from "react-dom";
import { Grid } from "@material-ui/core";
import {
  FormControl,
  InputLabel,
  FormHelperText,
  MenuItem,
  Select as MuiSelect,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {
  Background,
  ModalWrapper,
  Head,
  IconContainer,
  ButtonContainer,
  StyledForm,
} from "./Styles";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

/* Componentes */
import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";

/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Inputs } from "../../Input/Inputs";
import { editarLugar, getLugares } from "../../../Services";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  id: yup.number(),
  nombre: yup.string().required("Campo requerido"),
  acopio: yup.boolean().required("Campo requerido"),
  domicilio: yup.string().required("Campo requerido"),
  localidad: yup.string().required("Campo requerido"),
  provincia: yup.string().required("Campo requerido"),
  cuit: yup
    .number()
    .required("Campo requerido")
    .test(
      "len",
      "El CUIT debe tener exactamente 11 caracteres",
      (val) => val && val.toString().length === 11
    ),
  nombre_cliente: yup.string().required("Campo requerido"),
});

export const ModalEditarLugar = ({ onClose }) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getLugares());
  }, [dispatch]);
  const { lugares } = useSelector((state) => state.lugares);
  const [selectedLugar, setSelectedLugar] = React.useState(null);
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    const selectedOption = lugares?.find(
      (lugar) => lugar?.id === selectedValue
    );
    setSelectedLugar(selectedOption);

    formik.setValues((prevValues) => ({
      ...prevValues,
      id: selectedValue,
      nombre: selectedOption?.nombre || "",
      acopio: selectedOption?.acopio || false,
      domicilio: selectedOption?.domicilio || "",
      localidad: selectedOption?.localidad || "",
      provincia: selectedOption?.provincia || "",
      cuit: selectedOption?.cuit || "",
      nombre_cliente: selectedOption?.nombre_cliente || "",
    }));
  };

  const formik = useFormik({
    initialValues: {
      id: "",
      nombre: "",
      acopio: "",
      domicilio: "",
      localidad: "",
      provincia: "",
      cuit: "",
      nombre_cliente: "",
    },

    validationSchema: validationSchema,

    onSubmit: (values) => {
      dispatch(editarLugar(values));
      onClose();
    },
  });
  const handleClose = () => {
    onClose();
  };
  const SelectInput = ({
    label,
    name,
    value,
    onChange,
    options,
    error,
    disabled,
  }) => {
    const handleSelectChange = (event, selectedOption) => {
      onChange({
        target: {
          name,
          value: selectedOption ? selectedOption.value : "",
        },
      });
    };

    return (
      <FormControl fullWidth>
        <Autocomplete
          options={options}
          getOptionLabel={(option) => option.label}
          value={options?.find((option) => option?.value === value) || null}
          onChange={handleSelectChange}
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={error}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  };
  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper onClose={() => handleClose()}>
        <Head>
          <p>Editar un lugar</p>
          <IconContainer>
            <FontAwesomeIcon icon={faTimes} onClick={onClose} />
          </IconContainer>
        </Head>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid container md={12} sm={8} justify="center">
            <SelectInput
              label="Lugar"
              name="id"
              value={formik.values.id}
              onChange={handleSelectChange}
              options={lugares?.map((lugar) => ({
                value: lugar.id,
                label: lugar.nombre,
              }))}
              error={formik.errors.id}
            />
            <Inputs
              label="Nombre"
              name="nombre"
              type="text"
              value={formik.values.nombre}
              onChange={formik.handleChange}
              error={formik.errors.nombre && formik.touched.nombre}
            />
            {formik.errors.nombre && formik.touched.nombre && (
              <FormHelperText error={formik.errors.nombre}>
                {formik.errors?.nombre}
              </FormHelperText>
            )}

            <Inputs
              label="Domicilio"
              name="domicilio"
              type="text"
              value={formik.values.domicilio}
              onChange={formik.handleChange}
              error={formik.errors.domicilio && formik.touched.domicilio}
            />
            {formik.errors.domicilio && formik.touched.domicilio && (
              <FormHelperText error={formik.errors.domicilio}>
                {formik.errors?.domicilio}
              </FormHelperText>
            )}
            <Inputs
              label="Localidad"
              name="localidad"
              type="text"
              value={formik.values.localidad}
              onChange={formik.handleChange}
              error={formik.errors.localidad && formik.touched.localidad}
            />
            {formik.errors.localidad && formik.touched.localidad && (
              <FormHelperText error={formik.errors.localidad}>
                {formik.errors?.localidad}
              </FormHelperText>
            )}
            <Inputs
              label="Provincia"
              name="provincia"
              type="text"
              value={formik.values.provincia}
              onChange={formik.handleChange}
              error={formik.errors.provincia && formik.touched.provincia}
            />
            {formik.errors.provincia && formik.touched.provincia && (
              <FormHelperText error={formik.errors.provincia}>
                {formik.errors?.provincia}
              </FormHelperText>
            )}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="acopio">¿Es planta de acopio?</InputLabel>
                <MuiSelect
                  labelId="acopio"
                  name="acopio"
                  value={formik.values.acopio}
                  onChange={formik.handleChange}
                  error={formik.errors.acopio && formik.touched.acopio}
                >
                  <MenuItem value={true}>Si</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </MuiSelect>
                {formik.errors.acopio && formik.touched.acopio && (
                  <FormHelperText
                    error={formik.errors.acopio && formik.touched.acopio}
                  >
                    {formik.errors?.acopio}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Inputs
              label="CUIT (sin guiones)"
              name="cuit"
              type="number"
              value={formik.values.cuit}
              onChange={formik.handleChange}
              error={formik.errors.cuit && formik.touched.cuit}
            />
            {formik.errors.cuit && formik.touched.cuit && (
              <FormHelperText error={formik.errors.cuit}>
                {formik.errors?.cuit}
              </FormHelperText>
            )}

            <Inputs
              label="Nombre del cliente"
              name="nombre_cliente"
              type="text"
              value={formik.values.nombre_cliente}
              onChange={formik.handleChange}
              error={
                formik.errors.nombre_cliente && formik.touched.nombre_cliente
              }
            />
            {formik.errors.nombre_cliente && formik.touched.nombre_cliente && (
              <FormHelperText error={formik.errors.nombre_cliente}>
                {formik.errors?.nombre_cliente}
              </FormHelperText>
            )}

            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  type="button"
                  textArea="Cancelar" /* Acà se puede validar el tipo de boton */
                  onClick={() => handleClose()}
                  variant="outlined"
                  color="primary"
                  minwidth="true"
                />
              </ButtonContainer>
            </Grid>
            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  // disabled={validated}
                  type="submit"
                  textArea="Editar"
                  variant="contained"
                  color="primary"
                  mright="true"
                />
              </ButtonContainer>
            </Grid>
          </Grid>
        </StyledForm>
      </ModalWrapper>
    </Background>,
    document.getElementById("root")
  );
};
