import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
  Grid,
  Select,
  MenuItem,
  FormHelperText,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@material-ui/core";
import {
  Background,
  ModalWrapper,
  Head,
  IconContainer,
  FormGroup,
  ButtonContainer,
  GridItem,
  StyledForm,
} from "./Styles";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

/* Componentes */
import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";

/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Inputs } from "../../Input/Inputs";

import {
  planDespachoPost,
  planDespachoGet,
  getPeriodoFacturacion,
} from "../../../Services";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  tipo_transporte: yup.string().required("Campo requerido"),
  proveedor: yup.string().required("Campo requerido"),
  tipo_producto: yup.string(),
  periodo: yup.string().required("Campo requerido"),
  toneladas: yup.number().required("Campo requerido"),
});

export const ModalPlanDespacho = ({ onClose }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(planDespachoGet());
    dispatch(getPeriodoFacturacion());
  }, [dispatch]);

  const planDespacho = useSelector((state) => state.planDespachoCls);
  const { proveedores, tipo_arena } = useSelector(
    (state) => state.periodoFacturacionCls
  );
  const [showTable, setShowTable] = useState(false);
  const currentYear = new Date().getFullYear();

  const formik = useFormik({
    initialValues: {
      tipo_transporte: "",
      proveedor: "",
      tipo_producto: "",
      periodo: "",
      toneladas: 0,
    },

    validationSchema: validationSchema,

    onSubmit: (values) => {
      // if (
      //   values.tipo_transporte === "Cantera a Lavado" ||
      //   values.tipo_transporte === "Rechazado Lavado a Cantera"
      // ) {
      //   values.tipo_producto = "ARENA CANTERA";
      // }

      // Dispatch the action with the updated values
      dispatch(
        planDespachoPost({
          tipo_transporte: values.tipo_transporte,
          proveedor: values.proveedor,
          tipo_producto: values.tipo_producto,
          periodo: values.periodo,
          toneladas: values.toneladas,
        })
      );

      onClose();
    },
  });

  const handleClose = () => {
    onClose();
  };

  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper onClose={() => handleClose()}>
        <Head>
          <p>Registro de demanda por proveedor</p>
          <IconContainer>
            <FontAwesomeIcon icon={faTimes} onClick={onClose} />
          </IconContainer>
        </Head>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid container>
            <GridItem item xs={12} md={6}>
              <FormGroup>
                <InputLabel id="proveedor">Proveedor</InputLabel>
                <Select
                  id="proveedor"
                  name="proveedor"
                  label="Proveedor"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.proveedor}
                  error={formik.errors.proveedor && formik.touched.proveedor}
                >
                  <MenuItem value="">Seleccione un proveedor</MenuItem>
                  {proveedores?.map((proveedor) => (
                    <MenuItem key={proveedor.nombre} value={proveedor.nombre}>
                      {proveedor.nombre}
                    </MenuItem>
                  ))}
                </Select>
                {formik.errors.proveedor && formik.touched.proveedor && (
                  <FormHelperText
                    error={formik.errors.proveedor && formik.touched.proveedor}
                  >
                    {formik.errors?.proveedor}
                  </FormHelperText>
                )}
              </FormGroup>
            </GridItem>

            <GridItem item xs={12} md={6}>
              <FormGroup>
                <InputLabel id="tipo_transporte">Tramo</InputLabel>
                <Select
                  id="tipo_transporte"
                  name="tipo_transporte"
                  label="Tramo"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.tipo_transporte}
                  error={
                    formik.errors.tipo_transporte &&
                    formik.touched.tipo_transporte
                  }
                >
                  <MenuItem value="">Seleccione un tramo</MenuItem>
                  {formik.values.proveedor &&
                    proveedores
                      .find((prov) => prov.nombre === formik.values.proveedor)
                      .tramo.map((tramo) => (
                        <MenuItem key={tramo} value={tramo}>
                          {tramo}
                        </MenuItem>
                      ))}
                </Select>
                {formik.errors.tipo_transporte &&
                  formik.touched.tipo_transporte && (
                    <FormHelperText
                      error={
                        formik.errors.tipo_transporte &&
                        formik.touched.tipo_transporte
                      }
                    >
                      {formik.errors?.tipo_transporte}
                    </FormHelperText>
                  )}
              </FormGroup>
            </GridItem>
            {!formik.values.tipo_transporte
              .toLowerCase()
              .includes("cantera") && (
              <GridItem item xs={12} md={6}>
                <FormGroup>
                  <InputLabel id="tipo_producto">Tipo de arena</InputLabel>
                  <Select
                    id="tipo_producto"
                    name="tipo_producto"
                    label="Tipo de producto"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.tipo_producto}
                    error={
                      formik.errors.tipo_producto &&
                      formik.touched.tipo_producto
                    }
                  >
                    <MenuItem value="">Seleccione un tipo de arena</MenuItem>
                    {tipo_arena?.map((tipo) => (
                      <MenuItem key={tipo} value={tipo}>
                        {tipo}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.errors.tipo_producto &&
                    formik.touched.tipo_producto && (
                      <FormHelperText
                        error={
                          formik.errors.tipo_producto &&
                          formik.touched.tipo_producto
                        }
                      >
                        {formik.errors?.tipo_producto}
                      </FormHelperText>
                    )}
                </FormGroup>
              </GridItem>
            )}

            <GridItem item xs={12} md={6}>
              <FormGroup>
                <InputLabel id="periodo">Mes</InputLabel>
                <Select
                  id="periodo"
                  name="periodo"
                  label="Mes"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.periodo}
                  error={formik.errors.periodo && formik.touched.periodo}
                >
                  <MenuItem value="">Seleccione un mes</MenuItem>
                  <MenuItem value={`${currentYear}-01`}>Enero</MenuItem>
                  <MenuItem value={`${currentYear}-02`}>Febrero</MenuItem>
                  <MenuItem value={`${currentYear}-03`}>Marzo</MenuItem>
                  <MenuItem value={`${currentYear}-04`}>Abril</MenuItem>
                  <MenuItem value={`${currentYear}-05`}>Mayo</MenuItem>
                  <MenuItem value={`${currentYear}-06`}>Junio</MenuItem>
                  <MenuItem value={`${currentYear}-07`}>Julio</MenuItem>
                  <MenuItem value={`${currentYear}-08`}>Agosto</MenuItem>
                  <MenuItem value={`${currentYear}-09`}>Septiembre</MenuItem>
                  <MenuItem value={`${currentYear}-10`}>Octubre</MenuItem>
                  <MenuItem value={`${currentYear}-11`}>Noviembre</MenuItem>
                  <MenuItem value={`${currentYear}-12`}>Diciembre</MenuItem>
                </Select>
                {formik.errors.periodo && formik.touched.periodo && (
                  <FormHelperText
                    error={formik.errors.periodo && formik.touched.periodo}
                  >
                    {formik.errors?.periodo}
                  </FormHelperText>
                )}
              </FormGroup>
            </GridItem>

            <GridItem item xs={12} md={6}>
              <FormGroup>
                <Inputs
                  id="toneladas"
                  autoComplete="off"
                  type="number"
                  name="toneladas"
                  label="Cantidad de toneladas"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.toneladas}
                  error={formik.errors.toneladas && formik.touched.toneladas}
                />
                {formik.errors.toneladas && formik.touched.toneladas && (
                  <FormHelperText
                    error={formik.errors.toneladas && formik.touched.toneladas}
                  >
                    {formik.errors?.toneladas}
                  </FormHelperText>
                )}
              </FormGroup>
            </GridItem>
            <GridItem item xs={12} md={6}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showTable}
                      onChange={() => setShowTable(!showTable)}
                      color="primary"
                    />
                  }
                  label="Mostrar planes de despacho históricos"
                />
              </FormGroup>
            </GridItem>
          </Grid>

          {showTable && (
            <div style={{ height: "400px", overflow: "auto" }}>
              {Object.keys(planDespacho).map((transporteKey) => (
                <div key={transporteKey} style={{ marginBottom: "20px" }}>
                  <Typography variant="h6" gutterBottom>
                    {transporteKey}
                  </Typography>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Proveedor</TableCell>
                        <TableCell>Tipo de Producto</TableCell>
                        <TableCell>Mes</TableCell>
                        <TableCell>Toneladas</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {planDespacho[transporteKey].map((proveedor, index) =>
                        proveedor.tipo_producto?.map((tipo) =>
                          tipo.data.map((dataItem, innerIndex) => (
                            <TableRow
                              key={`${transporteKey}-${index}-${innerIndex}`}
                            >
                              <TableCell>{proveedor.proveedor}</TableCell>
                              <TableCell>{tipo.nombre}</TableCell>
                              <TableCell>{dataItem.periodo}</TableCell>
                              <TableCell>{dataItem.tn_contratadas}</TableCell>
                            </TableRow>
                          ))
                        )
                      )}
                    </TableBody>
                  </Table>
                </div>
              ))}
            </div>
          )}
          <Grid container md={12} sm={8} justify="center">
            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  type="button"
                  textArea="Cancelar" /* Acà se puede validar el tipo de boton */
                  onClick={() => handleClose()}
                  variant="outlined"
                  color="primary"
                  minwidth="true"
                />
              </ButtonContainer>
            </Grid>
            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  // disabled={validated}
                  type="submit"
                  textArea="Guardar"
                  variant="contained"
                  color="primary"
                  mright="true"
                />
              </ButtonContainer>
            </Grid>
          </Grid>
        </StyledForm>
      </ModalWrapper>
    </Background>,
    document.getElementById("root")
  );
};
