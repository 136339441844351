import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

/* Estilos */
import { InputAdornment, FormHelperText, IconButton } from "@material-ui/core";

import {
  StyledLogin,
  ContainerLogin,
  Title,
  ButtonLogin,
  StyledForm,
  LogoVista,
  FormGroup,
  A,
  IconContainer,
} from "./Styles";

import "./style.css";

/* Componentes */
import { Inputs } from "../Input/Inputs";

/* dependencias */
import { useFormik } from "formik";
import * as Yup from "yup";

/* imagenes / Iconos */
import Logo from "../../assets/img/logo_vista.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faLock,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";

import { login } from "../../Services/index";
import { AuthRoutes } from "../../utils/AuthRoles";

/* Validacion de inputs con Formik */
const validationSchema = Yup.object().shape({
  username: Yup.string().required("Este campo es requerido"),
  password: Yup.string().required("Contraseña incorrecta"),
});

export const LoginUser = ({ openModalForgotPassword }) => {
  const [passwordShow, setPasswordShow] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector((state) =>
    state.dataUser.user ? state.dataUser.user.Token : null
  );
  const Role = useSelector((state) =>
    state.dataUser.user ? state.dataUser.user.Role : null
  );
  let history = useHistory();

  useEffect(() => {
    if (token) {
      if (
        Role === "CoordAluvional" ||
        Role === "SupervAluvional" ||
        Role === "Weber" ||
        Role === "Pedrolga"
      ) {
        history.push(AuthRoutes.aluvional);
      } else if (
        Role === "CoordBrent" ||
        Role === "Brent" ||
        Role === "SupplyChain"
      ) {
        history.push(AuthRoutes.home);
      } else if (Role === "Balanza") {
        history.push("/balanza");
      } else {
        history.push("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, history]);

  const formik = useFormik({
    initialValues: { username: "", password: "" },
    validationSchema: validationSchema,

    onSubmit: (values) => {
      dispatch(login(values));
    },
  });

  const handleClickShowPassword = () => {
    setPasswordShow(!passwordShow);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <ContainerLogin>
      <StyledLogin maxWidth="sm">
        <LogoVista src={Logo} alt="logo" />
        <Title>Seguimiento de PAD</Title>

        <StyledForm onSubmit={formik.handleSubmit}>
          <FormGroup>
            <Inputs
              required
              autoComplete="off"
              name="username"
              label="Correo Electronico"
              variant="filled"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.username}
              error={!!formik.errors.username}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className="icon-input">
                    <IconContainer color="true" fontSize="true">
                      <FontAwesomeIcon icon={faUser} />
                    </IconContainer>
                  </InputAdornment>
                ),
              }}
            />
            <FormHelperText>{formik.errors.username}</FormHelperText>
          </FormGroup>
          <FormGroup>
            <Inputs
              required
              autoComplete="off"
              label="Contraseña"
              variant="filled"
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              error={!!formik.errors.username}
              type={passwordShow ? "text" : "password"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className="icon-input">
                    <IconContainer color="true" fontSize="true">
                      <FontAwesomeIcon icon={faLock} />
                    </IconContainer>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position="start"
                    className="icon-input eye-button"
                  >
                    <IconButton
                      className="edgeEnd"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {passwordShow ? (
                        <IconContainer>
                          <FontAwesomeIcon icon={faEye} />
                        </IconContainer>
                      ) : (
                        <IconContainer>
                          <FontAwesomeIcon icon={faEyeSlash} />
                        </IconContainer>
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FormHelperText>{formik.errors.password}</FormHelperText>
          </FormGroup>
          <ButtonLogin
            type="submit"
            variant="contained"
            color="primary"
            disabled={!formik.dirty}
            // onClick={() => dispatch(resetResponse())}
          >
            Iniciar Sesión
          </ButtonLogin>
          <A onClick={() => openModalForgotPassword()}>
            ¿Has olvidado tu contraseña?
          </A>
        </StyledForm>
      </StyledLogin>
    </ContainerLogin>
  );
};
