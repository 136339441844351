export const GET_LOGIN = "GET_LOGIN";
export const GET_TOKEN = "GET_TOKEN";
export const SET_RESET = "SET_RESET";
export const RESET_LOGIN = "RESET_LOGIN";
export const SET_LOGOUT = "SET_LOGOUT";
export const SET_RESPONSE = "SET_RESPONSE";
export const RESET_RESPONSE = "RESET_RESPONSE";
export const RESET_RECOMENDADOR = "RESET_RECOMENDADOR";
export const RESET_INFO_PLANTAS = "RESET_INFO_PLANTAS";
export const RESET_INDICADORES = "RESET_INDICADORES";
export const RESET_ESTADO_CAMIONES = "RESET_ESTADO_CAMIONES";
export const RESET_DESHABILITAR_CAMION = "RESET_DESHABILITAR_CAMION";
export const RESET_CONSULTAR_ETAPAS = "RESET_CONSULTAR_ETAPAS";
export const RESET_ALERTAS = "RESET_ALERTAS";
export const CURRENT_DESCARGAR = "CURRENT_DESCARGAR";
export const GET_PROYECCIONES = "GET_PROYECCIONES";
export const SET_CURRENT_TAGS = "SET_CURRENT_TAGS";
export const GET_INDICADORES = "GET_INDICADORES";
export const GET_ESTADO_CAMIONES = "GET_ESTADO_CAMIONES";
export const GET_CAMIONES_INACTIVOS = "GET_CAMIONES_INACTIVOS";
export const RESET_CAMIONES_INACTIVOS = "RESET_CAMIONES_INACTIVOS";
export const GET_PLANTAS_INACTIVOS = "GET_PLANTAS_INACTIVOS";
export const RESET_PLANTAS_INACTIVOS = "RESET_PLANTAS_INACTIVOS";
export const GET_INFO_PAD = "GET_INFO_PAD";
export const RESET_INFO_PAD = "RESET_INFO_PAD";
export const GET_RECOMENDADOR = "GET_RECOMENDADOR";
export const GET_ETAPAS = "GET_ETAPAS";
export const GET_FECHA_ETAPA = "GET_FECHA_ETAPA";
export const GET_ALERTAS = "GET_ALERTAS";
export const GET_INFO_PLANTAS = "GET_INFO_PLANTAS";
export const GET_PLANTAS = "GET_PLANTAS";
export const SET_CURRENT_DATA = " SET_CURRENT_DATA";
export const RESET_PLANTAS = "RESET_PLANTAS";
export const GET_DESHABILITAR_CAMION = "GET_DESHABILITAR_CAMION";
export const POST_MODIFICAR_TRAYECTO = "POST_MODIFICAR_TRAYECTO";
export const RESET_MODIFICAR_TRAYECTO = "RESET_MODIFICAR_TRAYECTO";
export const SET_LOADING = "SET_LOADING";
export const SHOW_MAP_TRANSITO_VACIO = "SHOW_MAP_TRANSITO_VACIO";
export const SHOW_CAR_TRANSITO_VACIO = "SHOW_CAR_TRANSITO_VACIO";
export const SHOW_MAP_PAD = "SHOW_MAP_PAD";
export const SHOW_CAR_PAD = "SHOW_CAR_PAD";
export const SHOW_MAP_CARGA_PLANTA = "SHOW_MAP_CARGA_PLANTA";
export const SHOW_CAR_CARGA_PLANTA = "SHOW_CAR_CARGA_PLANTA";
export const SHOW_MAP_TRANSITO_LLENO = "SHOW_MAP_TRANSITO_LLENO";
export const SHOW_CAR_TRANSITO_LLENO = "SHOW_CAR_TRANSITO_LLENO";
export const GET_ESCENARIOS = "GET_ESCENARIOS";
export const RESET_ESCENARIOS = "RESET_ESCENARIOS";
export const GET_PROYECCIONESESCENARIOS = "GET_PROYECCIONESESCENARIOS";
export const GET_ESTADORT = "GET_ESTADORT";
export const RESET_ESTADORT = "RESET_ESTADORT";
export const SIDEBAR_TOGGLE = "SIDEBAR_TOGGLE";
export const GET_CAMIONES_HISTORICOS = "GET_CAMIONES_HISTORICOS";
export const GET_PLANTAS_HISTORICAS = "GET_PLANTAS_HISTORICAS";
export const GET_PLAN_DESPACHO_CLS = "GET_PLAN_DESPACHO_CLS";
export const GET_INDICADORES_CLS = "GET_INDICADORES_CLS";
export const RESET_INDICADORES_CLS = "RESET_INDICADORES_CLS";
export const GET_ESTADO_CAMIONES_CLS = "GET_ESTADO_CAMIONES_CLS";
export const RESET_ESTADO_CAMIONES_CLS = "RESET_ESTADO_CAMIONES_CLS";
export const SHOW_CAR_TRANSITO_CLS = "SHOW_CAR_TRANSITO_CLS";
export const SHOW_MAP_TRANSITO_CLS = "SHOW_MAP_TRANSITO_CLS";
export const SHOW_CAR_CANTERA_CLS = "SHOW_CAR_CANTERA_CLS";
export const SHOW_MAP_CANTERA_CLS = "SHOW_MAP_CANTERA_CLS";
export const SHOW_CAR_LAVADO_CLS = "SHOW_CAR_LAVADO_CLS";
export const SHOW_MAP_LAVADO_CLS = "SHOW_MAP_LAVADO_CLS";
export const SHOW_CAR_SECADO_CLS = "SHOW_CAR_SECADO_CLS";
export const SHOW_MAP_SECADO_CLS = "SHOW_MAP_SECADO_CLS";
export const GET_ALERTAS_CLS = "GET_ALERTAS_CLS";
export const RESET_ALERTAS_CLS = "RESET_ALERTAS_CLS";
export const CURRENT_DESCARGAR_CLS = "CURRENT_DESCARGAR_CLS";
export const SET_CURRENT_TAGS_CLS = "SET_CURRENT_TAGS_CLS";
export const GET_INFO_PLANTAS_CLS = "GET_INFO_PLANTAS_CLS";
export const RESET_INFO_PLANTAS_CLS = "RESET_INFO_PLANTAS_CLS";
export const GET_INFO_FACTURACION_CLS = "GET_INFO_FACTURACION_CLS";
export const RESET_INFO_FACTURACION_CLS = "RESET_INFO_FACTURACION_CLS";
export const SHOW_CAR_WS_CLS = "SHOW_CAR_WS_CLS";
export const SHOW_MAP_WS_CLS = "SHOW_MAP_WS_CLS";
export const SHOW_CAR_SIN_GEOTAB = "SHOW_CAR_SIN_GEOTAB";
export const SHOW_MAP_SIN_GEOTAB = "SHOW_MAP_SIN_GEOTAB";
export const GET_CHOFERES_CLS = "GET_CHOFER_CLS";
export const GET_BALANZA_CLS = "GET_BALANZA_CLS";
export const RESET_VIAJES_CLS = "RESET_VIAJES_CLS";
export const GET_TRANSPORTISTAS_CLS = "GET_TRANSPORTISTAS_CLS";
export const GET_ORIGENES_CLS = "GET_ORIGENES_CLS";
export const GET_LUGARES_CLS = "GET_LUGARES_CLS";
export const GET_CARGAS_CLS = "GET_CARGAS_CLS";
export const GET_DESTINOS_CLS = "GET_DESTINOS_CLS";
export const GET_OPERACIONES_FINALIZABLES = "GET_OPERACIONES_FINALIZABLES";
export const GET_ULTIMASOPERACIONES_CLS = "GET_ULTIMASOPERACIONES_CLS";
export const GET_OPERACIONID_CLS = "GET_OPERACIONID_CLS";
export const GET_OPERACIONES_FINALIZABLES_MANUAL =
  "GET_OPERACIONES_FINALIZABLES_MANUAL";
export const GET_TIPOS_TALONARIOS = "GET_TIPOS_TALONARIOS";
export const RESET_OPERACIONID_CLS = "RESET_OPERACIONID_CLS";
export const GET_ACOPLADOS_CLS = "GET_ACOPLADOS_CLS";
export const GET_CHASIS_CLS = "GET_CHASIS_CLS";
export const GET_TIPOSASOCIABLES_TALONARIOS = "GET_TIPOSASOCIABLES_TALONARIOS";
export const GET_KEY = "GET_KEY";
export const GET_OPERACIONES_ABIERTAS = "GET_OPERACIONES_ABIERTAS";
export const GET_OPERACIONES_CERRADAS = "GET_OPERACIONES_CERRADAS";
export const GET_PARTE_DIARIO = "GET_PARTE_DIARIO";
export const GET_OPERACIONES_TODAS = "GET_OPERACIONES_TODAS";
export const GET_OPERACIONES_OFFLINE = "GET_OPERACIONES_OFFLINE";
export const GET_OPERACIONES_FALLIDAS = "GET_OPERACIONES_FALLIDAS";
