import React, { useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Head,
  DrawerContainer,
  ListItemAnchor,
  Title,
  CategoryTitle,
  AccordionContainer,
  AccordionSummaryContainer,
  AccordionDetailsContainer,
  ListContainer,
} from "./Styles";
import { Link } from "react-router-dom";

import { Grid } from "@material-ui/core";

const HeaderPad = ({
  setShowModalCargarOperacion,
  setShowModalChofer,
  setShowModalTransportista,
  setShowModalCarga,
  setShowModalLugar,
  setShowModalPatenteChasis,
  setShowModalPatenteAcoplado,
  setShowModalEditarChofer,
  setShowModalEditarTransportista,
  setShowModalEditarCarga,
  setShowModalEditarLugar,
  setShowModalEditarPatenteChasis,
  setShowModalEditarPatenteAcoplado,
  setShowModalOperacionId,
  setShowModalIniciarOperacion,
  setShowModalEditarTalonario,
  setShowModalFinalizarOperacion,
  setShowModalTalonario,
  setShowCerrarTalonario,
  setShowModalCrearPesaje,
  setShowModalReimprimir,
}) => {
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [expandedSubcategory, setExpandedSubcategory] = useState(null);
  const handleCategoryChange = (category) => (_, isExpanded) => {
    setExpandedCategory(isExpanded ? category : null);
    setExpandedSubcategory(null); // Reiniciar las subcategorías al cambiar la categoría principal
  };
  const handleSubcategoryChange = (subcategory) => (event, isExpanded) => {
    event.stopPropagation();
    setExpandedSubcategory(isExpanded ? subcategory : null);
  };
  const closeMenuChofer = () => {
    setShowModalChofer(true);
  };
  const closeMenuTransportista = () => {
    setShowModalTransportista(true);
  };
  const closeMenuCarga = () => {
    setShowModalCarga(true);
  };
  const closeMenuLugar = () => {
    setShowModalLugar(true);
  };
  const closeMenuPatenteChasis = () => {
    setShowModalPatenteChasis(true);
  };
  const closeMenuPatenteAcoplado = () => {
    setShowModalPatenteAcoplado(true);
  };
  const closeMenuEditarChofer = () => {
    setShowModalEditarChofer(true);
  };
  const closeMenuEditarTransportista = () => {
    setShowModalEditarTransportista(true);
  };
  const closeMenuEditarCarga = () => {
    setShowModalEditarCarga(true);
  };
  const closeMenuEditarLugar = () => {
    setShowModalEditarLugar(true);
  };
  const closeMenuEditarPatenteChasis = () => {
    setShowModalEditarPatenteChasis(true);
  };
  const closeMenuEditarPatenteAcoplado = () => {
    setShowModalEditarPatenteAcoplado(true);
  };
  const closeMenuOperacionId = () => {
    setShowModalOperacionId(true);
  };
  const closeMenuIniciarOperacion = () => {
    setShowModalIniciarOperacion(true);
  };
  const closeMenuCrearPesaje = () => {
    setShowModalCrearPesaje(true);
  };
  const closeMenuFinalizarOperacion = () => {
    setShowModalFinalizarOperacion(true);
  };
  const closeMenuEditarTalonario = () => {
    setShowModalEditarTalonario(true);
  };
  const closeMenuTalonario = () => {
    setShowModalTalonario(true);
  };
  const closeCerrarTalonario = () => {
    setShowCerrarTalonario(true);
  };
  const closeMenuReimprimir = () => {
    setShowModalReimprimir(true);
  };
  const closeMenuCargarOperacion = () => {
    setShowModalCargarOperacion(true);
  };

  return (
    <Grid>
      <Head></Head>
      <Grid container>
        <Grid item sx={12} md={12} style={{ backgroundColor: "red" }}>
          <DrawerContainer>
            <Title>Administración de Balanza</Title>
            <ListContainer>
              <AccordionContainer
                expanded={expandedCategory === "operaciones"}
                onChange={handleCategoryChange("operaciones")}
              >
                <AccordionSummaryContainer expandIcon={<ExpandMoreIcon />}>
                  <CategoryTitle>Operaciones</CategoryTitle>
                </AccordionSummaryContainer>
                <AccordionDetailsContainer>
                  <ListContainer>
                    <ListItemAnchor>
                      <Link to="#" onClick={() => closeMenuCrearPesaje()}>
                        Crear pesaje offline
                      </Link>
                    </ListItemAnchor>
                    <ListItemAnchor>
                      <Link to="#" onClick={() => closeMenuCargarOperacion()}>
                        Validar carga offline
                      </Link>
                    </ListItemAnchor>
                    <ListItemAnchor>
                      <Link to="#" onClick={() => closeMenuOperacionId()}>
                        Anular operación
                      </Link>
                    </ListItemAnchor>
                    <ListItemAnchor>
                      <Link to="#" onClick={() => closeMenuReimprimir()}>
                        Reimprimir remitos/autorizaciones
                      </Link>
                    </ListItemAnchor>
                    <ListItemAnchor>
                      <Link to="#" onClick={() => closeMenuIniciarOperacion()}>
                        Iniciar operación sin pesaje
                      </Link>
                    </ListItemAnchor>
                    <ListItemAnchor>
                      <Link
                        to="#"
                        onClick={() => closeMenuFinalizarOperacion()}
                      >
                        Finalizar operación sin pesaje
                      </Link>
                    </ListItemAnchor>
                  </ListContainer>
                </AccordionDetailsContainer>
              </AccordionContainer>

              <AccordionContainer
                expanded={expandedCategory === "transportistas"}
                onChange={handleCategoryChange("transportistas")}
              >
                <AccordionSummaryContainer expandIcon={<ExpandMoreIcon />}>
                  <CategoryTitle>Transportistas</CategoryTitle>
                </AccordionSummaryContainer>

                {/* Subcategory: Empresas */}
                <AccordionContainer
                  expanded={expandedSubcategory === "empresas"}
                  onChange={handleSubcategoryChange("empresas")}
                  style={{ marginLeft: "1rem" }}
                >
                  <AccordionSummaryContainer expandIcon={<ExpandMoreIcon />}>
                    <CategoryTitle>Empresas</CategoryTitle>
                  </AccordionSummaryContainer>
                  <AccordionDetailsContainer>
                    <ListContainer>
                      <ListItemAnchor>
                        <Link to="#" onClick={() => closeMenuTransportista()}>
                          Alta transportista
                        </Link>
                      </ListItemAnchor>
                      <ListItemAnchor>
                        <Link
                          to="#"
                          onClick={() => closeMenuEditarTransportista()}
                        >
                          Editar transportista
                        </Link>
                      </ListItemAnchor>
                    </ListContainer>
                  </AccordionDetailsContainer>
                </AccordionContainer>

                {/* Subcategory: Choferes */}
                <AccordionContainer
                  expanded={expandedSubcategory === "choferes"}
                  onChange={handleSubcategoryChange("choferes")}
                  style={{ marginLeft: "1rem" }}
                >
                  <AccordionSummaryContainer expandIcon={<ExpandMoreIcon />}>
                    <CategoryTitle>Choferes</CategoryTitle>
                  </AccordionSummaryContainer>
                  <AccordionDetailsContainer>
                    <ListContainer>
                      <ListItemAnchor>
                        <Link to="#" onClick={() => closeMenuChofer()}>
                          Alta chofer
                        </Link>
                      </ListItemAnchor>
                      <ListItemAnchor>
                        <Link to="#" onClick={() => closeMenuEditarChofer()}>
                          Editar chofer
                        </Link>
                      </ListItemAnchor>
                    </ListContainer>
                  </AccordionDetailsContainer>
                </AccordionContainer>

                {/* Subcategory: Patentes */}
                <AccordionContainer
                  expanded={expandedSubcategory === "patentes"}
                  onChange={handleSubcategoryChange("patentes")}
                  style={{ marginLeft: "1rem" }}
                >
                  <AccordionSummaryContainer expandIcon={<ExpandMoreIcon />}>
                    <CategoryTitle>Patentes</CategoryTitle>
                  </AccordionSummaryContainer>
                  <AccordionDetailsContainer>
                    <ListContainer>
                      <ListItemAnchor>
                        <Link to="#" onClick={() => closeMenuPatenteChasis()}>
                          Alta patente de un camión
                        </Link>
                      </ListItemAnchor>
                      <ListItemAnchor>
                        <Link
                          to="#"
                          onClick={() => closeMenuEditarPatenteChasis()}
                        >
                          Editar patente de un camión
                        </Link>
                      </ListItemAnchor>
                      <ListItemAnchor>
                        <Link to="#" onClick={() => closeMenuPatenteAcoplado()}>
                          Alta patente de un acoplado
                        </Link>
                      </ListItemAnchor>
                      <ListItemAnchor>
                        <Link
                          to="#"
                          onClick={() => closeMenuEditarPatenteAcoplado()}
                        >
                          Editar patente de un acoplado
                        </Link>
                      </ListItemAnchor>
                    </ListContainer>
                  </AccordionDetailsContainer>
                </AccordionContainer>
              </AccordionContainer>
              <AccordionContainer
                expanded={expandedCategory === "talonarios"}
                onChange={handleCategoryChange("talonarios")}
              >
                <AccordionSummaryContainer expandIcon={<ExpandMoreIcon />}>
                  <CategoryTitle>Guías y Talonarios</CategoryTitle>
                </AccordionSummaryContainer>
                <AccordionDetailsContainer>
                  <ListContainer>
                    <ListItemAnchor>
                      <Link to="#" onClick={() => closeMenuTalonario()}>
                        Alta talonario
                      </Link>
                    </ListItemAnchor>
                    {/* <ListItemAnchor>
                      <Link to="#" onClick={() => closeCerrarTalonario()}>
                        Cerrar talonario
                      </Link>
                    </ListItemAnchor> */}
                    <ListItemAnchor>
                      <Link to="#" onClick={() => closeMenuEditarTalonario()}>
                        Transferir talonario
                      </Link>
                    </ListItemAnchor>
                  </ListContainer>
                </AccordionDetailsContainer>
              </AccordionContainer>
              <AccordionContainer
                expanded={expandedCategory === "cargas"}
                onChange={handleCategoryChange("cargas")}
              >
                <AccordionSummaryContainer expandIcon={<ExpandMoreIcon />}>
                  <CategoryTitle>Cargas</CategoryTitle>
                </AccordionSummaryContainer>
                <AccordionDetailsContainer>
                  <ListContainer>
                    <ListItemAnchor>
                      <Link to="#" onClick={() => closeMenuCarga()}>
                        Alta carga
                      </Link>
                    </ListItemAnchor>
                    <ListItemAnchor>
                      <Link to="#" onClick={() => closeMenuEditarCarga()}>
                        Editar carga
                      </Link>
                    </ListItemAnchor>
                  </ListContainer>
                </AccordionDetailsContainer>
              </AccordionContainer>
              <AccordionContainer
                expanded={expandedCategory === "lugares"}
                onChange={handleCategoryChange("lugares")}
              >
                <AccordionSummaryContainer expandIcon={<ExpandMoreIcon />}>
                  <CategoryTitle>Áreas de influencia</CategoryTitle>
                </AccordionSummaryContainer>
                <AccordionDetailsContainer>
                  <ListContainer>
                    <ListItemAnchor>
                      <Link to="#" onClick={() => closeMenuLugar()}>
                        Alta origen/destino
                      </Link>
                    </ListItemAnchor>
                    <ListItemAnchor>
                      <Link to="#" onClick={() => closeMenuEditarLugar()}>
                        Editar origen/destino
                      </Link>
                    </ListItemAnchor>
                  </ListContainer>
                </AccordionDetailsContainer>
              </AccordionContainer>
            </ListContainer>
          </DrawerContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HeaderPad;
