import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { Grid } from "@material-ui/core";
import { FormControl, InputLabel, FormHelperText } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {
  Background,
  ModalWrapper,
  Head,
  IconContainer,
  ButtonContainer,
  StyledForm,
} from "./Styles";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

/* Componentes */
import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";

/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Inputs } from "../../Input/Inputs";
// import SelectInput from "../../SelectInput/SelectInput";

import { iniciarViajePost } from "../../../Services";
import { setLoading } from "../../../actions/loadingActions";
import { validateError } from "../../../Services/validateError";
import { URL_API } from "../../../constants/apis";

let TOKEN = "";
let axiosAuth = "";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  // bal_entrada_id: yup.number().required("Campo requerido"),
  chasis_patente: yup.string().required("Campo requerido"),
  // acoplado_patente: yup.string().required("Campo requerido"),
  transportista_id: yup.string().required("Campo requerido"),
  chofer_id: yup.string().required("Campo requerido"),
  carga_id: yup.string().required("Campo requerido"),
  origen_id: yup.string().required("Campo requerido"),
  destino_id: yup.string().required("Campo requerido"),
  comentario: yup.string(),
  // tara: yup.number(),
  // remito: yup.number(),
  // guia_numero: yup.number(),
});

export const ModalIniciarOperacion = ({ onClose }) => {
  const dispatch = useDispatch();

  const { cargas } = useSelector((state) => state.cargas);
  const { transportistas } = useSelector((state) => state.transportistas);
  const { choferes } = useSelector((state) => state.choferes);
  const { origenes } = useSelector((state) => state.origenes);
  const { destinos } = useSelector((state) => state.destinos);
  const { chasis } = useSelector((state) => state.chasis);
  const { acoplados } = useSelector((state) => state.acoplados);
  useEffect(() => {
    /* Inicializa la configuracion para peticiones con header authorization */
    const initAxiosAuth = () => {
      TOKEN = window.sessionStorage.getItem("Token");
      axiosAuth = axios.create({
        withCredentials: false,
        headers: { Authorization: `Bearer ${TOKEN}` },
      });
    };

    /* Valida que exista el token para iniciar la configuracion del axios */
    if (window.sessionStorage.getItem("Token")) {
      console.log("Token exists. Initializing axiosAuth.");
      initAxiosAuth();
    } else {
      console.log("Token is missing. axiosAuth will not be initialized.");
    }
  }, []);
  const formik = useFormik({
    initialValues: {
      bal_entrada_id: null,
      chasis_patente: "",
      acoplado_patente: "",
      transportista_id: "",
      chofer_id: "",
      carga_id: "",
      origen_id: "",
      destino_id: "",
      comentario: "",
      tara: null,
      remito: null,
      guia_numero: null,
      // volumen: null,
    },

    validationSchema: validationSchema,

    onSubmit: async (values) => {
      // Convertir las patentes a mayúsculas
      const chasisPatente = values.chasis_patente.toUpperCase();
      // const acopladoPatente = values.acoplado_patente.toUpperCase();
      // Buscar IDs correspondientes
      const transportistaId = transportistas.find(
        (transportista) => transportista.nombre === values.transportista_id
      )?.id;

      const choferId = choferes.find(
        (chofer) => `${chofer.nombre} ${chofer.apellido}` === values.chofer_id
      )?.id;

      const cargaId = cargas.find(
        (carga) => carga.nombre === values.carga_id
      )?.id;

      const origenId = origenes.find(
        (origen) => origen.nombre === values.origen_id
      )?.id;

      const destinoId = destinos.find(
        (destino) => destino.nombre === values.destino_id
      )?.id;

      if (values.remito === "") {
        values.remito = null;
      }
      if (values.guia_numero === "") {
        values.guia_numero = null;
      }
      if (values.acoplado_patente === "") {
        values.acoplado_patente = null;
      }
      // Crear un nuevo objeto con los IDs encontrados
      const updatedValues = {
        bal_entrada_id: values.bal_entrada_id,
        remito: values.remito,
        guia_numero: values.guia_numero,
        // volumen: values.volumen,
        comentario: values.comentario,
        talonario_numero: values.talonario,
        chasis_patente: chasisPatente,
        acoplado_patente: values.acoplado_patente,
        transportista_id: transportistaId,
        chofer_id: choferId,
        carga_id: cargaId,
        origen_id: origenId,
        destino_id: destinoId,
      };

      // Enviar la solicitud con los IDs actualizados
      dispatch(iniciarViajePost(updatedValues));
      onClose();
    },
  });
  const SelectInput = ({
    label,
    name,
    value,
    onChange,
    options,
    error,
    disabled,
  }) => {
    const handleSelectChange = (event, selectedOption) => {
      onChange({
        target: {
          name,
          value: selectedOption ? selectedOption.value : "",
        },
      });
    };

    return (
      <FormControl fullWidth>
        <Autocomplete
          options={options}
          getOptionLabel={(option) => option.label}
          value={options.find((option) => option.value === value) || null}
          onChange={handleSelectChange}
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={error}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  };
  const handlePatenteChange = (e) => {
    const patenteValue = e.target.value.toUpperCase();
    formik.setFieldValue("chasis_patente", patenteValue);
    const MIN_PATENTE_LENGTH = 7; // Ajusta este valor según tus necesidades
    const MIN_PATENTE_LENGTH2 = 6; // Ajusta este valor según tus necesidades
    const regexFormat1 = /^[A-Z]{3}\d{3}$/; // Formato de 3 letras y 3 números
    const regexFormat2 = /^[A-Z]{2}\d{3}[A-Z]{2}$/; // Formato de 2 letras, 3 números y 2 letras

    if (
      (patenteValue.length === MIN_PATENTE_LENGTH &&
        regexFormat2.test(patenteValue)) ||
      (patenteValue.length === MIN_PATENTE_LENGTH2 &&
        regexFormat1.test(patenteValue))
    ) {
      dispatch(setLoading(true));

      // Primera consulta para obtener la operación
      axiosAuth
        .get(`${URL_API}balanza/getOperacion?chasis_patente=${patenteValue}`)
        .then(function (response) {
          const { data } = response;
          formik.setValues((prevValues) => ({
            ...prevValues,
            chasis_patente: patenteValue,
            acoplado_patente: data.acoplado?.patente,
            transportista_id: data.transportista?.nombre,
            chofer_id: `${data.chofer?.nombre} ${data.chofer?.apellido}`,
            carga_id: data.tipo?.carga?.nombre,
            origen_id: data.tipo?.origen?.nombre,
            destino_id: data.tipo?.destino?.nombre,
          }));
        })
        .catch(function (error) {
          dispatch(validateError(error));
        })
        .finally(function () {
          dispatch(setLoading(false));
        });
    } else {
      formik.setValues((prevValues) => ({
        ...prevValues,
        chasis_patente: patenteValue,
        acoplado_patente: "",
        transportista_id: "",
        chofer_id: "",
        carga_id: "",
        origen_id: "",
        destino_id: "",
        tara: "",
      }));
    }
  };

  const handleRecomendarClick = () => {
    const chasis = formik?.values?.chasis_patente?.toUpperCase();
    const acoplado = formik?.values?.acoplado_patente?.toUpperCase();
    // Buscar IDs correspondientes
    const transportista_id = transportistas?.find(
      (transportista) =>
        transportista?.nombre === formik?.values?.transportista_id
    )?.id;
    const chofer_id = choferes?.find(
      (chofer) =>
        `${chofer?.nombre} ${chofer?.apellido}` === formik?.values?.chofer_id
    )?.id;
    const carga_id = cargas?.find(
      (carga) => carga?.nombre === formik?.values?.carga_id
    )?.id;
    const origen_id = origenes?.find(
      (origen) => origen?.nombre === formik?.values?.origen_id
    )?.id;
    const destino_id = destinos?.find(
      (destino) => destino?.nombre === formik?.values?.destino_id
    )?.id;

    dispatch(setLoading(true));
    if (
      !chasis ||
      !acoplado ||
      !origen_id ||
      !destino_id ||
      !carga_id ||
      !chofer_id ||
      !transportista_id
    ) {
      dispatch(validateError("Faltan datos para recomendar guía"));
      dispatch(setLoading(false));
      return;
    } else {
      axiosAuth
        .get(
          `${URL_API}balanza/recomendarGuiaRemito?chasis_patente=${chasis}&acoplado_patente=${acoplado}&origen_id=${origen_id}&destino_id=${destino_id}&carga_id=${carga_id}&chofer_id=${chofer_id}&transportista_id=${transportista_id}`
        )
        .then(function (response) {
          const { data } = response;
          formik.setValues((prevValues) => ({
            ...prevValues,
            remito: data.remito,
            guia_numero: data.guia,
            talonario: data.talonario,
          }));
        })
        .catch(function (error) {
          dispatch(validateError(error));
        })
        .finally(function () {
          dispatch(setLoading(false));
        });
    }
  };

  const handleClose = () => {
    onClose();
  };

  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper onClose={() => handleClose()}>
        <Head>
          <p>Iniciar operación sin pesaje</p>
          <IconContainer>
            <FontAwesomeIcon icon={faTimes} onClick={onClose} />
          </IconContainer>
        </Head>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid container md={12} sm={8} justify="center">
            <SelectInput
              label="Chasis"
              name="chasis_patente"
              value={formik.values.chasis_patente}
              onChange={handlePatenteChange}
              error={
                formik.errors.chasis_patente && formik.touched.chasis_patente
              }
              options={chasis?.map((chasis) => ({
                value: chasis.patente,
                label: chasis.patente,
              }))}
            />
            {formik.errors.chasis_patente && formik.touched.chasis_patente && (
              <FormHelperText
                error={
                  formik.errors.chasis_patente && formik.touched.chasis_patente
                }
              >
                {formik.errors?.chasis_patente}
              </FormHelperText>
            )}
            <SelectInput
              label="Acoplado"
              name="acoplado_patente"
              value={formik.values.acoplado_patente}
              onChange={formik.handleChange}
              error={
                formik.errors.acoplado_patente &&
                formik.touched.acoplado_patente
              }
              options={acoplados.map((acoplado) => ({
                value: acoplado.patente,
                label: acoplado.patente,
              }))}
            />
            {formik.errors.acoplado_patente &&
              formik.touched.acoplado_patente && (
                <FormHelperText
                  error={
                    formik.errors.acoplado_patente &&
                    formik.touched.acoplado_patente
                  }
                >
                  {formik.errors?.acoplado_patente}
                </FormHelperText>
              )}
            <SelectInput
              label="Empresa Transportista"
              name="transportista_id"
              value={formik.values.transportista_id}
              onChange={formik.handleChange}
              error={
                formik.errors.transportista_id &&
                formik.touched.transportista_id
              }
              options={transportistas.map((transportista) => ({
                value: transportista.nombre,
                label: transportista.nombre,
              }))}
            />
            {formik.errors.transportista_id &&
              formik.touched.transportista_id && (
                <FormHelperText
                  error={
                    formik.errors.transportista_id &&
                    formik.touched.transportista_id
                  }
                >
                  {formik.errors?.transportista_id}
                </FormHelperText>
              )}
            <SelectInput
              label="Chofer"
              name="chofer_id"
              value={formik.values.chofer_id}
              onChange={formik.handleChange}
              error={formik.errors.chofer_id && formik.touched.chofer_id}
              options={choferes.map((chofer) => ({
                value: `${chofer.nombre} ${chofer.apellido}`,
                label: `${chofer.nombre} ${chofer.apellido}`,
              }))}
            />
            {formik.errors.chofer_id && formik.touched.chofer_id && (
              <FormHelperText
                error={formik.errors.chofer_id && formik.touched.chofer_id}
              >
                {formik.errors?.chofer_id}
              </FormHelperText>
            )}

            <SelectInput
              label="Tipo de producto"
              type="text"
              name="carga_id"
              value={formik.values.carga_id}
              onChange={formik.handleChange}
              error={formik.errors.carga_id && formik.touched.carga_id}
              options={cargas.map((carga) => ({
                value: carga.nombre,
                label: carga.nombre,
              }))}
            />
            {formik.errors.carga_id && formik.touched.carga_id && (
              <FormHelperText
                error={formik.errors.carga_id && formik.touched.carga_id}
              >
                {formik.errors?.carga_id}
              </FormHelperText>
            )}

            <SelectInput
              label="Origen"
              type="text"
              name="origen_id"
              value={formik.values.origen_id}
              onChange={formik.handleChange}
              error={formik.errors.origen_id && formik.touched.origen_id}
              options={origenes.map((origen) => ({
                value: origen.nombre,
                label: origen.nombre,
              }))}
            />
            {formik.errors.origen_id && formik.touched.origen_id && (
              <FormHelperText
                error={formik.errors.origen_id && formik.touched.origen_id}
              >
                {formik.errors?.origen_id}
              </FormHelperText>
            )}
            <SelectInput
              label="Destino"
              type="text"
              name="destino_id"
              value={formik.values.destino_id}
              onChange={formik.handleChange}
              error={formik.errors.destino_id && formik.touched.destino_id}
              options={destinos.map((destino) => ({
                value: destino.nombre,
                label: destino.nombre,
              }))}
            />
            {formik.errors.destino_id && formik.touched.destino_id && (
              <FormHelperText
                error={formik.errors.destino_id && formik.touched.destino_id}
              >
                {formik.errors?.destino_id}
              </FormHelperText>
            )}

            {/* <Grid item sm={12}>
              <InputLabel
                id="tara"
                style={{ marginTop: "1rem", fontSize: "13px" }}
              >
                Tara (Tn)
              </InputLabel>
              <Inputs
                type="number"
                name="tara"
                disabled
                value={formik.values.tara}
                onChange={formik.handleChange}
              />
            </Grid> */}

            {/* <Grid item sm={12}>
              <InputLabel
                id="volumen"
                style={{ marginTop: "1rem", fontSize: "13px" }}
              >
                Volumen (m3)
              </InputLabel> */}

            {/* <Inputs
                type="number"
                name="volumen"
                value={formik.values.volumen}
                onChange={formik.handleChange}
              />
            </Grid> */}
            <Grid item sm={3}>
              <InputLabel
                id="remito"
                style={{ marginTop: "1rem", fontSize: "13px" }}
              >
                Remito
              </InputLabel>
              <Inputs
                type="number"
                name="remito"
                value={formik.values.remito}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item sm={3}>
              <InputLabel
                id="guia_numero"
                style={{ marginTop: "1rem", fontSize: "13px" }}
              >
                Guía
              </InputLabel>
              <Inputs
                type="number"
                name="guia_numero"
                value={formik.values.guia_numero}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item sm={3}>
              <InputLabel
                id="talonario"
                style={{ marginTop: "1rem", fontSize: "13px" }}
              >
                Talonario
              </InputLabel>
              <Inputs
                type="number"
                name="talonario"
                value={formik.values.talonario}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item sm={3}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  // disabled={validated}
                  type="button"
                  textArea="Recomendar"
                  variant="outlined"
                  color="primary"
                  mright="true"
                  onClick={handleRecomendarClick}
                  // minheight={true}
                />
              </ButtonContainer>
            </Grid>
            <Grid item sm={12}>
              <InputLabel
                id="comentario"
                style={{ marginTop: "1rem", fontSize: "13px" }}
              >
                Comentario
              </InputLabel>
              <Inputs
                type="text"
                name="comentario"
                value={formik.values.comentario}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item sm={6}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  type="button"
                  textArea="Cancelar" /* Acà se puede validar el tipo de boton */
                  onClick={() => handleClose()}
                  variant="outlined"
                  color="primary"
                  minwidth="true"
                />
              </ButtonContainer>
            </Grid>

            <Grid item sm={6}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  // disabled={validated}
                  type="submit"
                  textArea="Iniciar"
                  variant="contained"
                  color="primary"
                  mright="true"
                />
              </ButtonContainer>
            </Grid>
          </Grid>
        </StyledForm>
      </ModalWrapper>
    </Background>,
    document.getElementById("root")
  );
};
