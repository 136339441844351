import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Grid } from "@material-ui/core";
import {
  FormControl,
  InputLabel,
  FormHelperText,
  MenuItem,
  Select as MuiSelect,
} from "@material-ui/core";
import {
  Background,
  ModalWrapper,
  Head,
  IconContainer,
  ButtonContainer,
  StyledForm,
} from "./Styles";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

/* Componentes */
import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";

/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Inputs } from "../../Input/Inputs";
import { createCarga, getCargas } from "../../../Services";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  nombre: yup.string().required("Campo requerido"),
  volumen: yup.boolean().required("Campo requerido"),
  tipo: yup.string().required("Campo requerido"),
});

export const ModalCarga = ({ onClose }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCargas());
  }, [dispatch]);

  const { cargas } = useSelector((state) => state.cargas);

  const formik = useFormik({
    initialValues: {
      nombre: "",
      volumen: "",
      tipo: "",
    },

    validationSchema: validationSchema,

    onSubmit: (values) => {
      dispatch(
        createCarga({
          nombre: values.nombre.toUpperCase(),
          volumen: values.volumen,
          tipo: values.tipo,
        })
      );
      onClose();
    },
  });
  const handleClose = () => {
    onClose();
  };

  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper onClose={() => handleClose()}>
        <Head>
          <p>Dar de alta a un tipo de carga</p>
          <IconContainer>
            <FontAwesomeIcon icon={faTimes} onClick={onClose} />
          </IconContainer>
        </Head>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid container md={12} sm={8} justify="center">
            <Inputs
              label="Nombre"
              name="nombre"
              value={formik.values.nombre}
              onChange={formik.handleChange}
              error={formik.errors.nombre && formik.touched.nombre}
            />
            {formik.errors.nombre && formik.touched.nombre && (
              <FormHelperText
                error={formik.errors.nombre && formik.touched.nombre}
              >
                {formik.errors?.nombre}
              </FormHelperText>
            )}

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="volumen">¿Pasa por balanza?</InputLabel>
                <MuiSelect
                  labelId="volumen"
                  name="volumen"
                  value={formik.values.volumen}
                  onChange={formik.handleChange}
                  error={formik.errors.volumen && formik.touched.volumen}
                >
                  <MenuItem value={true}>Si</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </MuiSelect>
                {formik.errors.volumen && formik.touched.volumen && (
                  <FormHelperText
                    error={formik.errors.volumen && formik.touched.volumen}
                  >
                    {formik.errors?.volumen}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="tipo">Tipo de producto</InputLabel>
                <MuiSelect
                  labelId="tipo"
                  name="tipo"
                  value={formik.values.tipo}
                  onChange={formik.handleChange}
                  error={formik.errors.tipo && formik.touched.tipo}
                >
                  {[...new Set(cargas.map((carga) => carga.tipo))].map(
                    (tipo, index) => (
                      <MenuItem key={index} value={tipo}>
                        {tipo}
                      </MenuItem>
                    )
                  )}
                </MuiSelect>
                {formik.errors.tipo && formik.touched.tipo && (
                  <FormHelperText
                    error={formik.errors.tipo && formik.touched.tipo}
                  >
                    {formik.errors?.tipo}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  type="button"
                  textArea="Cancelar" /* Acà se puede validar el tipo de boton */
                  onClick={() => handleClose()}
                  variant="outlined"
                  color="primary"
                  minwidth="true"
                />
              </ButtonContainer>
            </Grid>
            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  // disabled={validated}
                  type="submit"
                  textArea="Crear"
                  variant="contained"
                  color="primary"
                  mright="true"
                />
              </ButtonContainer>
            </Grid>
          </Grid>
        </StyledForm>
      </ModalWrapper>
    </Background>,
    document.getElementById("root")
  );
};
