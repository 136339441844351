import styled from "styled-components";
import { Nav, Button } from "react-bootstrap";

export const StyledNav = styled(Nav)`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  @media (max-width: 768px) {
    h4 {
      font-size: 1rem;
    }
  }
`;

export const StyledButton = styled(Button)`
  margin-top: auto;
  margin-bottom: 20px;
  background-color: #c1c5c8;
  color: #4f4184;
  border: none;
  a {
    color: #4f4184;
    border: none;
    background-color: #c1c5c8;
  }

  &:hover,
  &:focus,
  &:active,
  &:visited {
    background-color: #c1c5c8;
    color: #4f4184;
    border: none;
  }
`;

export const LogoVista = styled.img`
  margin: 0 auto 10px auto;
  width: 3.625rem;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  margin-top: 100px;
  margin-bottom: 50px;
  background: transparent;
`;

export const VerticalTitle = styled.h5`
  writing-mode: vertical-lr;
  text-orientation: upright;
  color: #4f4184;
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  margin: 0 auto 10px auto;
  margin-top: 100px;
`;

export const Title = styled.h5`
  color: #4f4184;
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  margin: 0 auto 10px auto;
  margin-top: 100px;
  margin-bottom: -50px;

  @media (max-width: 768px) {
    font-size: 1rem;
`;
export const SidebarWrapper = styled.div`
  width: ${(props) => (props.isExpanded ? "125px" : "75px")};
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #c1c5c8;
  color: #ffffff;
  overflow-x: hidden;
  padding-top: 60px;
  z-index: 1000;

  a {
    color: #4f4184;
  }

  @media (max-width: 768px) {
    width: 90px;
    visibility: ${(props) => (props.isExpanded ? "visible" : "hidden")};
    overflow-x: hidden;
    #styled-button {
      visibility: visible;
      transition: 0.5s;
      background-color: #c1c5c8;
      color: #4f4184;
      border: ${(props) => (props.isExpanded ? "none" : "2px solid #4f4184")};
    }
  }
`;
