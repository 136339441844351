import {
  GET_OPERACIONID_CLS,
  RESET_OPERACIONID_CLS,
} from "../../constants/actionsTypes";

const initialState = {};

export const operacionIdReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_OPERACIONID_CLS: {
      return { ...state, ...payload };
    }
    case RESET_OPERACIONID_CLS: {
      return { ...initialState };
    }
    default:
      return state;
  }
};
