import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import {
  Grid,
  FormHelperText,
  InputLabel,
  Input,
  Select,
  MenuItem,
} from "@material-ui/core";
import {
  Background,
  ModalWrapper,
  Head,
  IconContainer,
  FormGroup,
  ButtonContainer,
  StyledForm,
} from "./Styles";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

/* Componentes */
import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";

/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
// import { addHours } from "../../../utils/DateTimeConverter/DateTimeConverter";
import { getTalonarioTipos, getParteMineria } from "../../../Services";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  fecha_inicio: yup.string().required("La fecha de inicio es requerida"),
  fecha_fin: yup.string().required("La fecha de fin es requerida"),
  // talonario: yup.number(),
  // talonario_tipo: yup.number(),
});

export const ModalParteMineria = ({ onClose }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTalonarioTipos());
  }, [dispatch]);
  const { tipos } = useSelector((state) => state.talonarioTipos);
  console.log(tipos);
  const formik = useFormik({
    initialValues: {
      fecha_inicio: "",
      fecha_fin: "",
      talonario: null,
      talonario_tipo: null,
    },

    validationSchema: validationSchema,

    onSubmit: (values) => {
      dispatch(
        getParteMineria(
          values.fecha_inicio,
          values.fecha_fin,
          values.talonario,
          values.talonario_tipo
        )
      );
      onClose();
    },
  });

  const handleClose = () => {
    onClose();
  };

  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper onClose={() => handleClose()}>
        <Head>
          <p>Indique el rango de fechas del parte de mineria</p>
          <IconContainer>
            <FontAwesomeIcon icon={faTimes} onClick={onClose} />
          </IconContainer>
        </Head>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid xs={12} md={6}>
              <FormGroup>
                <InputLabel id="fecha_inicio">Fecha de inicio</InputLabel>
                <Input
                  id="fecha_inicio"
                  name="fecha_inicio"
                  type="date"
                  onChange={formik.handleChange}
                  value={formik.values.fecha_inicio}
                  error={
                    formik.errors.fecha_inicio && formik.touched.fecha_inicio
                  }
                />
                {formik.errors.fecha_inicio && formik.touched.fecha_inicio && (
                  <FormHelperText
                    error={
                      formik.errors.fecha_inicio && formik.touched.fecha_inicio
                    }
                  >
                    {formik.errors?.fecha_inicio}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <InputLabel id="fecha_fin">Fecha de fin</InputLabel>
                <Input
                  id="fecha_fin"
                  name="fecha_fin"
                  type="date"
                  onChange={formik.handleChange}
                  value={formik.values.fecha_fin}
                  error={formik.errors.fecha_fin && formik.touched.fecha_fin}
                />
                {formik.errors.fecha_fin && formik.touched.fecha_fin && (
                  <FormHelperText
                    error={formik.errors.fecha_fin && formik.touched.fecha_fin}
                  >
                    {formik.errors?.fecha_fin}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <InputLabel id="talonario">Talonario</InputLabel>
                <Input
                  id="talonario"
                  name="talonario"
                  type="number"
                  onChange={formik.handleChange}
                  value={formik.values.talonario}
                  error={formik.errors.talonario && formik.touched.talonario}
                />
                {formik.errors.talonario && formik.touched.talonario && (
                  <FormHelperText
                    error={formik.errors.talonario && formik.touched.talonario}
                  >
                    {formik.errors?.talonario}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <InputLabel id="talonario_tipo">Tipo de talonario</InputLabel>
                <Select
                  id="talonario_tipo"
                  name="talonario_tipo"
                  value={formik.values.talonario_tipo}
                  onChange={formik.handleChange}
                  error={
                    formik.errors.talonario_tipo &&
                    formik.touched.talonario_tipo
                  }
                >
                  {tipos?.map((tipo) => (
                    <MenuItem key={tipo.id} value={tipo.id}>
                      {tipo.nombre}
                    </MenuItem>
                  ))}
                </Select>

                {formik.errors.talonario_tipo &&
                  formik.touched.talonario_tipo && (
                    <FormHelperText
                      error={
                        formik.errors.talonario_tipo &&
                        formik.touched.talonario_tipo
                      }
                    >
                      {formik.errors?.talonario_tipo}
                    </FormHelperText>
                  )}
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container md={12} sm={8} justify="center">
            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  type="button"
                  textArea="Cancelar" /* Acà se puede validar el tipo de boton */
                  onClick={() => handleClose()}
                  variant="outlined"
                  color="primary"
                  minwidth="true"
                />
              </ButtonContainer>
            </Grid>
            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  // disabled={validated}
                  type="submit"
                  textArea="Descargar"
                  variant="contained"
                  color="primary"
                  mright="true"
                />
              </ButtonContainer>
            </Grid>
          </Grid>
        </StyledForm>
      </ModalWrapper>
    </Background>,
    document.getElementById("root")
  );
};
