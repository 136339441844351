import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { Grid } from "@material-ui/core";
import {
  FormControl,
  InputLabel,
  FormHelperText,
  MenuItem,
  Select as MuiSelect,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import {
  Background,
  ModalWrapper,
  Head,
  IconContainer,
  ButtonContainer,
  StyledForm,
} from "./Styles";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

/* Componentes */
import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";
import { setLoading } from "../../../actions/loadingActions";
import { validateError } from "../../../Services/validateError";
import { URL_API } from "../../../constants/apis";
/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Inputs } from "../../Input/Inputs";
import {
  createTalonario,
  getTalonarioTipos,
  getChoferes,
} from "../../../Services";
let TOKEN = "";
let axiosAuth = "";

/* Inicializa la configuracion para peticiones con header authorization */
const initAxiosAuth = () => {
  TOKEN = window.sessionStorage.getItem("Token");
  axiosAuth = axios.create({
    withCredentials: false,
    headers: { Authorization: `Bearer ${TOKEN}` },
  });
};

/* Valida que exista el token para iniciar la configuracion del axios */
if (window.sessionStorage.getItem("Token")) {
  initAxiosAuth();
}
/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  numero: yup.number().required("Campo requerido"),
  creacion: yup.date().required("Campo requerido"),
  vencimiento: yup.date().required("Campo requerido"),
  abierto: yup.boolean().required("Campo requerido"),
  tipo_id: yup.string().required("Campo requerido"),
  chofer_id: yup.string(),
  guias_desde: yup.number().required("Campo requerido"),
  guias_hasta: yup.number().required("Campo requerido"),
  status: yup.string().required("Campo requerido"),
});

export const ModalTalonario = ({ onClose }) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getTalonarioTipos());
    dispatch(getChoferes());
  }, [dispatch]);
  const { choferes } = useSelector((state) => state.choferes);
  const { tipos } = useSelector((state) => state.talonarioTipos);

  const handleRecomendarClick = () => {
    const { numero, tipo_id } = formik.values;
    const tipoId = tipos?.find((tipo) => tipo.nombre === tipo_id)?.id;

    if (!numero || !tipoId) {
      dispatch(validateError("Debe completar los campos"));
      dispatch(setLoading(false));
      return;
    }

    if (tipo_id === "Despacho") {
      formik.setValues((prevValues) => ({
        ...prevValues,
        guias_desde: 1,
        guias_hasta: 25,
      }));
    } else {
      dispatch(setLoading(true));
      axiosAuth
        .get(
          `${URL_API}balanza/recomendarTalonario?numero=${numero}&tipo_id=${tipoId}`
        )
        .then(function (response) {
          const { data } = response;
          console.log(data);
          formik.setValues((prevValues) => ({
            ...prevValues,
            guias_desde: data.guias_desde,
            guias_hasta: data.guias_hasta,
          }));
          dispatch(setLoading(false));
        })
        .catch(function (error) {
          console.log(error);
          dispatch(validateError(error));
          dispatch(setLoading(false));
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      numero: "",
      creacion: new Date().toISOString().split("T")[0],
      vencimiento: "",
      abierto: "",
      tipo_id: "",
      chofer_id: "",
      guias_desde: "",
      guias_hasta: "",
      status: "",
    },

    validationSchema: validationSchema,

    onSubmit: (values) => {
      const choferId = choferes?.find(
        (chofer) => `${chofer.nombre} ${chofer.apellido}` === values.chofer_id
      )?.id;
      const talonarioId = tipos?.find(
        (tipo) => tipo.nombre === values.tipo_id
      )?.id;
      const updatedValues = {
        ...values,
        chofer_id: choferId,
        tipo_id: talonarioId,
      };
      console.log(updatedValues);
      dispatch(createTalonario(updatedValues));
      onClose();
    },
  });
  const handleClose = () => {
    onClose();
  };
  const handleTipoChange = (event) => {
    const selectedValue = event.target.value;
    const selectedOption = tipos.find((tipo) => tipo.id === selectedValue);

    formik.setValues((prevValues) => ({
      ...prevValues,
      tipo_id: selectedValue,
      chofer_id:
        selectedOption?.nombre === "Despacho" ? null : prevValues.chofer_id,
    }));
  };
  const SelectInput = ({
    label,
    name,
    value,
    onChange,
    options,
    error,
    disabled,
  }) => {
    const handleSelectChange = (event, selectedOption) => {
      onChange({
        target: {
          name,
          value: selectedOption ? selectedOption.value : "",
        },
      });
    };

    return (
      <FormControl fullWidth>
        <Autocomplete
          options={options}
          getOptionLabel={(option) => option.label}
          value={options?.find((option) => option.value === value) || null}
          onChange={handleSelectChange}
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={error}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  };
  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper onClose={() => handleClose()}>
        <Head>
          <p>Dar de alta a un talonario</p>
          <IconContainer>
            <FontAwesomeIcon icon={faTimes} onClick={onClose} />
          </IconContainer>
        </Head>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid container md={12} sm={8} justify="center">
            <Inputs
              label="Numero"
              name="numero"
              type="number"
              onChange={formik.handleChange}
              value={formik.values.numero}
              error={formik.touched.numero && Boolean(formik.errors.numero)}
            />
            {formik.touched.numero && (
              <FormHelperText error>{formik.errors.numero}</FormHelperText>
            )}

            <Grid item sm={12}>
              <InputLabel
                id="creacion"
                style={{ marginTop: "1rem", fontSize: "13px" }}
              >
                Fecha de creación
              </InputLabel>
              <Inputs
                name="creacion"
                type="date"
                onChange={formik.handleChange}
                value={formik.values.creacion}
                error={
                  formik.touched.creacion && Boolean(formik.errors.creacion)
                }
              />
              {formik.touched.creacion && (
                <FormHelperText error>{formik.errors.creacion}</FormHelperText>
              )}
            </Grid>
            <Grid item sm={12}>
              <InputLabel
                id="vencimiento"
                style={{ marginTop: "1rem", fontSize: "13px" }}
              >
                Fecha de vencimiento
              </InputLabel>
              <Inputs
                name="vencimiento"
                type="date"
                onChange={formik.handleChange}
                value={formik.values.vencimiento}
                error={
                  formik.touched.vencimiento &&
                  Boolean(formik.errors.vencimiento)
                }
              />
              {formik.touched.vencimiento && (
                <FormHelperText error>
                  {formik.errors.vencimiento}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="abierto">¿Está abierto?</InputLabel>
                <MuiSelect
                  labelId="abierto"
                  name="abierto"
                  value={formik.values.abierto}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.abierto && Boolean(formik.errors.abierto)
                  }
                >
                  <MenuItem value={true}>Si</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </MuiSelect>
              </FormControl>
              {formik.touched.abierto && (
                <FormHelperText error>{formik.errors.abierto}</FormHelperText>
              )}
            </Grid>
            <SelectInput
              label="Tipo de talonario"
              name="tipo_id"
              value={formik.values.tipo_id}
              onChange={handleTipoChange}
              options={tipos?.map((tipo) => ({
                value: tipo.nombre,
                label: tipo.nombre,
              }))}
              error={formik.touched.tipo_id && Boolean(formik.errors.tipo_id)}
            />
            {formik.touched.tipo_id && (
              <FormHelperText error>{formik.errors.tipo_id}</FormHelperText>
            )}

            {formik.values.tipo_id !== "Despacho" && (
              <SelectInput
                label="Chofer"
                name="chofer_id"
                value={formik.values.chofer_id}
                onChange={formik.handleChange}
                options={choferes?.map((chofer) => ({
                  value: `${chofer.nombre} ${chofer.apellido}`,
                  label: `${chofer.nombre} ${chofer.apellido}`,
                }))}
                error={
                  formik.touched.chofer_id && Boolean(formik.errors.chofer_id)
                }
              />
            )}
            {formik.touched.chofer_id && (
              <FormHelperText error>{formik.errors.chofer_id}</FormHelperText>
            )}

            <Grid item sm={12}>
              <FormControl fullWidth>
                <InputLabel id="status">Estado</InputLabel>
                <MuiSelect
                  labelId="status"
                  name="status"
                  value={formik.values.status}
                  onChange={formik.handleChange}
                  error={formik.touched.status && Boolean(formik.errors.status)}
                >
                  <MenuItem value={"En stock"}>En stock</MenuItem>
                  <MenuItem value={"Asociado a un chofer"}>
                    Asociado a un chofer
                  </MenuItem>
                </MuiSelect>

                {formik.touched.status && (
                  <FormHelperText error>{formik.errors.status}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item sm={4}>
              <Inputs
                label="Guia desde"
                name="guias_desde"
                type="number"
                onChange={formik.handleChange}
                value={formik.values.guias_desde}
                error={
                  formik.touched.guias_desde &&
                  Boolean(formik.errors.guias_desde)
                }
              />
              {formik.touched.guias_desde && (
                <FormHelperText error>
                  {formik.errors.guias_desde}
                </FormHelperText>
              )}
            </Grid>
            <Grid item sm={4}>
              <Inputs
                label="Guia hasta"
                name="guias_hasta"
                type="number"
                onChange={formik.handleChange}
                value={formik.values.guias_hasta}
                error={
                  formik.touched.guias_hasta &&
                  Boolean(formik.errors.guias_hasta)
                }
              />
              {formik.touched.guias_hasta && (
                <FormHelperText error>
                  {formik.errors.guias_hasta}
                </FormHelperText>
              )}
            </Grid>
            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  type="button"
                  textArea="Recomendar"
                  variant="outlined"
                  color="primary"
                  mright="true"
                  onClick={handleRecomendarClick}
                />
              </ButtonContainer>
            </Grid>
            <Grid item sm={6}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  type="button"
                  textArea="Cancelar" /* Acà se puede validar el tipo de boton */
                  onClick={() => handleClose()}
                  variant="outlined"
                  color="primary"
                  minwidth="true"
                />
              </ButtonContainer>
            </Grid>

            <Grid item sm={6}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  type="submit"
                  textArea="Crear"
                  variant="contained"
                  color="primary"
                  mright="true"
                />
              </ButtonContainer>
            </Grid>
          </Grid>
        </StyledForm>
      </ModalWrapper>
    </Background>,
    document.getElementById("root")
  );
};
