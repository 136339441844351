import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Grid } from "@material-ui/core";
import {
  FormControl,
  InputLabel,
  FormHelperText,
  MenuItem,
  Select as MuiSelect,
} from "@material-ui/core";
import {
  Background,
  ModalWrapper,
  Head,
  IconContainer,
  ButtonContainer,
  StyledForm,
} from "./Styles";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

/* Componentes */
import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";
import { ModalViajes } from "../ModalViajes/ModalViajes";

/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Inputs } from "../../Input/Inputs";
// import SelectInput from "../../SelectInput/SelectInput";
import {
  getOperacionesIniciadas,
  finalizarViajePost,
  descartarPesaje,
  registrarTara,
} from "../../../Services";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  // bal_entrada_id: yup.number(),
  chasis_patente: yup.string().required("Campo requerido"),
  acoplado_patente: yup.string(),
  transportista_id: yup.string(),
  chofer_id: yup.string(),
  carga_id: yup.string(),
  origen_id: yup.string(),
  destino_id: yup.string(),
  comentario: yup.string(),
  // remito: yup.number(),
  // guia_numero: yup.number(),
  // dt_entrada: yup.string(),
});

export const ModalViajesIniciados = ({ onClose, selectedPesaje }) => {
  const dispatch = useDispatch();
  const [showViajesModal, setShowViajesModal] = useState(false);
  useEffect(() => {
    dispatch(getOperacionesIniciadas());
  }, [dispatch]);
  const { cargas } = useSelector((state) => state.cargas);
  const { transportistas } = useSelector((state) => state.transportistas);
  const { choferes } = useSelector((state) => state.choferes);
  const { origenes } = useSelector((state) => state.origenes);
  const { destinos } = useSelector((state) => state.destinos);
  const { operaciones } = useSelector((state) => state.operaciones_iniciadas);

  const formik = useFormik({
    initialValues: {
      bal_salida_id: selectedPesaje ? selectedPesaje.id : null,
      tara: null,
      peso_neto: null,
      chasis_patente: "",
      acoplado_patente: "",
      transportista_id: "",
      chofer_id: "",
      carga_id: "",
      origen_id: "",
      destino_id: "",
      comentario: "",
      talonario: null,
      remito: null,
      guia_numero: null,
      dt_entrada: null,
    },

    validationSchema: validationSchema,

    onSubmit: async (values) => {
      const chasisPatente = values.chasis_patente.toUpperCase();

      const updatedValues = {
        chasis_patente: chasisPatente,
        comentario: values.comentario,
        bal_salida_id: selectedPesaje.id,
      };

      // Enviar la solicitud con los IDs actualizados
      console.log(updatedValues);
      dispatch(finalizarViajePost(updatedValues));
      onClose();
    },
  });

  const SelectInput = ({ label, name, value, options, error, disabled }) => {
    const handleSelectChange = (event) => {
      formik.handleChange(event);
      handlePatenteChange(name, event.target.value);
    };

    return (
      <FormControl fullWidth>
        <InputLabel>{label}</InputLabel>
        <MuiSelect
          name={name}
          value={value}
          onChange={handleSelectChange}
          disabled={disabled}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </MuiSelect>
        {error && (
          <FormHelperText error={error}>{formik.errors?.[name]}</FormHelperText>
        )}
      </FormControl>
    );
  };

  const handlePatenteChange = (fieldName, selectedValue) => {
    const patenteValue = selectedValue;

    if (fieldName === "chasis_patente") {
      const matchingOperacion = operaciones.find(
        (operacion) => operacion?.chasis?.patente === patenteValue
      );

      if (matchingOperacion) {
        const tara = matchingOperacion?.balanza_entrada.peso;
        const peso_neto = Math.abs(selectedPesaje.peso - tara);

        formik.setValues((prevValues) => ({
          ...prevValues,
          tara: tara,
          peso_neto: peso_neto,
          chasis_patente: patenteValue,
          acoplado_patente: matchingOperacion?.acoplado?.patente,
          transportista_id: matchingOperacion?.transportista?.nombre,
          chofer_id: matchingOperacion?.chofer?.nombre_apellido,
          carga_id: matchingOperacion?.tipo?.carga?.nombre,
          origen_id: matchingOperacion?.tipo?.origen?.nombre,
          destino_id: matchingOperacion?.tipo?.destino?.nombre,
          remito: matchingOperacion?.remito ? matchingOperacion?.remito : null,
          guia_numero: matchingOperacion?.guia?.numero
            ? matchingOperacion?.guia?.numero
            : null,
          talonario: matchingOperacion?.guia?.talonario?.numero,
        }));
      }
    }
  };

  const handleClose = () => {
    onClose();
  };

  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper onClose={() => handleClose()}>
        <Head>
          <p>Finalizar operación</p>
          <IconContainer>
            <FontAwesomeIcon icon={faTimes} onClick={onClose} />
          </IconContainer>
        </Head>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid container md={12} sm={8} justify="center">
            <Grid item sm={4}>
              <InputLabel id="peso" style={{ fontSize: "12px" }}>
                Peso (Tn)
              </InputLabel>
              <Inputs
                type="text"
                name="peso"
                disabled
                value={selectedPesaje ? selectedPesaje.peso : ""}
                error={formik.errors.peso && formik.touched.peso}
              />
              {formik.errors.peso && formik.touched.peso && (
                <FormHelperText
                  error={formik.errors.peso && formik.touched.peso}
                >
                  {formik.errors?.peso}
                </FormHelperText>
              )}
            </Grid>
            <Grid item sm={4}>
              <InputLabel id="tara" style={{ fontSize: "12px" }}>
                Tara (Tn)
              </InputLabel>
              <Inputs
                type="text"
                name="tara"
                disabled
                value={formik.values.tara}
                error={formik.errors.tara && formik.touched.tara}
              />
              {formik.errors.tara && formik.touched.tara && (
                <FormHelperText
                  error={formik.errors.tara && formik.touched.tara}
                >
                  {formik.errors?.tara}
                </FormHelperText>
              )}
            </Grid>
            <Grid item sm={4}>
              <InputLabel id="neto" style={{ fontSize: "12px" }}>
                Peso Neto (Tn)
              </InputLabel>
              <Inputs
                type="text"
                name="peso_neto"
                disabled
                value={
                  formik.values.peso_neto !== null
                    ? formik.values.peso_neto.toFixed(2)
                    : ""
                }
                error={formik.errors.peso_neto && formik.touched.peso_neto}
              />
              {formik.errors.peso_neto && formik.touched.peso_neto && (
                <FormHelperText
                  error={formik.errors.peso_neto && formik.touched.peso_neto}
                >
                  {formik.errors?.peso_neto}
                </FormHelperText>
              )}
            </Grid>
            <SelectInput
              label="Patente"
              name="chasis_patente"
              value={formik.values.chasis_patente}
              onChange={handlePatenteChange}
              error={
                formik.errors.chasis_patente && formik.touched.chasis_patente
              }
              options={operaciones.map((operacion) => ({
                value: operacion.chasis.patente,
                label: operacion.chasis.patente,
              }))}
            />
            <Inputs
              label="Acoplado"
              type="text"
              name="acoplado_patente"
              value={formik.values.acoplado_patente}
              disabled
              error={
                formik.errors.acoplado_patente &&
                formik.touched.acoplado_patente
              }
            />
            {formik.errors.acoplado_patente &&
              formik.touched.acoplado_patente && (
                <FormHelperText
                  error={
                    formik.errors.acoplado_patente &&
                    formik.touched.acoplado_patente
                  }
                >
                  {formik.errors?.acoplado_patente}
                </FormHelperText>
              )}
            <SelectInput
              label="Empresa Transportista"
              type="text"
              name="transportista_id"
              value={formik.values.transportista_id}
              disabled
              error={
                formik.errors.transportista_id &&
                formik.touched.transportista_id
              }
              options={transportistas.map((transportista) => ({
                value: transportista.nombre,
                label: transportista.nombre,
              }))}
            />
            {formik.errors.transportista_id &&
              formik.touched.transportista_id && (
                <FormHelperText
                  error={
                    formik.errors.transportista_id &&
                    formik.touched.transportista_id
                  }
                >
                  {formik.errors?.transportista_id}
                </FormHelperText>
              )}
            <SelectInput
              label="Chofer"
              type="text"
              name="chofer_id"
              value={formik.values.chofer_id}
              disabled
              error={formik.errors.chofer_id && formik.touched.chofer_id}
              options={choferes.map((chofer) => ({
                value: `${chofer.nombre} ${chofer.apellido}`,
                label: `${chofer.nombre} ${chofer.apellido}`,
              }))}
            />
            {formik.errors.chofer_id && formik.touched.chofer_id && (
              <FormHelperText
                error={formik.errors.chofer_id && formik.touched.chofer_id}
              >
                {formik.errors?.chofer_id}
              </FormHelperText>
            )}

            <SelectInput
              label="Tipo de producto"
              type="text"
              name="carga_id"
              value={formik.values.carga_id}
              disabled
              error={formik.errors.carga_id && formik.touched.carga_id}
              options={cargas.map((carga) => ({
                value: carga.nombre,
                label: carga.nombre,
              }))}
            />
            {formik.errors.carga_id && formik.touched.carga_id && (
              <FormHelperText
                error={formik.errors.carga_id && formik.touched.carga_id}
              >
                {formik.errors?.carga_id}
              </FormHelperText>
            )}

            <SelectInput
              label="Origen"
              type="text"
              name="origen_id"
              value={formik.values.origen_id}
              disabled
              error={formik.errors.origen_id && formik.touched.origen_id}
              options={origenes.map((origen) => ({
                value: origen.nombre,
                label: origen.nombre,
              }))}
            />
            {formik.errors.origen_id && formik.touched.origen_id && (
              <FormHelperText
                error={formik.errors.origen_id && formik.touched.origen_id}
              >
                {formik.errors?.origen_id}
              </FormHelperText>
            )}
            <SelectInput
              label="Destino"
              type="text"
              name="destino_id"
              value={formik.values.destino_id}
              disabled
              error={formik.errors.destino_id && formik.touched.destino_id}
              options={destinos.map((destino) => ({
                value: destino.nombre,
                label: destino.nombre,
              }))}
            />
            {formik.errors.destino_id && formik.touched.destino_id && (
              <FormHelperText
                error={formik.errors.destino_id && formik.touched.destino_id}
              >
                {formik.errors?.destino_id}
              </FormHelperText>
            )}

            <Grid item sm={3}>
              <InputLabel
                id="remito"
                style={{ marginTop: "1rem", fontSize: "13px" }}
              >
                Remito
              </InputLabel>
              <Inputs
                type="number"
                name="remito"
                value={formik.values.remito}
                disabled
              />
            </Grid>
            <Grid item sm={3}>
              <InputLabel
                id="guia_numero"
                style={{ marginTop: "1rem", fontSize: "13px" }}
              >
                Guía
              </InputLabel>
              <Inputs
                type="number"
                name="guia_numero"
                value={formik.values.guia_numero}
                disabled
              />
            </Grid>
            <Grid item sm={3}>
              <InputLabel
                id="talonario"
                style={{ marginTop: "1rem", fontSize: "13px" }}
              >
                Talonario
              </InputLabel>
              <Inputs
                type="text"
                name="talonario"
                value={formik.values.talonario}
                disabled
              />
            </Grid>

            <Grid item sm={3}>
              <InputLabel
                id="comentario"
                style={{ marginTop: "1rem", fontSize: "13px" }}
              >
                Comentario
              </InputLabel>
              <Inputs
                type="text"
                name="comentario"
                value={formik.values.comentario}
                onChange={formik.handleChange}
              />
            </Grid>

            <Grid item sm={3}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  // disabled={validated}
                  type="button"
                  textArea="Descartar Pesaje"
                  onClick={() => {
                    dispatch(descartarPesaje(selectedPesaje.id));
                    onClose();
                  }}
                  variant="outlined"
                  color="primary"
                  mright="true"
                />
              </ButtonContainer>
            </Grid>
            <Grid item sm={3}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  type="button"
                  textArea="Registrar Tara" /* Acà se puede validar el tipo de boton */
                  onClick={() => {
                    dispatch(
                      registrarTara(
                        selectedPesaje.peso,
                        formik.values.chasis_patente,
                        selectedPesaje.id
                      )
                    );
                  }}
                  variant="outlined"
                  color="primary"
                  minwidth="true"
                />
              </ButtonContainer>
            </Grid>
            <Grid item sm={3}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  type="button"
                  textArea="Cambiar a Iniciar"
                  onClick={() => setShowViajesModal(true)}
                  variant="outlined"
                  color="primary"
                  mright="true"
                />
              </ButtonContainer>
            </Grid>
            <Grid item sm={6}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  type="button"
                  textArea="Cancelar" /* Acà se puede validar el tipo de boton */
                  onClick={() => handleClose()}
                  variant="outlined"
                  color="primary"
                  minwidth="true"
                />
              </ButtonContainer>
            </Grid>
            <Grid item sm={6}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  // disabled={validated}
                  type="submit"
                  textArea="Finalizar"
                  variant="contained"
                  color="primary"
                  mright="true"
                />
              </ButtonContainer>
            </Grid>
          </Grid>
        </StyledForm>
      </ModalWrapper>
      {showViajesModal && (
        <ModalViajes onClose={onClose} selectedPesaje={selectedPesaje} />
      )}
    </Background>,
    document.getElementById("root")
  );
};
