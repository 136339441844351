import styled from "styled-components";
import {
  ListItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
} from "@material-ui/core";

export const Background = styled.div``;

export const Container = styled.div`
  display: flow-root;
  background-color: #cbc3e3;
  position: relative;
  margin: 0;
  padding: 0;
  height: calc(100vh - 60px);
  overflow: hidden;
  @media (max-width: 768px) {
    display: block;
    position: relative;
  }
  &.open768 {
    @media (max-width: 768px) {
      display: block;
      position: absolute;
      z-index: 100;
      left: 434px;
    }
  }
`;
export const Menu = styled.div`
  margin: 0;
  display: block;
  width: 100%;
  height: 40px;
  padding: 0;
  position: relative;
`;
export const Cabecera = styled.div`
  display: flex;
  width: 100%;
  border-bottom: solid 2px #4f4184;
  padding: 15px;
  position: relative;
`;
export const NameSelect = styled.span`
  font-family: "Roboto-Medium", sans-serif;
  font-size: 1.2rem;
  color: #4f4184;
  padding: 4px 0;
  line-height: 0;
`;
export const Options = styled.ul`
  display: block;
  position: absolute;
  top: 40px;
  background: #cbc3e3;
  width: 100%;
  height: calc(100% - 40px);
  list-style: none;
  padding: 0;
  margin: 0;
  left: 100%;
  z-index: 9;
  transition: left 0.2s;
  &.open {
    left: 0;
    transition: left 0.2s;
  }
`;

// export const SubItems = styled.li`
//   font-family: "Roboto-Regular", sans-serif;
//   font-size: 0.8rem;
//   color: #cbc3e3;
//   padding-left: 30px;
//   margin: 0;
//   transition: background 0.5s, color 0.5s;
//   &:hover {
//     background: #4f4184;
//     color: #ffffff;
//   }
//   & a {
//     display: flex;
//     height: 100%;
//     text-decoration: none;
//     color: #cbc3e3;
//     padding: 10px 0px;
//     transition: background 0.5s, color 0.5s;
//     cursor: pointer;
//     box-sizing: border-box;
//     &:hover {
//       background: transparent;
//       color: #ffffff;
//     }
//   }
// `;

export const Hr = styled.hr`
  border-bottom: solid 1px #4f4184;
  width: calc(100% - 30px);
  margin: 0 auto;
`;
export const ContentSelect = styled.div`
  display: block;
  width: 100%;
  height: auto;
  overflow: hidden;
`;

export const Title = styled.li`
  font-family: "Roboto-Regular", sans-serif;
  font-size: 0.8rem;
  color: #4f4184;
  padding: 10px 30px;
  margin: 0;
  cursor: pointer;
  transition: background 0.5s, color 0.5s;
  // &:hover {
  //   background: #4f4184;
  //   color: #ffffff;
  // }
  // & a {
  //   display: flex;
  //   height: 100%;
  //   text-decoration: none;
  //   color: #cbc3e3;
  //   padding: 10px 0px;
  //   transition: background 0.5s, color 0.5s;
  //   box-sizing: border-box;
  //   &:hover {
  //     background: red;
  //     color: #ffffff;
  //   }
  // }
`;

export const CategoryTitle = styled.p`
  font-family: "Roboto-Medium", sans-serif;
  font-size: 0.8rem;
  color: #4f4184;
  padding: 10px 15px;
  margin: 0;
  cursor: pointer;
  transition: background 0.5s, color 0.5s;
  // &:hover {
  //   background: #4f4184;
  //   color: #ffffff;
  //   transition: background 0.5s, color 0.5s;
  // }
  // & a {
  //   display: flex;
  //   height: 100%;
  //   text-decoration: none;
  //   color: #4f4184;
  //   transition: background 0.5s, color 0.5s;
  //   box-sizing: border-box;
  //   &:hover {
  //     color: #ffffff;
  //   }
  // }
`;
export const ListContainer = styled(List)`
  background: #cbc3e3; /* Agrega el color de fondo deseado */
  color: #4f4184;
`;

export const AccordionContainer = styled(Accordion)`
  background: #cbc3e3 !important; /* Agrega el color de fondo deseado */
  color: #4f4184;
  & .MuiAccordionSummary-content {
    margin: 0;
  }
`;
export const AccordionSummaryContainer = styled(AccordionSummary)`
  background: #cbc3e3; /* Agrega el color de fondo deseado */
  color: #4f4184;
  & .MuiAccordionSummary-content {
    margin: 0;
  }
`;
export const AccordionDetailsContainer = styled(AccordionDetails)`
  background: #cbc3e3; /* Agrega el color de fondo deseado */
  color: #4f4184;
  & .MuiAccordionSummary-content {
    margin: 0;
  }
`;
export const ListItemAnchor = styled(ListItem)`
  cursor: pointer;
  width: 100%;
  // &:hover {
  //   background: #c1c5c8;
  // }
  & a {
    color: #ffffff;
    font-size: 1rem;
    padding-left: 2rem;
  }
  & a:hover {
    text-decoration: none;
  }
  @media (max-width: 768px) {
    & a {
      color: #ffffff;
      font-size: 0.9rem;
      padding-left: 0.5rem;
    }
    & a:hover {
      text-decoration: none;
    }
  }
`;
