import React from "react";
import ReactDOM from "react-dom";
import { Grid } from "@material-ui/core";
import { FormControl, FormHelperText } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {
  Background,
  ModalWrapper,
  Head,
  IconContainer,
  ButtonContainer,
  StyledForm,
} from "./Styles";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

/* Componentes */
import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";

/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Inputs } from "../../Input/Inputs";
import { createChofer } from "../../../Services";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  nombre: yup.string().required("Campo requerido"),
  apellido: yup.string().required("Campo requerido"),
  dni: yup.number().required("Campo requerido"),
  transportista_id: yup.string().required("Campo requerido"),
});

export const ModalChofer = ({ onClose }) => {
  const dispatch = useDispatch();
  const { transportistas } = useSelector((state) => state.transportistas);

  const formik = useFormik({
    initialValues: {
      nombre: "",
      apellido: "",
      dni: null,
      transportista_id: "",
    },

    validationSchema: validationSchema,

    onSubmit: async (values) => {
      // Buscar IDs correspondientes
      const transportistaId = transportistas.find(
        (transportista) => transportista.nombre === values.transportista_id
      )?.id;
      const nombreEnMayus = values.nombre.toUpperCase();
      const apellidoEnMayus = values.apellido.toUpperCase();

      // Crear un nuevo objeto con los IDs encontrados
      const updatedValues = {
        ...values,
        transportista_id: transportistaId,
        nombre: nombreEnMayus,
        apellido: apellidoEnMayus,
      };

      // Enviar la solicitud con los IDs actualizados
      dispatch(createChofer(updatedValues));
      onClose();
    },
  });

  const SelectInput = ({
    label,
    name,
    value,
    onChange,
    options,
    error,
    disabled,
  }) => {
    const handleSelectChange = (event, selectedOption) => {
      onChange({
        target: {
          name,
          value: selectedOption ? selectedOption.value : "",
        },
      });
    };

    return (
      <FormControl fullWidth>
        <Autocomplete
          options={options}
          getOptionLabel={(option) => option.label}
          value={options.find((option) => option.value === value) || null}
          onChange={handleSelectChange}
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={error}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  };
  const handleClose = () => {
    onClose();
  };
  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper onClose={() => handleClose()}>
        <Head>
          <p>Dar de alta a un Chofer</p>
          <IconContainer>
            <FontAwesomeIcon icon={faTimes} onClick={onClose} />
          </IconContainer>
        </Head>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid container md={12} sm={8} justify="center">
            <Inputs
              label="Nombre"
              name="nombre"
              value={formik.values.nombre}
              onChange={formik.handleChange}
              error={formik.errors.nombre && formik.touched.nombre}
            />
            {formik.errors.nombre && formik.touched.nombre && (
              <FormHelperText
                error={formik.errors.nombre && formik.touched.nombre}
              >
                {formik.errors?.nombre}
              </FormHelperText>
            )}
            <Inputs
              label="Apellido"
              name="apellido"
              value={formik.values.apellido}
              onChange={formik.handleChange}
              error={formik.errors.apellido && formik.touched.apellido}
            />
            {formik.errors.apellido && formik.touched.apellido && (
              <FormHelperText
                error={formik.errors.apellido && formik.touched.apellido}
              >
                {formik.errors?.apellido}
              </FormHelperText>
            )}
            <Inputs
              label="DNI"
              name="dni"
              type={"number"}
              value={formik.values.dni}
              onChange={formik.handleChange}
              error={formik.errors.dni && formik.touched.dni}
            />
            {formik.errors.dni && formik.touched.dni && (
              <FormHelperText error={formik.errors.dni && formik.touched.dni}>
                {formik.errors?.dni}
              </FormHelperText>
            )}

            <SelectInput
              label="Empresa Transportista"
              name="transportista_id"
              value={formik.values.transportista_id}
              onChange={formik.handleChange}
              error={
                formik.errors.transportista_id &&
                formik.touched.transportista_id
              }
              options={transportistas.map((transportista) => ({
                value: transportista.nombre,
                label: transportista.nombre,
              }))}
            />
            {formik.errors.transportista_id &&
              formik.touched.transportista_id && (
                <FormHelperText
                  error={
                    formik.errors.transportista_id &&
                    formik.touched.transportista_id
                  }
                >
                  {formik.errors?.transportista_id}
                </FormHelperText>
              )}
            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  type="button"
                  textArea="Cancelar" /* Acà se puede validar el tipo de boton */
                  onClick={() => handleClose()}
                  variant="outlined"
                  color="primary"
                  minwidth="true"
                />
              </ButtonContainer>
            </Grid>
            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  // disabled={validated}
                  type="submit"
                  textArea="Crear"
                  variant="contained"
                  color="primary"
                  mright="true"
                />
              </ButtonContainer>
            </Grid>
          </Grid>
        </StyledForm>
      </ModalWrapper>
    </Background>,
    document.getElementById("root")
  );
};
