import React from "react";
import ReactDOM from "react-dom";
import { Grid, TextField, FormHelperText } from "@material-ui/core";
import {
  Background,
  ModalWrapper,
  Head,
  IconContainer,
  ButtonContainer,
  StyledForm,
} from "./Styles";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

/* Componentes */
import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";

/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import { reimprimirOperacion } from "../../../Services";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  ticket: yup.number().required("Campo requerido"),
  // guia_numero: yup.number().required("Campo requerido"),
  chasis_patente: yup.string().required("Campo requerido"),
  acoplado_patente: yup.string().required("Campo requerido"),
  // peso: yup.number().required("Campo requerido"),
  transportista: yup.string().required("Campo requerido"),
  chofer: yup.string().required("Campo requerido"),
  carga: yup.string().required("Campo requerido"),
  origen: yup.string().required("Campo requerido"),
  destino: yup.string().required("Campo requerido"),
  // comentario: yup.string(),
  // remito: yup.number(),
});

export const ModalReimprimirPopup = ({ onClose }) => {
  const dispatch = useDispatch();
  const operacionId = useSelector((state) => state.operacionId);
  const formik = useFormik({
    initialValues: {
      ticket: operacionId?.ticket,
      guia_numero: operacionId?.guia?.numero,
      chasis_patente: operacionId?.chasis?.patente,
      acoplado_patente: operacionId?.acoplado?.patente,
      peso_entrada: operacionId?.balanza_entrada?.peso,
      peso_salida: operacionId?.balanza_salida,
      peso: operacionId?.balanza_salida
        ? Math.abs(
            operacionId.balanza_salida - operacionId.balanza_entrada.peso
          )
        : null,
      transportista: operacionId?.transportista?.nombre,
      chofer: operacionId.chofer?.nombre_apellido,
      carga: operacionId?.tipo?.carga?.nombre,
      origen: operacionId?.tipo?.origen?.nombre,
      destino: operacionId?.tipo?.destino?.nombre,
      comentario: operacionId?.comentario,
      remito: operacionId?.remito,
      talonario: operacionId?.guia?.talonario?.numero,
    },
    validationSchema: validationSchema,
    enableReinitialize: true, // Enable reinitialization when initialValues change
    onSubmit: (values) => {
      const data = {
        chasis_patente: values.chasis_patente,
        comentario: values.comentario,
        bal_salida_id: operacionId?.balanza_salida?.id,
        volumen: operacionId?.volumen,
      };
      dispatch(reimprimirOperacion(data));
      onClose();
    },
  });

  const handleClose = () => {
    onClose();
  };
  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper onClose={() => handleClose()}>
        <Head>
          <p>Reimprimir viaje</p>
          <IconContainer>
            <FontAwesomeIcon icon={faTimes} onClick={onClose} />
          </IconContainer>
        </Head>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid container md={12} sm={8} justify="center">
            <TextField
              label="ticket"
              type="number"
              name="ticket"
              onChange={formik.handleChange}
              disabled
              value={formik.values.ticket}
              error={formik.errors.ticket && formik.touched.ticket}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {formik.errors.ticket && formik.touched.ticket && (
              <FormHelperText
                error={formik.errors.ticket && formik.touched.ticket}
              >
                {formik.errors?.ticket}
              </FormHelperText>
            )}
            <TextField
              label="Patente"
              type="text"
              name="chasis_patente"
              value={formik.values.chasis_patente}
              disabled
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              error={
                formik.errors.chasis_patente && formik.touched.chasis_patente
              }
            />

            {formik.errors.chasis_patente && formik.touched.chasis_patente && (
              <FormHelperText
                error={
                  formik.errors.chasis_patente && formik.touched.chasis_patente
                }
              >
                {formik.errors?.chasis_patente}
              </FormHelperText>
            )}
            <TextField
              label="Acoplado"
              type="text"
              name="acoplado_patente"
              value={formik.values.acoplado_patente}
              onChange={formik.handleChange}
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              error={
                formik.errors.acoplado_patente &&
                formik.touched.acoplado_patente
              }
            />
            {formik.errors.acoplado_patente &&
              formik.touched.acoplado_patente && (
                <FormHelperText
                  error={
                    formik.errors.acoplado_patente &&
                    formik.touched.acoplado_patente
                  }
                >
                  {formik.errors?.acoplado_patente}
                </FormHelperText>
              )}
            <TextField
              label="Peso entrada(Tn.)"
              type="number"
              name="peso_entrada"
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              onChange={formik.handleChange}
              value={formik.values.peso_entrada}
              error={formik.errors.peso_entrada && formik.touched.peso_entrada}
            />
            {formik.errors.peso_entrada && formik.touched.peso_entrada && (
              <FormHelperText
                error={
                  formik.errors.peso_entrada && formik.touched.peso_entrada
                }
              >
                {formik.errors?.peso_entrada}
              </FormHelperText>
            )}
            <TextField
              label="Peso salida(Tn.)"
              type="number"
              name="peso_salida"
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              onChange={formik.handleChange}
              value={formik.values.peso_salida}
              error={formik.errors.peso_salida && formik.touched.peso_salida}
            />
            {formik.errors.peso_salida && formik.touched.peso_salida && (
              <FormHelperText
                error={formik.errors.peso_salida && formik.touched.peso_salida}
              >
                {formik.errors?.peso_salida}
              </FormHelperText>
            )}
            <TextField
              label="Peso Neto (Tn.)"
              type="number"
              name="peso"
              value={formik.values.peso}
              onChange={formik.handleChange}
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              error={formik.errors.peso && formik.touched.peso}
            />
            {formik.errors.peso && formik.touched.peso && (
              <FormHelperText error={formik.errors.peso && formik.touched.peso}>
                {formik.errors?.peso}
              </FormHelperText>
            )}

            <TextField
              label="Transportista"
              type="text"
              name="transportista"
              value={formik.values.transportista}
              onChange={formik.handleChange}
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              error={
                formik.errors.transportista && formik.touched.transportista
              }
            />
            {formik.errors.transportista && formik.touched.transportista && (
              <FormHelperText
                error={
                  formik.errors.transportista && formik.touched.transportista
                }
              >
                {formik.errors?.transportista}
              </FormHelperText>
            )}
            <TextField
              label="Chofer"
              type="text"
              name="chofer"
              value={formik.values.chofer}
              onChange={formik.handleChange}
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              error={formik.errors.chofer && formik.touched.chofer}
            />
            {formik.errors.chofer && formik.touched.chofer && (
              <FormHelperText
                error={formik.errors.chofer && formik.touched.chofer}
              >
                {formik.errors?.chofer}
              </FormHelperText>
            )}
            <TextField
              label="Carga"
              type="text"
              name="carga"
              value={formik.values.carga}
              onChange={formik.handleChange}
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              error={formik.errors.carga && formik.touched.carga}
            />
            {formik.errors.carga && formik.touched.carga && (
              <FormHelperText
                error={formik.errors.carga && formik.touched.carga}
              >
                {formik.errors?.carga}
              </FormHelperText>
            )}
            <TextField
              label="Origen"
              type="text"
              name="origen"
              value={formik.values.origen}
              onChange={formik.handleChange}
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              error={formik.errors.origen && formik.touched.origen}
            />
            {formik.errors.origen && formik.touched.origen && (
              <FormHelperText
                error={formik.errors.origen && formik.touched.origen}
              >
                {formik.errors?.origen}
              </FormHelperText>
            )}
            <TextField
              label="Destino"
              type="text"
              name="destino"
              value={formik.values.destino}
              onChange={formik.handleChange}
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              error={formik.errors.destino && formik.touched.destino}
            />
            {formik.errors.destino && formik.touched.destino && (
              <FormHelperText
                error={formik.errors.destino && formik.touched.destino}
              >
                {formik.errors?.destino}
              </FormHelperText>
            )}
            <TextField
              label="Comentario"
              type="text"
              name="comentario"
              value={formik.values.comentario}
              onChange={formik.handleChange}
              disabled
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label="Remito"
              type="number"
              name="remito"
              value={formik.values.remito}
              onChange={formik.handleChange}
              disabled
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label="Guia"
              type="number"
              name="guia_numero"
              disabled
              value={formik.values.guia_numero}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label="Talonario"
              type="number"
              name="talonario"
              disabled
              value={formik.values.talonario}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />

            <Grid container md={12} sm={8} justify="center">
              <Grid item sm={4}>
                <ButtonContainer align="center">
                  <ButtonPrimary
                    type="button"
                    textArea="Cancelar" /* Acà se puede validar el tipo de boton */
                    onClick={() => handleClose()}
                    variant="outlined"
                    color="primary"
                    minwidth="true"
                  />
                </ButtonContainer>
              </Grid>
              <Grid item sm={4}>
                <ButtonContainer align="center">
                  <ButtonPrimary
                    // disabled={validated}
                    type="submit"
                    textArea="Reimprimir"
                    variant="contained"
                    color="primary"
                    mright="true"
                  />
                </ButtonContainer>
              </Grid>
            </Grid>
          </Grid>
        </StyledForm>
      </ModalWrapper>
    </Background>,
    document.getElementById("root")
  );
};
