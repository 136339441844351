import React from "react";
import ReactDOM from "react-dom";
import { Grid } from "@material-ui/core";
import {
  FormControl,
  InputLabel,
  FormHelperText,
  MenuItem,
  Select as MuiSelect,
} from "@material-ui/core";
import {
  Background,
  ModalWrapper,
  Head,
  IconContainer,
  ButtonContainer,
  StyledForm,
} from "./Styles";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

/* Componentes */
import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";

/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { Inputs } from "../../Input/Inputs";
import { createLugar } from "../../../Services";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  nombre: yup.string().required("Campo requerido"),
  domicilio: yup.string().required("Campo requerido"),
  localidad: yup.string().required("Campo requerido"),
  provincia: yup.string().required("Campo requerido"),
  acopio: yup.boolean().required("Campo requerido"),
  cuit: yup
    .number()
    .required("Campo requerido")
    .test(
      "len",
      "El CUIT debe tener exactamente 11 caracteres",
      (val) => val && val.toString().length === 11
    ),
  nombre_cliente: yup.string().required("Campo requerido"),
});

export const ModalLugar = ({ onClose }) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      nombre: "",
      domicilio: "",
      localidad: "",
      provincia: "",
      acopio: "",
      cuit: "",
      nombre_cliente: "",
    },

    validationSchema: validationSchema,

    onSubmit: (values) => {
      dispatch(
        createLugar({
          nombre: values.nombre.toUpperCase(),
          domicilio: values.domicilio.toUpperCase(),
          localidad: values.localidad.toUpperCase(),
          provincia: values.provincia.toUpperCase(),
          acopio: values.acopio,
          cuit: values.cuit,
          nombre_cliente: values.nombre_cliente.toUpperCase(),
        })
      );
      onClose();
    },
  });
  const handleClose = () => {
    onClose();
  };

  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper onClose={() => handleClose()}>
        <Head>
          <p>Dar de alta a un Lugar</p>
          <IconContainer>
            <FontAwesomeIcon icon={faTimes} onClick={onClose} />
          </IconContainer>
        </Head>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid container md={12} sm={8} justify="center">
            <Inputs
              label="Nombre"
              name="nombre"
              value={formik.values.nombre}
              onChange={formik.handleChange}
              error={formik.errors.nombre && formik.touched.nombre}
            />
            {formik.errors.nombre && formik.touched.nombre && (
              <FormHelperText
                error={formik.errors.nombre && formik.touched.nombre}
              >
                {formik.errors?.nombre}
              </FormHelperText>
            )}
            <Inputs
              label="Domicilio"
              name="domicilio"
              value={formik.values.domicilio}
              onChange={formik.handleChange}
              error={formik.errors.domicilio && formik.touched.domicilio}
            />
            {formik.errors.domicilio && formik.touched.domicilio && (
              <FormHelperText
                error={formik.errors.domicilio && formik.touched.domicilio}
              >
                {formik.errors?.domicilio}
              </FormHelperText>
            )}
            <Inputs
              label="Localidad"
              name="localidad"
              value={formik.values.localidad}
              onChange={formik.handleChange}
              error={formik.errors.localidad && formik.touched.localidad}
            />
            {formik.errors.localidad && formik.touched.localidad && (
              <FormHelperText
                error={formik.errors.localidad && formik.touched.localidad}
              >
                {formik.errors?.localidad}
              </FormHelperText>
            )}
            <Inputs
              label="Provincia"
              name="provincia"
              value={formik.values.provincia}
              onChange={formik.handleChange}
              error={formik.errors.provincia && formik.touched.provincia}
            />
            {formik.errors.provincia && formik.touched.provincia && (
              <FormHelperText
                error={formik.errors.provincia && formik.touched.provincia}
              >
                {formik.errors?.provincia}
              </FormHelperText>
            )}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="acopio">¿Es planta de acopio?</InputLabel>
                <MuiSelect
                  labelId="acopio"
                  name="acopio"
                  value={formik.values.acopio}
                  onChange={formik.handleChange}
                  error={formik.errors.acopio && formik.touched.acopio}
                >
                  <MenuItem value={true}>Si</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </MuiSelect>
                {formik.errors.acopio && formik.touched.acopio && (
                  <FormHelperText
                    error={formik.errors.acopio && formik.touched.acopio}
                  >
                    {formik.errors?.acopio}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Inputs
              label="CUIT (sin guiones)"
              name="cuit"
              value={formik.values.cuit}
              onChange={formik.handleChange}
              error={formik.errors.cuit && formik.touched.cuit}
            />
            {formik.errors.cuit && formik.touched.cuit && (
              <FormHelperText error={formik.errors.cuit && formik.touched.cuit}>
                {formik.errors?.cuit}
              </FormHelperText>
            )}
            <Inputs
              label="Nombre de cliente"
              name="nombre_cliente"
              value={formik.values.nombre_cliente}
              onChange={formik.handleChange}
              error={
                formik.errors.nombre_cliente && formik.touched.nombre_cliente
              }
            />
            {formik.errors.nombre_cliente && formik.touched.nombre_cliente && (
              <FormHelperText
                error={
                  formik.errors.nombre_cliente && formik.touched.nombre_cliente
                }
              >
                {formik.errors?.nombre_cliente}
              </FormHelperText>
            )}

            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  type="button"
                  textArea="Cancelar" /* Acà se puede validar el tipo de boton */
                  onClick={() => handleClose()}
                  variant="outlined"
                  color="primary"
                  minwidth="true"
                />
              </ButtonContainer>
            </Grid>
            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  // disabled={validated}
                  type="submit"
                  textArea="Crear"
                  variant="contained"
                  color="primary"
                  mright="true"
                />
              </ButtonContainer>
            </Grid>
          </Grid>
        </StyledForm>
      </ModalWrapper>
    </Background>,
    document.getElementById("root")
  );
};
