import {
  GET_OPERACIONID_CLS,
  RESET_OPERACIONID_CLS,
} from "../../constants/actionsTypes";

export const getOperacionIdCls = (payload) => {
  return {
    type: GET_OPERACIONID_CLS,
    payload,
  };
};

export const resetOperacionIdCls = () => ({
  type: RESET_OPERACIONID_CLS,
});
