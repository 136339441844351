import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Grid } from "@material-ui/core";
import { FormControl, FormHelperText } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {
  Background,
  ModalWrapper,
  Head,
  IconContainer,
  ButtonContainer,
  StyledForm,
} from "./Styles";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

/* Componentes */
import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";

/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Inputs } from "../../Input/Inputs";
import { editarTransportista, getTransportistas } from "../../../Services";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  nombre: yup.string().required("Campo requerido"),
  id: yup.number().required("Campo requerido"),
  cuit: yup.string().required("Campo requerido"),
  domicilio: yup.string().required("Campo requerido"),
  localidad: yup.string().required("Campo requerido"),
  provincia: yup.string().required("Campo requerido"),
});

export const ModalEditarTransportista = ({ onClose }) => {
  const [selectedTransportista, setSelectedTransportista] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTransportistas());
  }, [dispatch]);
  const { transportistas } = useSelector((state) => state.transportistas);
  const formik = useFormik({
    initialValues: {
      nombre: "",
      id: "",
      domicilio: "",
      localidad: "",
      provincia: "",
      cuit: "",
    },

    validationSchema: validationSchema,

    onSubmit: (values) => {
      dispatch(editarTransportista(values));
      onClose();
    },
  });
  const handleClose = () => {
    onClose();
  };
  const handleSelectChange = (event, selectedOption) => {
    const selectedTransportistaInfo = transportistas.find(
      (transportista) => transportista.id === selectedOption.value
    );
    setSelectedTransportista(selectedTransportistaInfo);
    formik.setFieldValue("id", selectedOption.value);
    formik.setFieldValue("nombre", selectedTransportistaInfo.nombre);
    formik.setFieldValue("domicilio", selectedTransportistaInfo.domicilio);
    formik.setFieldValue("localidad", selectedTransportistaInfo.localidad);
    formik.setFieldValue("provincia", selectedTransportistaInfo.provincia);
    formik.setFieldValue("cuit", selectedTransportistaInfo.cuit);
  };
  const SelectInput = ({
    label,
    name,
    value,
    onChange,
    options,
    error,
    disabled,
  }) => {
    return (
      <FormControl fullWidth>
        <Autocomplete
          options={options}
          getOptionLabel={(option) => option.label}
          value={options.find((option) => option.value === value) || null}
          onChange={(event, selectedOption) =>
            handleSelectChange(event, selectedOption)
          }
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={error}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  };

  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper onClose={() => handleClose()}>
        <Head>
          <p>Editar una empresa transportista</p>
          <IconContainer>
            <FontAwesomeIcon icon={faTimes} onClick={onClose} />
          </IconContainer>
        </Head>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid container md={12} sm={8} justify="center">
            <SelectInput
              label="Empresa Transportista"
              name="id"
              type="number"
              value={formik.values.id}
              onChange={formik.handleChange}
              error={formik.errors.nombre && formik.touched.nombre}
              options={transportistas.map((carga) => ({
                value: carga.id,
                label: carga.nombre,
              }))}
            />

            {formik.errors.nombre && formik.touched.nombre && (
              <FormHelperText error={formik.errors.nombre}>
                {formik.errors?.nombre}
              </FormHelperText>
            )}
            <Inputs
              label="Nombre"
              name="nombre"
              value={formik.values.nombre}
              onChange={formik.handleChange}
              error={formik.errors.nombre && formik.touched.nombre}
            />
            {formik.errors.nombre && formik.touched.nombre && (
              <FormHelperText error={formik.errors.nombre}>
                {formik.errors?.nombre}
              </FormHelperText>
            )}

            <Inputs
              label="Domicilio"
              name="domicilio"
              value={formik.values.domicilio}
              onChange={formik.handleChange}
              error={formik.errors.domicilio && formik.touched.domicilio}
            />
            {formik.errors.domicilio && formik.touched.domicilio && (
              <FormHelperText
                error={formik.errors.domicilio && formik.touched.domicilio}
              >
                {formik.errors?.domicilio}
              </FormHelperText>
            )}
            <Inputs
              label="Localidad"
              name="localidad"
              value={formik.values.localidad}
              onChange={formik.handleChange}
              error={formik.errors.localidad && formik.touched.localidad}
            />
            {formik.errors.localidad && formik.touched.localidad && (
              <FormHelperText
                error={formik.errors.localidad && formik.touched.localidad}
              >
                {formik.errors?.localidad}
              </FormHelperText>
            )}
            <Inputs
              label="Provincia"
              name="provincia"
              value={formik.values.provincia}
              onChange={formik.handleChange}
              error={formik.errors.provincia && formik.touched.provincia}
            />
            {formik.errors.provincia && formik.touched.provincia && (
              <FormHelperText
                error={formik.errors.provincia && formik.touched.provincia}
              >
                {formik.errors?.provincia}
              </FormHelperText>
            )}
            <Inputs
              label="CUIT (sin guiones)"
              name="cuit"
              value={formik.values.cuit}
              onChange={formik.handleChange}
              error={formik.errors.cuit && formik.touched.cuit}
            />
            {formik.errors.cuit && formik.touched.cuit && (
              <FormHelperText error={formik.errors.cuit && formik.touched.cuit}>
                {formik.errors?.cuit}
              </FormHelperText>
            )}

            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  type="button"
                  textArea="Cancelar" /* Acà se puede validar el tipo de boton */
                  onClick={() => handleClose()}
                  variant="outlined"
                  color="primary"
                  minwidth="true"
                />
              </ButtonContainer>
            </Grid>
            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  // disabled={validated}
                  type="submit"
                  textArea="Editar"
                  variant="contained"
                  color="primary"
                  mright="true"
                />
              </ButtonContainer>
            </Grid>
          </Grid>
        </StyledForm>
      </ModalWrapper>
    </Background>,
    document.getElementById("root")
  );
};
