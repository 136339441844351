import {
  GET_ULTIMASOPERACIONES_CLS,
  GET_OPERACIONES_TODAS,
} from "../../constants/actionsTypes";

const initialState = {};

export const ultimasOperacionesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ULTIMASOPERACIONES_CLS: {
      return { ...state, ...payload };
    }

    default:
      return state;
  }
};

export const operacionesTodasReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_OPERACIONES_TODAS: {
      return { ...state, ...payload };
    }

    default:
      return state;
  }
};
