import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

import Header from "../Components/Header/Header";
import HeaderPad from "../Components/Balanza/HeaderPad/HeaderPad";
import { BalanzaComponent } from "../Components/Balanza/BalanzaComponent/BalanzaComponent";
import ModalApproveBalanza from "../utils/ModalApproveBalanza/ModalApproveBalanza";
import FullPageLoader from "../utils/FullPageLoader/FullPageLoader";
import { Sidebar } from "../Components/Sidebar/Sidebar";
import { ModalViajes } from "../Components/Balanza/ModalViajes/ModalViajes";
import { ModalViajesIniciados } from "../Components/Balanza/ModalViajesIniciados/ModalViajesIniciados";
import { ModalChofer } from "../Components/Balanza/ModalChofer/ModalChofer";
import { ModalTransportista } from "../Components/Balanza/ModalTransportista/ModalTransportista";
import { ModalLugar } from "../Components/Balanza/ModalLugar/ModalLugar";
import { ModalPatenteChasis } from "../Components/Balanza/ModalPatenteChasis/ModalPatenteChasis";
import { ModalPatenteAcoplado } from "../Components/Balanza/ModalPatenteAcoplado/ModalPatenteAcoplado";
import { ModalCarga } from "../Components/Balanza/ModalCarga/ModalCarga";
import { ModalAnularOperacion } from "../Components/Balanza/ModalAnularOperacion/ModalAnularOperacion";
import { ModalOperacionId } from "../Components/Balanza/ModalOperacionId/ModalOperacionId";
import { ModalEditarCarga } from "../Components/Balanza/ModalEditarCarga/ModalEditarCarga";
import { ModalEditarChofer } from "../Components/Balanza/ModalEditarChofer/ModalEditarChofer";
import { ModalEditarTransportista } from "../Components/Balanza/ModalEditarTransportista/ModalEditarTransportista";
import { ModalEditarLugar } from "../Components/Balanza/ModalEditarLugar/ModalEditarLugar";
import { ModalEditarPatenteChasis } from "../Components/Balanza/ModalEditarPatenteChasis/ModalEditarPatenteChasis";
import { ModalEditarPatenteAcoplado } from "../Components/Balanza/ModalEditarPatenteAcoplado/ModalEditarPatenteAcoplado";
import { ModalIniciarOperacion } from "../Components/Balanza/ModalIniciarOperacion/ModalIniciarOperacion";
import { ModalCrearPesaje } from "../Components/Balanza/ModalCrearPesaje/ModalCrearPesaje";
import { ModalEditarTalonario } from "../Components/Balanza/ModalEditarTalonario/ModalEditarTalonario";
import { ModalFinalizarOperacion } from "../Components/Balanza/ModalFinalizarOperacion/ModalFinalizarOperacion";
import { ModalTalonario } from "../Components/Balanza/ModalTalonario/ModalTalonario";
import { ModalParteCompleto } from "../Components/Balanza/ModalParteCompleto/ModalParteCompleto";
import { ModalParteTransportistas } from "../Components/Balanza/ModalParteTransportistas/ModalParteTransportistas";
import { OptionsRight } from "../Components/Balanza/OptionsRight/OptionsRight";
import { ModalCerrarTalonario } from "../Components/Balanza/ModalCerrarTalonario/ModalCerrarTalonario";
import { OperacionesAbiertas } from "../Components/Balanza/OperacionesAbiertas/OperacionesAbiertas";
import { OperacionesCerradas } from "../Components/Balanza/OperacionesCerradas/OperacionesCerradas";
import { ModalCrearParteDiario } from "../Components/Balanza/ModalCrearParteDiario/ModalCrearParteDiario";
import { ModalEditarParteDiario } from "../Components/Balanza/ModalEditarParteDiario/ModalEditarParteDiario";
import { ModalEnviarParteDiario } from "../Components/Balanza/ModalEnviarParteDiario/ModalEnviarParteDiario";
import { ModalVerPartesDiarios } from "../Components/Balanza/ModalVerPartesDiarios/ModalVerPartesDiarios";
import { ModalParteQuincenal } from "../Components/Balanza/ModalParteQuincenal/ModalParteQuincenal";
import { ModalParteMineria } from "../Components/Balanza/ModalParteMineria/ModalParteMineria";
import { ModalReimprimir } from "../Components/Balanza/ModalReimprimir/ModalReimprimir";
import { ModalRestartTurn } from "../Components/Balanza/ModalRestartTurn/ModalRestartTurn";
import { ModalCargarOperacion } from "../Components/Balanza/ModalCargarOperacion/ModalCargarOperacion";
import { Head } from "../Components/Balanza/HeaderPad/Styles";
import { ModalReimprimirPopup } from "../Components/Balanza/ModalReimprimirPopup/ModalReimprimirPopup";
import { restartTurn } from "../Services";
import {
  getBalanza,
  getCargas,
  getChoferes,
  getDestinos,
  getOrigenes,
  getTransportistas,
  getOperacionesIniciadas,
  getOperacionesIniciadasManual,
  getUltimasOperaciones,
  getTalonarioTipos,
  getTalonarioTiposAsociables,
  getAcoplados,
  getChasis,
  getOperacionesAbiertas,
  getOperacionesCerradas,
  getOperacionesTodas,
  getOperacionesOffline,
  getOperacionesFallidas,
  getParteDiarioComentarios,
  getDestinatariosMails,
} from "../Services";

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  @media (max-width: 768px) {
    overflow: auto;
    display: block;
  }
`;
const Home = styled(Grid)`
  position: relative;
`;

export const Balanza = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loading);

  const [showModalViajes, setShowModalViajes] = useState(false);
  const [showModalViajesIniciados, setShowModalViajesIniciados] =
    useState(false);
  const [showModalReimprimir, setShowModalReimprimir] = useState(false);
  const [showModalChofer, setShowModalChofer] = useState(false);
  const [showModalTransportista, setShowModalTransportista] = useState(false);
  const [showModalLugar, setShowModalLugar] = useState(false);
  const [showModalPatenteChasis, setShowModalPatenteChasis] = useState(false);
  const [showModalPatenteAcoplado, setShowModalPatenteAcoplado] =
    useState(false);
  const [showModalCarga, setShowModalCarga] = useState(false);
  const [showModalTalonario, setShowModalTalonario] = useState(false);
  const [showModalAnularOperacion, setShowModalAnularOperacion] =
    useState(false);
  const [showModalOperacionId, setShowModalOperacionId] = useState(false);
  const [showModalEditarCarga, setShowModalEditarCarga] = useState(false);
  const [showModalEditarChofer, setShowModalEditarChofer] = useState(false);
  const [showModalEditarTransportista, setShowModalEditarTransportista] =
    useState(false);
  const [showModalEditarLugar, setShowModalEditarLugar] = useState(false);
  const [showModalEditarPatenteChasis, setShowModalEditarPatenteChasis] =
    useState(false);
  const [showModalEditarPatenteAcoplado, setShowModalEditarPatenteAcoplado] =
    useState(false);
  const [showModalCrearPesaje, setShowModalCrearPesaje] = useState(false);
  const [showModalIniciarOperacion, setShowModalIniciarOperacion] =
    useState(false);
  const [showModalFinalizarOperacion, setShowModalFinalizarOperacion] =
    useState(false);
  const [showModalEditarTalonario, setShowModalEditarTalonario] =
    useState(false);
  const [showModalCerrarTalonario, setShowCerrarTalonario] = useState(false);
  const [selectedPesaje, setSelectedPesaje] = useState(null);
  const [showModalParteQuincenal, setShowModalParteQuincenal] = useState(false);
  const [showModalParteMineria, setShowModalParteMineria] = useState(false);
  const [showModalParteCompleto, setShowModalParteCompleto] = useState(false);
  const [showModalParteTransportistas, setShowModalParteTransportistas] =
    useState(false);
  const [showModalCrearParteDiario, setShowModalCrearParteDiario] =
    useState(false);
  const [showModalEditarParteDiario, setShowModalEditarParteDiario] =
    useState(false);
  const [showModalEnviarParteDiario, setShowModalEnviarParteDiario] =
    useState(false);
  const [showModalVerPartesDiarios, setShowModalVerPartesDiarios] =
    useState(false);
  const [showModalCargarOperacion, setShowModalCargarOperacion] =
    useState(false);

  const [showModalReimprimirPopup, setShowModalReimprimirPopup] =
    useState(false);

  const openModalToggleViajes = () => {
    setShowModalViajes(!showModalViajes);
  };
  const openModalToggleViajesIniciados = () => {
    setShowModalViajesIniciados(!showModalViajesIniciados);
  };
  const openModalToggleParteQuincenal = () => {
    setShowModalParteQuincenal(!showModalParteQuincenal);
  };
  const openModalToggleParteMineria = () => {
    setShowModalParteMineria(!showModalParteMineria);
  };

  useEffect(() => {
    dispatch(getBalanza());
    dispatch(getCargas());
    dispatch(getTransportistas());
    dispatch(getChoferes());
    dispatch(getOrigenes());
    dispatch(getDestinos());
    dispatch(getOperacionesIniciadas());
    dispatch(getOperacionesIniciadasManual());
    dispatch(getUltimasOperaciones());
    dispatch(getTalonarioTipos());
    dispatch(getTalonarioTiposAsociables());
    dispatch(getAcoplados());
    dispatch(getChasis());
    dispatch(getOperacionesAbiertas());
    dispatch(getOperacionesCerradas());
    dispatch(getOperacionesTodas());
    dispatch(getOperacionesOffline());
    dispatch(getOperacionesFallidas());
    dispatch(getParteDiarioComentarios());
    dispatch(getDestinatariosMails());
  }, []);
  // useEffect(() => {
  //   const idUpdateData = setInterval(() => {
  //     dispatch(getBalanza());
  //     dispatch(getOperacionesIniciadas());
  //     dispatch(getOperacionesIniciadasManual());
  //     dispatch(getUltimasOperaciones());
  //     dispatch(getOperacionesAbiertas());
  //     dispatch(getOperacionesCerradas());
  //     dispatch(getOperacionesTodas());
  //   }, 180000);

  //   return () => {
  //     clearInterval(idUpdateData);
  //   };
  // }, []);
  
  // MODAL RESTART TURN
  const [showModalRestartTurn, setShowModalRestartTurn] = useState(false);
  
  const openModalToggleRestartTurn = () => {
    setShowModalRestartTurn(!showModalRestartTurn);
  };

  const openModalConfirmRestartTurn = () => {
    console.log("Reinicio de turno")
    setShowModalRestartTurn(false);
    dispatch(restartTurn());
  };

  return (
    <Home container>
      <Grid item xs={12} md={3} sm={3}>
        <Sidebar />
        <Head />
      </Grid>
      <Grid item xs={12} md={9}>
        <Header />
      </Grid>
      <Container>
        <Grid item xs={12} md={3}>
          <HeaderPad
            setShowModalCarga={setShowModalCarga}
            setShowModalChofer={setShowModalChofer}
            setShowModalTransportista={setShowModalTransportista}
            setShowModalLugar={setShowModalLugar}
            setShowModalPatenteChasis={setShowModalPatenteChasis}
            setShowModalPatenteAcoplado={setShowModalPatenteAcoplado}
            setShowModalAnularOperacion={setShowModalAnularOperacion}
            setShowModalOperacionId={setShowModalOperacionId}
            setShowModalEditarCarga={setShowModalEditarCarga}
            setShowModalEditarChofer={setShowModalEditarChofer}
            setShowModalEditarTransportista={setShowModalEditarTransportista}
            setShowModalEditarLugar={setShowModalEditarLugar}
            setShowModalEditarPatenteChasis={setShowModalEditarPatenteChasis}
            setShowModalEditarPatenteAcoplado={
              setShowModalEditarPatenteAcoplado
            }
            setShowModalIniciarOperacion={setShowModalIniciarOperacion}
            setShowModalFinalizarOperacion={setShowModalFinalizarOperacion}
            setShowModalEditarTalonario={setShowModalEditarTalonario}
            setShowModalTalonario={setShowModalTalonario}
            setShowCerrarTalonario={setShowCerrarTalonario}
            setShowModalCrearPesaje={setShowModalCrearPesaje}
            setShowModalReimprimir={setShowModalReimprimir}
            setShowModalCargarOperacion={setShowModalCargarOperacion}
            setShowModalReimprimirPopup={setShowModalReimprimirPopup}
          />
        </Grid>

        <Grid item xs={12} md={6} style={{ overflowY: "auto" }}>
          <BalanzaComponent
            setShowModalViajes={setShowModalViajes}
            setShowModalViajesIniciados={setShowModalViajesIniciados}
            setSelectedPesaje={setSelectedPesaje}
          />
          <OperacionesAbiertas />
          <OperacionesCerradas />
        </Grid>
        <Grid item xs={12} md={3}>
          <OptionsRight
            setShowModalParteQuincenal={setShowModalParteQuincenal}
            setShowModalParteMineria={setShowModalParteMineria}
            setShowModalParteCompleto={setShowModalParteCompleto}
            setShowModalParteTransportistas={setShowModalParteTransportistas}
            setShowModalCrearParteDiario={setShowModalCrearParteDiario}
            setShowModalEditarParteDiario={setShowModalEditarParteDiario}
            setShowModalEnviarParteDiario={setShowModalEnviarParteDiario}
            setShowModalVerPartesDiarios={setShowModalVerPartesDiarios}
            setShowModalRestartTurn={setShowModalRestartTurn}
          />
        </Grid>
        <ModalApproveBalanza />
        <FullPageLoader isLoading={isLoading} />
        {showModalViajes && (
          <ModalViajes
            onClose={openModalToggleViajes}
            selectedPesaje={selectedPesaje}
          />
        )}
        {showModalViajesIniciados && (
          <ModalViajesIniciados
            onClose={openModalToggleViajesIniciados}
            selectedPesaje={selectedPesaje}
          />
        )}
        {showModalChofer && (
          <ModalChofer onClose={() => setShowModalChofer(!showModalChofer)} />
        )}
        {showModalTransportista && (
          <ModalTransportista
            onClose={() => setShowModalTransportista(!showModalTransportista)}
          />
        )}
        {showModalLugar && (
          <ModalLugar onClose={() => setShowModalLugar(!showModalLugar)} />
        )}
        {showModalPatenteChasis && (
          <ModalPatenteChasis
            onClose={() => setShowModalPatenteChasis(!showModalPatenteChasis)}
          />
        )}
        {showModalPatenteAcoplado && (
          <ModalPatenteAcoplado
            onClose={() =>
              setShowModalPatenteAcoplado(!showModalPatenteAcoplado)
            }
          />
        )}
        {showModalCarga && (
          <ModalCarga onClose={() => setShowModalCarga(!showModalCarga)} />
        )}
        {showModalTalonario && (
          <ModalTalonario
            onClose={() => setShowModalTalonario(!showModalTalonario)}
          />
        )}
        {showModalCerrarTalonario && (
          <ModalCerrarTalonario
            onClose={() => setShowCerrarTalonario(!showModalCerrarTalonario)}
          />
        )}
        {showModalCargarOperacion && (
          <ModalCargarOperacion
            onClose={() =>
              setShowModalCargarOperacion(!showModalCargarOperacion)
            }
          />
        )}

        {showModalAnularOperacion && (
          <ModalAnularOperacion
            onClose={() =>
              setShowModalAnularOperacion(!showModalAnularOperacion)
            }
          />
        )}
        {showModalReimprimir && (
          <ModalReimprimir
            onClose={() => setShowModalReimprimir(!showModalReimprimir)}
            setModalReimprimirPopup={setShowModalReimprimirPopup}
          />
        )}

        {showModalReimprimirPopup && (
          <ModalReimprimirPopup
            onClose={() =>
              setShowModalReimprimirPopup(!showModalReimprimirPopup)
            }
          />
        )}

        {showModalOperacionId && (
          <ModalOperacionId
            onClose={() => setShowModalOperacionId(!showModalOperacionId)}
            setModalAnularOperacion={setShowModalAnularOperacion}
          />
        )}
        {showModalEditarCarga && (
          <ModalEditarCarga
            onClose={() => setShowModalEditarCarga(!showModalEditarCarga)}
          />
        )}
        {showModalEditarChofer && (
          <ModalEditarChofer
            onClose={() => setShowModalEditarChofer(!showModalEditarChofer)}
          />
        )}
        {showModalEditarTransportista && (
          <ModalEditarTransportista
            onClose={() =>
              setShowModalEditarTransportista(!showModalEditarTransportista)
            }
          />
        )}
        {showModalEditarLugar && (
          <ModalEditarLugar
            onClose={() => setShowModalEditarLugar(!showModalEditarLugar)}
          />
        )}
        {showModalEditarPatenteChasis && (
          <ModalEditarPatenteChasis
            onClose={() =>
              setShowModalEditarPatenteChasis(!showModalEditarPatenteChasis)
            }
          />
        )}
        {showModalEditarPatenteAcoplado && (
          <ModalEditarPatenteAcoplado
            onClose={() =>
              setShowModalEditarPatenteAcoplado(!showModalEditarPatenteAcoplado)
            }
          />
        )}
        {showModalEditarTalonario && (
          <ModalEditarTalonario
            onClose={() =>
              setShowModalEditarTalonario(!showModalEditarTalonario)
            }
          />
        )}
        {showModalCrearPesaje && (
          <ModalCrearPesaje
            onClose={() => setShowModalCrearPesaje(!showModalCrearPesaje)}
          />
        )}
        {showModalIniciarOperacion && (
          <ModalIniciarOperacion
            onClose={() =>
              setShowModalIniciarOperacion(!showModalIniciarOperacion)
            }
          />
        )}
        {showModalFinalizarOperacion && (
          <ModalFinalizarOperacion
            onClose={() =>
              setShowModalFinalizarOperacion(!showModalFinalizarOperacion)
            }
          />
        )}
        {showModalParteQuincenal && (
          <ModalParteQuincenal
            onClose={() => setShowModalParteQuincenal(!showModalParteQuincenal)}
          />
        )}
        {showModalParteMineria && (
          <ModalParteMineria
            onClose={() => setShowModalParteMineria(!showModalParteMineria)}
          />
        )}
        {showModalParteCompleto && (
          <ModalParteCompleto
            onClose={() => setShowModalParteCompleto(!showModalParteCompleto)}
          />
        )}
        {showModalParteTransportistas && (
          <ModalParteTransportistas
            onClose={() =>
              setShowModalParteTransportistas(!showModalParteTransportistas)
            }
          />
        )}
        {showModalCrearParteDiario && (
          <ModalCrearParteDiario
            onClose={() =>
              setShowModalCrearParteDiario(!showModalCrearParteDiario)
            }
          />
        )}

        {showModalEditarParteDiario && (
          <ModalEditarParteDiario
            onClose={() =>
              setShowModalEditarParteDiario(!showModalEditarParteDiario)
            }
          />
        )}
        {showModalEnviarParteDiario && (
          <ModalEnviarParteDiario
            onClose={() =>
              setShowModalEnviarParteDiario(!showModalEnviarParteDiario)
            }
          />
        )}
        {showModalVerPartesDiarios && (
          <ModalVerPartesDiarios
            onClose={() =>
              setShowModalVerPartesDiarios(!showModalVerPartesDiarios)
            }
          />
        )}
        {showModalRestartTurn && (
          <ModalRestartTurn open={showModalRestartTurn} onClose={openModalToggleRestartTurn} onConfirm={openModalConfirmRestartTurn} />
        )}
      </Container>
    </Home>
  );
};
