import { GET_BALANZA_CLS } from "../../constants/actionsTypes";

const initialState = {};

export const balanzaClsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_BALANZA_CLS: {
      return { ...state, ...payload };
    }
    default:
      return state;
  }
};
