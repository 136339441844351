import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Grid } from "@material-ui/core";
import {
  FormControl,
  InputLabel,
  FormHelperText,
  MenuItem,
  Select as MuiSelect,
} from "@material-ui/core";
import {
  Background,
  ModalWrapper,
  Head,
  IconContainer,
  ButtonContainer,
  StyledForm,
} from "./Styles";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

/* Componentes */
import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";

/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Inputs } from "../../Input/Inputs";
// import SelectInput from "../../SelectInput/SelectInput";
import {
  finalizarViajePost,
  getOperacionesIniciadasManual,
} from "../../../Services";
import { validateError } from "../../../Services/validateError";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  chasis_patente: yup.string().required("Campo requerido"),
  acoplado_patente: yup.string(),
  transportista_id: yup.string(),
  chofer_id: yup.string(),
  carga_id: yup.string(),
  origen_id: yup.string(),
  destino_id: yup.string(),
  comentario: yup.string(),
  volumen: yup.number(),
});

export const ModalFinalizarOperacion = ({ onClose }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOperacionesIniciadasManual());
  }, [dispatch]);

  const { cargas } = useSelector((state) => state.cargas);
  const { transportistas } = useSelector((state) => state.transportistas);
  const { choferes } = useSelector((state) => state.choferes);
  const { origenes } = useSelector((state) => state.origenes);
  const { destinos } = useSelector((state) => state.destinos);
  const { operaciones } = useSelector(
    (state) => state.operaciones_iniciadas_manual
  );

  const formik = useFormik({
    initialValues: {
      bal_salida_id: null,
      chasis_patente: "",
      acoplado_patente: "",
      transportista_id: "",
      chofer_id: "",
      carga_id: "",
      origen_id: "",
      destino_id: "",
      comentario: "",
      volumen: 0,
      remito: null,
      guia_numero: null,
    },

    validationSchema: validationSchema,

    onSubmit: async (values) => {
      const chasisPatente = values.chasis_patente.toUpperCase();
      if (values.volumen === "" || values.volumen === null) {
        formik.setErrors({ volumen: "El volumen debe ser mayor a 0" });
        return;
      }

      const updatedValues = {
        chasis_patente: chasisPatente,
        comentario: values.comentario,
        volumen: values.volumen,
        bal_salida_id: null,
      };
      console.log(updatedValues, "updatedValues");
      // Enviar la solicitud con los IDs actualizados
      dispatch(finalizarViajePost(updatedValues));
      onClose();
    },
  });

  const SelectInput = ({ label, name, value, options, error, disabled }) => {
    const handleSelectChange = (event) => {
      formik.handleChange(event);
      handlePatenteChange(name, event.target.value);
    };

    return (
      <FormControl fullWidth>
        <InputLabel>{label}</InputLabel>
        <MuiSelect
          name={name}
          value={value}
          onChange={handleSelectChange}
          disabled={disabled}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </MuiSelect>
        {error && (
          <FormHelperText error={error}>{formik.errors?.[name]}</FormHelperText>
        )}
      </FormControl>
    );
  };

  const handlePatenteChange = (fieldName, selectedValue) => {
    const patenteValue = selectedValue;

    if (fieldName === "chasis_patente") {
      const matchingOperacion = operaciones.find(
        (operacion) => operacion?.chasis?.patente === patenteValue
      );

      if (matchingOperacion) {
        formik.setValues((prevValues) => ({
          ...prevValues,
          chasis_patente: patenteValue,
          acoplado_patente: matchingOperacion?.acoplado?.patente,
          transportista_id: matchingOperacion?.transportista?.nombre,
          chofer_id: matchingOperacion?.chofer?.nombre_apellido,
          carga_id: matchingOperacion?.tipo?.carga?.nombre,
          origen_id: matchingOperacion?.tipo?.origen?.nombre,
          destino_id: matchingOperacion?.tipo?.destino?.nombre,
          remito: matchingOperacion?.remito ? matchingOperacion?.remito : null,
          guia_numero: matchingOperacion?.guia?.numero
            ? matchingOperacion?.guia?.numero
            : null,
          talonario: matchingOperacion?.guia?.talonario?.numero
            ? matchingOperacion?.guia?.talonario?.numero
            : null,
        }));
      }
    }
  };

  const handleClose = () => {
    onClose();
  };

  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper onClose={() => handleClose()}>
        <Head>
          <p>Finalizar operación sin pesaje</p>
          <IconContainer>
            <FontAwesomeIcon icon={faTimes} onClick={onClose} />
          </IconContainer>
        </Head>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid container md={12} sm={8} justify="center">
            <SelectInput
              label="Patente"
              name="chasis_patente"
              value={formik.values?.chasis_patente}
              onChange={handlePatenteChange}
              error={
                formik.errors?.chasis_patente && formik.touched?.chasis_patente
              }
              options={operaciones?.map((patente) => ({
                value: patente.chasis.patente,
                label: patente.chasis.patente,
              }))}
            />
            <Inputs
              label="Acoplado"
              type="text"
              name="acoplado_patente"
              value={formik.values.acoplado_patente}
              disabled
              error={
                formik.errors.acoplado_patente &&
                formik.touched.acoplado_patente
              }
            />
            {formik.errors.acoplado_patente &&
              formik.touched.acoplado_patente && (
                <FormHelperText
                  error={
                    formik.errors.acoplado_patente &&
                    formik.touched.acoplado_patente
                  }
                >
                  {formik.errors?.acoplado_patente}
                </FormHelperText>
              )}
            <SelectInput
              label="Empresa Transportista"
              type="text"
              name="transportista_id"
              value={formik.values.transportista_id}
              // onChange={formik.handleChange}
              error={
                formik.errors.transportista_id &&
                formik.touched.transportista_id
              }
              options={transportistas.map((transportista) => ({
                value: transportista.nombre,
                label: transportista.nombre,
              }))}
              disabled
            />
            {formik.errors.transportista_id &&
              formik.touched.transportista_id && (
                <FormHelperText
                  error={
                    formik.errors.transportista_id &&
                    formik.touched.transportista_id
                  }
                >
                  {formik.errors?.transportista_id}
                </FormHelperText>
              )}
            <SelectInput
              label="Chofer"
              type="text"
              name="chofer_id"
              value={formik.values.chofer_id}
              disabled
              error={formik.errors.chofer_id && formik.touched.chofer_id}
              options={choferes.map((chofer) => ({
                value: `${chofer.nombre} ${chofer.apellido}`,
                label: `${chofer.nombre} ${chofer.apellido}`,
              }))}
            />
            {formik.errors.chofer_id && formik.touched.chofer_id && (
              <FormHelperText
                error={formik.errors.chofer_id && formik.touched.chofer_id}
              >
                {formik.errors?.chofer_id}
              </FormHelperText>
            )}

            <SelectInput
              label="Tipo de producto"
              type="text"
              name="carga_id"
              value={formik.values.carga_id}
              disabled
              error={formik.errors.carga_id && formik.touched.carga_id}
              options={cargas.map((carga) => ({
                value: carga.nombre,
                label: carga.nombre,
              }))}
            />
            {formik.errors.carga_id && formik.touched.carga_id && (
              <FormHelperText
                error={formik.errors.carga_id && formik.touched.carga_id}
              >
                {formik.errors?.carga_id}
              </FormHelperText>
            )}

            <SelectInput
              label="Origen"
              type="text"
              name="origen_id"
              value={formik.values.origen_id}
              disabled
              error={formik.errors.origen_id && formik.touched.origen_id}
              options={origenes.map((origen) => ({
                value: origen.nombre,
                label: origen.nombre,
              }))}
            />
            {formik.errors.origen_id && formik.touched.origen_id && (
              <FormHelperText
                error={formik.errors.origen_id && formik.touched.origen_id}
              >
                {formik.errors?.origen_id}
              </FormHelperText>
            )}
            <SelectInput
              label="Destino"
              type="text"
              name="destino_id"
              value={formik.values.destino_id}
              disabled
              error={formik.errors.destino_id && formik.touched.destino_id}
              options={destinos.map((destino) => ({
                value: destino.nombre,
                label: destino.nombre,
              }))}
            />
            {formik.errors.destino_id && formik.touched.destino_id && (
              <FormHelperText
                error={formik.errors.destino_id && formik.touched.destino_id}
              >
                {formik.errors?.destino_id}
              </FormHelperText>
            )}
            <Grid item sm={6}>
              <InputLabel
                id="volumen"
                style={{ marginTop: "1rem", fontSize: "13px" }}
              >
                Volumen (m3)
              </InputLabel>

              <Inputs
                type="number"
                name="volumen"
                value={formik.values.volumen}
                onChange={formik.handleChange}
                error={formik.errors.volumen && formik.touched.volumen}
              />
              {formik.errors.volumen && formik.touched.volumen && (
                <FormHelperText
                  error={formik.errors.volumen && formik.touched.volumen}
                >
                  {formik.errors?.volumen}
                </FormHelperText>
              )}
            </Grid>

            <Grid item sm={6}>
              <InputLabel
                id="comentario"
                style={{ marginTop: "1rem", fontSize: "13px" }}
              >
                Comentario
              </InputLabel>
              <Inputs
                type="text"
                name="comentario"
                value={formik.values.comentario}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item sm={4}>
              <InputLabel
                id="remito"
                style={{ marginTop: "1rem", fontSize: "13px" }}
              >
                Remito
              </InputLabel>
              <Inputs
                type="number"
                name="remito"
                value={formik.values.remito}
                disabled
              />
            </Grid>
            <Grid item sm={4}>
              <InputLabel
                id="guia_numero"
                style={{ marginTop: "1rem", fontSize: "13px" }}
              >
                Guía
              </InputLabel>
              <Inputs
                type="number"
                name="guia_numero"
                value={formik.values.guia_numero}
                disabled
              />
            </Grid>
            <Grid item sm={4}>
              <InputLabel
                id="talonario"
                style={{ marginTop: "1rem", fontSize: "13px" }}
              >
                Talonario
              </InputLabel>
              <Inputs
                type="number"
                name="talonario"
                value={formik.values.talonario}
                disabled
              />
            </Grid>

            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  type="button"
                  textArea="Cancelar" /* Acà se puede validar el tipo de boton */
                  onClick={() => handleClose()}
                  variant="outlined"
                  color="primary"
                  minwidth="true"
                />
              </ButtonContainer>
            </Grid>
            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  // disabled={validated}
                  type="submit"
                  textArea="Finalizar"
                  variant="contained"
                  color="primary"
                  mright="true"
                />
              </ButtonContainer>
            </Grid>
          </Grid>
        </StyledForm>
      </ModalWrapper>
    </Background>,
    document.getElementById("root")
  );
};
