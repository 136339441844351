import { GET_ACOPLADOS_CLS } from "../../constants/actionsTypes";

const initialState = {};

export const acopladosReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ACOPLADOS_CLS: {
      return { ...state, ...payload };
    }
    default:
      return state;
  }
};
