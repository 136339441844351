import {
  GET_OPERACIONES_FINALIZABLES,
  GET_OPERACIONES_FINALIZABLES_MANUAL,
  GET_OPERACIONES_ABIERTAS,
  GET_OPERACIONES_CERRADAS,
  GET_OPERACIONES_TODAS,
  GET_OPERACIONES_OFFLINE,
  GET_OPERACIONES_FALLIDAS,
} from "../../constants/actionsTypes";

export const getOperacionesFinalizablesCls = (payload) => {
  return {
    type: GET_OPERACIONES_FINALIZABLES,
    payload,
  };
};

export const getOperacionesFinalizablesManualCls = (payload) => {
  return {
    type: GET_OPERACIONES_FINALIZABLES_MANUAL,
    payload,
  };
};

export const getOperacionesAbiertasCls = (payload) => {
  return {
    type: GET_OPERACIONES_ABIERTAS,
    payload,
  };
};

export const getOperacionesCerradasCls = (payload) => {
  return {
    type: GET_OPERACIONES_CERRADAS,
    payload,
  };
};

export const getOperacionesTodasCls = (payload) => {
  return {
    type: GET_OPERACIONES_TODAS,
    payload,
  };
};

export const getOperacionesOfflineCls = (payload) => {
  return {
    type: GET_OPERACIONES_OFFLINE,
    payload,
  };
};

export const getOperacionesFallidasCls = (payload) => {
  return {
    type: GET_OPERACIONES_FALLIDAS,
    payload,
  };
};
