import styled from "styled-components";

export const Background = styled.div`
  background: rgba(0, 0, 0, 0.4);
  display: block;
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 1001;
  top: 0;
  left: 0;
`;

export const ModalWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.35);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px auto;
  width: 90%;
  max-width: 400px;
  height: auto;
  overflow: hidden;
  padding: 25px;
  z-index: 1002;
  position: relative;
  top: 20%;
  & p {
    font-weight: 500;
    font-size: 16px;
    color: #454545;
    margin-top: 18px;
    margin-bottom: 0.75rem;
  }
  & svg {
    width: 100px;
    height: 85px;
  }
`;

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
