import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import { Grid } from "@material-ui/core";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import {
  Background,
  ModalWrapper,
  Head,
  IconContainer,
  ButtonContainer,
  StyledForm,
} from "./Styles";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

/* Componentes */
import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";

/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Inputs } from "../../Input/Inputs";

import { getOperacionId, getOperacionesCerradas } from "../../../Services";
import { resetOperacionIdCls } from "../../../actions/balanza/operacionIdActions";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  ticket: yup.number().required("Campo requerido"),
});

export const ModalReimprimir = ({ onClose, setModalReimprimirPopup }) => {
  const dispatch = useDispatch();
  const [showTable, setShowTable] = useState(false);
  useEffect(() => {
    dispatch(getOperacionesCerradas());
  }, [dispatch]);
  const { operaciones } = useSelector((state) => state?.operaciones_cerradas);
  const formik = useFormik({
    initialValues: {
      ticket: null,
    },

    validationSchema: validationSchema,

    onSubmit: (values) => {
      dispatch(getOperacionId(values.ticket));
      onClose();
      setModalReimprimirPopup(true);
    },
  });

  const handleClose = () => {
    dispatch(resetOperacionIdCls());
    onClose();
  };
  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper onClose={() => handleClose()}>
        <Head>
          <p>Buscar operación por ticket para reimprimir</p>
          <IconContainer>
            <FontAwesomeIcon icon={faTimes} onClick={onClose} />
          </IconContainer>
        </Head>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid container md={12} sm={8} justify="center">
            <Inputs
              label="Ticket"
              type="number"
              name="ticket"
              value={formik.values.ticket}
              onChange={formik.handleChange}
            />
            {showTable && (
              <div
                style={{
                  height: "400px",
                  overflow: "auto",
                  marginTop: "20px",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Ticket</TableCell>
                      <TableCell>Chofer</TableCell>
                      <TableCell>Transportista</TableCell>
                      <TableCell>Chasis</TableCell>
                      <TableCell>Acoplado</TableCell>
                      <TableCell>Carga</TableCell>
                      <TableCell>Origen</TableCell>
                      <TableCell>Destino</TableCell>
                      <TableCell>Remito</TableCell>
                      <TableCell>Guia</TableCell>
                      <TableCell>Acciones</TableCell>{" "}
                      {/* Agregamos esta celda para los botones */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {operaciones?.map((viaje) => (
                      <TableRow key={viaje.ticket}>
                        <TableCell>{viaje.ticket}</TableCell>
                        <TableCell>{viaje.chofer?.nombre_apellido}</TableCell>
                        <TableCell>{viaje.transportista?.nombre}</TableCell>
                        <TableCell>{viaje.chasis?.patente}</TableCell>
                        <TableCell>{viaje.acoplado?.patente}</TableCell>
                        <TableCell>{viaje.tipo.carga?.nombre}</TableCell>
                        <TableCell>{viaje.tipo.origen?.nombre}</TableCell>
                        <TableCell>{viaje.tipo.destino?.nombre}</TableCell>
                        <TableCell>
                          {viaje.remito ? viaje.remito : null}
                        </TableCell>
                        <TableCell>{viaje.guia?.numero}</TableCell>
                        <TableCell>
                          <ButtonPrimary
                            type="button"
                            textArea="Seleccionar"
                            onClick={() => {
                              dispatch(getOperacionId(viaje.ticket));
                              onClose();
                              setModalReimprimirPopup(true);
                            }}
                            variant="outlined"
                            color="primary"
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            )}

            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  type="button"
                  textArea="Cancelar" /* Acà se puede validar el tipo de boton */
                  onClick={() => handleClose()}
                  variant="outlined"
                  color="primary"
                  minwidth="true"
                />
              </ButtonContainer>
            </Grid>
            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  // disabled={validated}
                  type="button"
                  textArea="Ver ultimas operaciones"
                  minheight={true}
                  onClick={() => setShowTable(!showTable)}
                  variant="outlined"
                  color="primary"
                  mright="true"
                />
              </ButtonContainer>
            </Grid>
            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  // disabled={validated}
                  type="submit"
                  textArea="Buscar"
                  variant="contained"
                  color="primary"
                  mright="true"
                />
              </ButtonContainer>
            </Grid>
          </Grid>
        </StyledForm>
      </ModalWrapper>
    </Background>,
    document.getElementById("root")
  );
};
