import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";

import { Grid, FormHelperText } from "@material-ui/core";
import {
  Background,
  ModalWrapper,
  Head,
  IconContainer,
  FormGroup,
  ButtonContainer,
  CustomCheckbox,
  StyledForm,
  CheckboxContainer,
} from "./Styles";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

/* Componentes */
import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";
import { ModalHabilitarNuevoCamion } from "../ModalHabilitarNuevoCamion/ModalHabilitarNuevoCamion";
// import HabilitarCamionApprove from '../HabilitarCamionApprove/HabilitarCamionApprove';

/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
import { camionesHistoricosPost } from "../../../Services";
import { addHours } from "../../../utils/DateTimeConverter/DateTimeConverter";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  trucks_plates: yup
    .array()
    .of(
      yup
        .string()
        .required("Debe seleccionar al menos un camión para habilitar") // Validate the selected truck plates
    )
    .required("Debe seleccionar al menos un camión para habilitar"), // Validate the selected truck plates
});

export const ModalHabilitarCamion = ({ onClose }) => {
  const [habilitarNuevoCamion, setHabilitarNuevoCamion] = useState(false);
  const dispatch = useDispatch();
  const camionesHistoricos = useSelector(
    (state) => state.camionesHistoricos.camiones
  );
  const hoyArgentina = addHours(-3, new Date()).toISOString();
  const { Name, Role, Email } = useSelector((state) => state.dataUser.user);
  const [validated, setValidated] = useState(true);
  const openModalToggleHabilitarNuevoCamion = () => {
    setHabilitarNuevoCamion(!habilitarNuevoCamion);
  };
  const formik = useFormik({
    initialValues: {
      trucks_plates: [], // Initialize an empty array to store the selected truck plates
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        camionesHistoricosPost({
          ...values,
          contratista: "CONTRATISTA",
          user_name: Name,
          user_email: Email,
          user_rol: Role,
          datetime: hoyArgentina,
        })
      );
      onClose();
    },
  });

  const handleCloseModal = () => {
    onClose();
  };
  const handleHabilitarNuevoCamionClick = () => {
    openModalToggleHabilitarNuevoCamion();
  };
  // useEffect(() => {
  //   dispatch(camionesInactivos());
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (formik.values.trucks_plates !== "") {
      setValidated(false);
    } else setValidated(true);
  }, [formik, setValidated]);

  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper onClose={handleCloseModal}>
        <Head>
          <p>Asignar camión</p>
          <IconContainer>
            <FontAwesomeIcon icon={faTimes} onClick={onClose} />
          </IconContainer>
        </Head>
        <StyledForm onSubmit={formik.handleSubmit}>
          <CheckboxContainer container spacing={1}>
            {camionesHistoricos &&
              camionesHistoricos
                .sort((a, b) => a.localeCompare(b)) // Orden ascendente
                .map((camion) => (
                  <Grid item xs={10} sm={5} md={2} key={camion.id}>
                    <FormGroup>
                      <label>
                        <CustomCheckbox
                          type="checkbox"
                          name="trucks_plates"
                          onChange={formik.handleChange}
                          value={camion}
                          checked={formik.values.trucks_plates.includes(camion)} // Verifica si el checkbox está seleccionado
                        />
                        {camion}
                      </label>
                    </FormGroup>
                  </Grid>
                ))}
          </CheckboxContainer>
          <ButtonContainer>
            <ButtonPrimary
              textArea="Cancelar"
              variant="outlined"
              color="primary"
              minwidth="true"
              onClick={handleCloseModal}
            />
            <ButtonPrimary
              textArea="Nuevo Camión"
              variant="outlined"
              color="primary"
              minwidth="true"
              onClick={handleHabilitarNuevoCamionClick}
            />
            <ButtonPrimary
              disabled={validated}
              type="submit"
              textArea="Guardar"
              variant="contained"
              color="primary"
              minwidth="true"
            />
          </ButtonContainer>
        </StyledForm>
        {habilitarNuevoCamion && (
          <ModalHabilitarNuevoCamion
            onClose={openModalToggleHabilitarNuevoCamion}
          />
        )}
      </ModalWrapper>
    </Background>,
    document.getElementById("root")
  );
};
