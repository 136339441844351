import { GET_ESTADORT, RESET_ESTADORT } from "../constants/actionsTypes";

export const getEstadoRT = (payload) => {
  return {
    type: GET_ESTADORT,
    payload: payload,
  };
};

export const resetEstadoRT = () => ({
  type: RESET_ESTADORT,
});
