import React from "react";
import ReactDOM from "react-dom";
import useAlert from "../../Hooks/useAlert";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ReactComponent as Success } from "../../assets/img/check-circle.svg";
import { ReactComponent as Error } from "../../assets/img/error-triangle.svg";
import { Background, ModalWrapper, ContainerButtons } from "./Styles";

import ButtonPrimary from "../../Components/ButtonPrimary/ButtonPrimary";
import { resetResponse } from "../../actions/responseActions";

/* Modal de aprobacion cuando todo sucede bien o mal */
const ModalApproveCls = () => {
  const { response } = useAlert();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClick = () => {
    if (response.status === 200 || response.status === 201) {
      history.push("/aluvional");
      dispatch(resetResponse());

      window.location.reload();
    } else {
      history.push();
      dispatch(resetResponse());
    }
  };

  return ReactDOM.createPortal(
    response.show ? (
      <Background>
        <ModalWrapper>
          {(response.status === 200 || response.status === 201) && <Success />}
          {response.status >= 400 && <Error />}
          <p style={{ textAlign: "center" }}>{response.message}</p>
          <ContainerButtons>
            <ButtonPrimary
              type="primary"
              textArea="Aceptar"
              variant="contained"
              color="primary"
              onClick={() => handleClick()}
            />
          </ContainerButtons>
        </ModalWrapper>
      </Background>
    ) : (
      <div />
    ),
    document.getElementById("root")
  );
};

export default ModalApproveCls;
