import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button, FormControl } from "@material-ui/core";

export const IconContainer = styled.div`
  & svg {
    font-size: 1.2rem;
    color: #575757;
  }
`;

export const Container = styled.div`
  display: flow-root;
  position: relative;
  && {
    width: 100%;
    // min-height: calc(100vh - 60px);
    background-color: #ffffff;
    overflow: auto;
    margin: 0 auto;
  }
`;

export const Head = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0.813rem auto 0 auto;
  padding: 0 1rem 0 1rem;
`;

export const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.875rem auto calc(1.875rem - 1.25rem) auto;
  padding: 0 1rem 0 1rem;
`;

export const TableContainer = styled.div`
  width: 50.625rem;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 1.313rem 1.875rem;
  margin: 30px auto;

  @media (max-width: 768px) {
    width: 98%;
    padding: 10px;
  }
`;
export const Buton = styled(Button)`
  && {
    font-family: "Roboto", sans-serif;
    color: var(--purplePrimary);
    border: 0.063rem solid var(--purplePrimary);
    border-radius: 5px;
    width: ${(props) => (props.width ? "11.188rem" : "6.25rem")};
    height: 2.5rem;
    text-transform: none;
    font-size: 14px;

    &:active:hover:not([disabled]) {
      border-color: var(--purplePrimary);
    }

    &:disabled {
      background-color: #c0dcf5;
      border-color: #c0dcf5;
      opacity: 1;
    }
  }
`;

export const StyledTableCell = styled.th`
  background-color: #c8c8c8;
  color: #ffffff;
  font-size: 14px;
`;

export const StyledTableRow = styled.td`
  &:nth-child(even) {
    background-color: #f4f4f4;
  }
  &:hover {
    background-color: #ddd;
  }
`;
export const ButtonEdit = styled(Link)`
  background: none;
  border: none;
  text-decoration: underline;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

export const Title = styled.p`
  font-family: "Roboto";
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1.563rem;
`;

export const Paragraph = styled.p`
  font-family: "Roboto";
  font-size: 1rem;
`;
export const FormGroup = styled(FormControl)`
  width: 15.625rem;
  height: 4.375rem;
`;
