import { ButtonP } from "./Styles";

const ButtonPrimary = ({
  type,
  textArea,
  variant,
  minwidth,
  minheight,
  color,
  onClick,
  mright,
  disabled,
}) => {
  return (
    <ButtonP
      type={type}
      variant={variant}
      minwidth={minwidth}
      minheight={minheight}
      color={color}
      mright={mright}
      onClick={onClick}
      disabled={disabled}
    >
      {textArea}
    </ButtonP>
  );
};

export default ButtonPrimary;
