import { logout } from "../actions/loginActions";
import { resetResponse, setResponse } from "../actions/responseActions";

/* Valida los diferentes tipos de errores que arroja el Back y devuelve un mensaje personalizado */
export const validateError =
  (error, rute = "") =>
  (dispatch) => {
    const show = true;
    const status = error.response?.status;
    const data = error.response?.data;

    switch (status) {
      case 400: {
        dispatch(
          setResponse({
            status: 400,
            message: data.detail
              ? data.detail
              : "Ocurrio un error y los datos no se pudieron guardar",
            show,
            redirect: "",
          })
        );
        break;
      }
      case 401: {
        dispatch(
          setResponse({
            status: 401,
            message: data.detail
              ? data.detail === "TOKEN_VENCIDO"
                ? "La sesion a expirado, intente ingresar nuevamente"
                : data.detail
              : data.detail,
            show,
            redirect: "",
          })
        );
        setTimeout(() => {
          dispatch(resetResponse());
          // window.location.href = '/'
          window.location.reload();
          dispatch(logout());
        }, 3000);
        break;
      }
      case 403: {
        dispatch(
          setResponse({
            status: 403,
            message: data.detail
              ? data.detail
              : "No tienes permisos para hacer esta peticion",
            show,
            redirect: rute,
          })
        );
        break;
      }
      case 404: {
        dispatch(
          setResponse({
            status: 404,
            message: data.detail
              ? data.detail
              : "El recurso que solicitó no se encuentra disponible",
            show,
            redirect: rute,
          })
        );
        break;
      }
      case 405: {
        dispatch(
          setResponse({
            status: 405,
            message: data.detail
              ? data.detail
              : "No pudo realizar la operación",
            show,
            redirect: rute,
          })
        );
        break;
      }
      case 406: {
        dispatch(
          setResponse({
            status: 406,
            message: data.detail
              ? data.detail
              : "Esta planta ya existe, no la puedes crear nuevamente",
            show,
            redirect: rute,
          })
        );
        break;
      }
      case 409: {
        dispatch(
          setResponse({
            status: 409,
            message: data.detail
              ? data.detail
              : "Los parametros asignados no son validos para la simulación",
            show,
            redirect: rute,
          })
        );
        break;
      }
      case 422: {
        dispatch(
          setResponse({
            status: 422,
            message: data.detail.msg
              ? data.detail.msg
              : "Ocurrio un error y los datos no se pudieron guardar",
            show,
            redirect: "",
          })
        );
        break;
      }

      case status >= 500 && status <= 599: {
        dispatch(
          setResponse({
            status: 500,
            message: data.detail
              ? data.detail
              : "Ocurrió un error en servidor, vuelva intentarlo mas tarde.",
            show,
            redirect: "/home",
          })
        );
        break;
      }

      default:
        break;
    }
  };
