import {
  GET_ORIGENES_CLS,
  GET_DESTINOS_CLS,
  GET_LUGARES_CLS,
} from "../../constants/actionsTypes";

const initialState = {};

export const origenesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ORIGENES_CLS: {
      return { ...state, ...payload };
    }
    default:
      return state;
  }
};

export const destinosReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_DESTINOS_CLS: {
      return { ...state, ...payload };
    }
    default:
      return state;
  }
};

export const lugaresReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_LUGARES_CLS: {
      return { ...state, ...payload };
    }
    default:
      return state;
  }
};
