import React from "react";
import { useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import { Container, TableContainer, Title } from "./Styles";
import {
  Grid,
  Table,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#C8C8C8",
    color: "#000",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export const OperacionesCerradas = () => {
  const { operaciones } = useSelector((state) => state?.operaciones_cerradas);

  // Mostrar solo los primeros 10 registros de 'operaciones'
  const primeros10Operaciones = operaciones
    ?.slice(0, 10)
    .sort((a, b) => b.ticket - a.ticket);

  const getPrefixedTicket = (ticket, isOffline) => {
    return isOffline === 0 ? `O-${ticket}` : `S-${ticket}`;
  };

  return (
    <Container>
      <Grid item md={12}></Grid>
      <TableContainer component={Paper}>
        <Grid item md={12}>
          <Title>Operaciones finalizadas</Title>
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Ticket</StyledTableCell>
              <StyledTableCell>Hora</StyledTableCell>
              <StyledTableCell>Peso bruto</StyledTableCell>
              <StyledTableCell>Tara</StyledTableCell>
              <StyledTableCell>Peso neto</StyledTableCell>
              <StyledTableCell>Chasis</StyledTableCell>
              <StyledTableCell>Acoplado</StyledTableCell>
              <StyledTableCell>Chofer</StyledTableCell>
              <StyledTableCell>Carga</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {primeros10Operaciones?.map((operacion) => (
              <StyledTableRow key={operacion.ticket}>
                <StyledTableCell>
                  {getPrefixedTicket(operacion.ticket, operacion.is_offline)}
                </StyledTableCell>
                <StyledTableCell>{operacion.hora}</StyledTableCell>
                <StyledTableCell>{operacion.bruto} Tn.</StyledTableCell>
                <StyledTableCell>{operacion.tara} Tn.</StyledTableCell>
                <StyledTableCell>{operacion.neto} Tn.</StyledTableCell>
                <StyledTableCell>{operacion.chasis?.patente}</StyledTableCell>
                <StyledTableCell>{operacion.acoplado?.patente}</StyledTableCell>
                <StyledTableCell>
                  {operacion.chofer?.nombre_apellido}
                </StyledTableCell>
                <StyledTableCell>
                  {operacion.tipo?.carga?.nombre}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};
