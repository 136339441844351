import {
  GET_DESTINOS_CLS,
  GET_ORIGENES_CLS,
  GET_LUGARES_CLS,
} from "../../constants/actionsTypes";

export const getDestinosCls = (payload) => {
  return {
    type: GET_DESTINOS_CLS,
    payload,
  };
};

export const getOrigenesCls = (payload) => {
  return {
    type: GET_ORIGENES_CLS,
    payload,
  };
};

export const getLugaresCls = (payload) => {
  return {
    type: GET_LUGARES_CLS,
    payload,
  };
};
