import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import {
  Grid,
  Select,
  MenuItem,
  FormHelperText,
  InputLabel,
} from "@material-ui/core";
import {
  Background,
  ModalWrapper,
  Head,
  IconContainer,
  FormGroup,
  ButtonContainer,
  StyledForm,
} from "./Styles";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

/* Componentes */
import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";

/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  getParteQuincenal,
  getChoferes,
  getChasis,
  getCargas,
} from "../../../Services";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  mes_operativo: yup.number().required("El mes operativo es requerido"),
});

export const ModalParteQuincenal = ({ onClose }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getChoferes());
    dispatch(getChasis());
    dispatch(getCargas());
  }, [dispatch]);
  const { choferes } = useSelector((state) => state.choferes);
  const { chasis } = useSelector((state) => state.chasis);
  const { cargas } = useSelector((state) => state.cargas);
  const formik = useFormik({
    initialValues: {
      mes_operativo: "",
      operacion_tipo: null,
      chasis_patente: null,
      chofer_id: null,
      entrada: null,
    },

    validationSchema: validationSchema,

    onSubmit: (values) => {
      if (values.chofer_id !== null) {
        const choferid = choferes?.find(
          (chofer) => chofer?.nombre_apellido === values?.chofer_id
        ).id;
        dispatch(
          getParteQuincenal(
            values.mes_operativo,
            values.operacion_tipo,
            values.chasis_patente,
            choferid,
            values.entrada
          )
        );
      } else {
        dispatch(
          getParteQuincenal(
            values.mes_operativo,
            values.operacion_tipo,
            values.chasis_patente,
            values.chofer_id,
            values.entrada
          )
        );
      }
      onClose();
    },
  });

  const handleClose = () => {
    onClose();
  };

  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper onClose={() => handleClose()}>
        <Head>
          <p>Indique el mes operativo del parte quincenal</p>
          <IconContainer>
            <FontAwesomeIcon icon={faTimes} onClick={onClose} />
          </IconContainer>
        </Head>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid xs={12} md={12}>
              <FormGroup>
                <InputLabel id="mes_operativo">Mes operativo</InputLabel>
                <Select
                  id="mes_operativo"
                  name="mes_operativo"
                  onChange={formik.handleChange}
                  value={formik.values.mes_operativo}
                  error={
                    formik.errors.mes_operativo && formik.touched.mes_operativo
                  }
                >
                  <MenuItem value="">Seleccione un mes</MenuItem>
                  <MenuItem value={1}>Enero</MenuItem>
                  <MenuItem value={2}>Febrero</MenuItem>
                  <MenuItem value={3}>Marzo</MenuItem>
                  <MenuItem value={4}>Abril</MenuItem>
                  <MenuItem value={5}>Mayo</MenuItem>
                  <MenuItem value={6}>Junio</MenuItem>
                  <MenuItem value={7}>Julio</MenuItem>
                  <MenuItem value={8}>Agosto</MenuItem>
                  <MenuItem value={9}>Septiembre</MenuItem>
                  <MenuItem value={10}>Octubre</MenuItem>
                  <MenuItem value={11}>Noviembre</MenuItem>
                  <MenuItem value={12}>Diciembre</MenuItem>
                </Select>
                {formik.errors.mes_operativo &&
                  formik.touched.mes_operativo && (
                    <FormHelperText
                      error={
                        formik.errors.mes_operativo &&
                        formik.touched.mes_operativo
                      }
                    >
                      {formik.errors?.mes_operativo}
                    </FormHelperText>
                  )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <InputLabel id="operacion_tipo">Tipo de Operación</InputLabel>
                <Select
                  id="operacion_tipo"
                  name="operacion_tipo"
                  onChange={formik.handleChange}
                  value={formik.values.operacion_tipo}
                  error={
                    formik.errors.operacion_tipo &&
                    formik.touched.operacion_tipo
                  }
                >
                  <MenuItem value={null}>Seleccione una operación</MenuItem>
                  {[...new Set(cargas.map((carga) => carga.tipo))].map(
                    (tipo) => (
                      <MenuItem key={tipo} value={tipo}>
                        {tipo}
                      </MenuItem>
                    )
                  )}
                </Select>
                {formik.errors.operacion_tipo &&
                  formik.touched.operacion_tipo && (
                    <FormHelperText
                      error={
                        formik.errors.operacion_tipo &&
                        formik.touched.operacion_tipo
                      }
                    >
                      {formik.errors?.operacion_tipo}
                    </FormHelperText>
                  )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <InputLabel id="entrada">Movimiento</InputLabel>
                <Select
                  id="entrada"
                  name="entrada"
                  onChange={formik.handleChange}
                  value={formik.values.entrada}
                  error={formik.errors.entrada && formik.touched.entrada}
                >
                  <MenuItem value={null}>Seleccione un movimiento</MenuItem>
                  <MenuItem value={true}>Entrada</MenuItem>
                  <MenuItem value={false}>Salida</MenuItem>
                </Select>
                {formik.errors.entrada && formik.touched.entrada && (
                  <FormHelperText
                    error={formik.errors.entrada && formik.touched.entrada}
                  >
                    {formik.errors?.entrada}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <InputLabel id="chasis_patente">Chasis/Patente</InputLabel>
                <Select
                  id="chasis_patente"
                  name="chasis_patente"
                  onChange={formik.handleChange}
                  value={formik.values.chasis_patente}
                  error={
                    formik.errors.chasis_patente &&
                    formik.touched.chasis_patente
                  }
                >
                  <MenuItem value={null}>Seleccione un chasis/patente</MenuItem>
                  {chasis?.map((chasis) => (
                    <MenuItem key={chasis.patente} value={chasis.patente}>
                      {chasis.patente}
                    </MenuItem>
                  ))}
                </Select>
                {formik.errors.chasis_patente &&
                  formik.touched.chasis_patente && (
                    <FormHelperText
                      error={
                        formik.errors.chasis_patente &&
                        formik.touched.chasis_patente
                      }
                    >
                      {formik.errors?.chasis_patente}
                    </FormHelperText>
                  )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <InputLabel id="chofer_id">Chofer</InputLabel>
                <Select
                  id="chofer_id"
                  name="chofer_id"
                  onChange={formik.handleChange}
                  value={formik.values.chofer_id}
                  error={formik.errors.chofer_id && formik.touched.chofer_id}
                >
                  <MenuItem value={null}>Seleccione un chofer</MenuItem>
                  {choferes?.map((chofer) => (
                    <MenuItem
                      key={chofer.nombre_apellido}
                      value={chofer.nombre_apellido}
                    >
                      {chofer.nombre_apellido}
                    </MenuItem>
                  ))}
                </Select>
                {formik.errors.chofer_id && formik.touched.chofer_id && (
                  <FormHelperText
                    error={formik.errors.chofer_id && formik.touched.chofer_id}
                  >
                    {formik.errors?.chofer_id}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container md={12} sm={8} justify="center">
            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  type="button"
                  textArea="Cancelar" /* Acà se puede validar el tipo de boton */
                  onClick={() => handleClose()}
                  variant="outlined"
                  color="primary"
                  minwidth="true"
                />
              </ButtonContainer>
            </Grid>
            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  // disabled={validated}
                  type="submit"
                  textArea="Descargar"
                  variant="contained"
                  color="primary"
                  mright="true"
                />
              </ButtonContainer>
            </Grid>
          </Grid>
        </StyledForm>
      </ModalWrapper>
    </Background>,
    document.getElementById("root")
  );
};
