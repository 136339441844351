import {
  GET_OPERACIONES_FINALIZABLES,
  GET_OPERACIONES_FINALIZABLES_MANUAL,
  GET_OPERACIONES_ABIERTAS,
  GET_OPERACIONES_CERRADAS,
  GET_OPERACIONES_OFFLINE,
  GET_OPERACIONES_FALLIDAS,
} from "../../constants/actionsTypes";

const initialState = {};
const initialStateArray = [];

export const operacionesFinalizablesReducer = (
  state = initialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_OPERACIONES_FINALIZABLES: {
      return { ...state, ...payload };
    }
    default:
      return state;
  }
};

export const operacionesFinalizablesManualReducer = (
  state = initialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_OPERACIONES_FINALIZABLES_MANUAL: {
      return { ...state, ...payload };
    }
    default:
      return state;
  }
};

export const operacionesAbiertasReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_OPERACIONES_ABIERTAS: {
      return { ...state, ...payload };
    }
    default:
      return state;
  }
};

export const operacionesCerradasReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_OPERACIONES_CERRADAS: {
      return { ...state, ...payload };
    }
    default:
      return state;
  }
};

export const getOperacionesOfflineReducer = (
  state = initialStateArray,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_OPERACIONES_OFFLINE: {
      return payload; // Retorna directamente el array
    }
    default:
      return state;
  }
};

export const getOperacionesFallidasReducer = (
  state = initialStateArray,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_OPERACIONES_FALLIDAS: {
      return { ...state, ...payload };
    }
    default:
      return state;
  }
};
