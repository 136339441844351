import {
  GET_TIPOS_TALONARIOS,
  GET_TIPOSASOCIABLES_TALONARIOS,
} from "../../constants/actionsTypes";

const initialState = {};

export const talonarioTiposReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TIPOS_TALONARIOS: {
      return { ...state, ...payload };
    }

    default:
      return state;
  }
};

export const talonarioTiposAsociablesReducer = (
  state = initialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TIPOSASOCIABLES_TALONARIOS: {
      return { ...state, ...payload };
    }

    default:
      return state;
  }
};
