import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import { Box, Toltip, SpanTitle } from "./Styles";
import { Container } from "react-bootstrap";
import { Box as Boxx } from "@material-ui/core";

/* Imagenes SVG */
import { ReactComponent as IconPad } from "../../assets/img/LogoPad.svg";
import { ReactComponent as LogoPlantaCarga } from "../../assets/img/LogoPlantaCarga.svg";
import { ReactComponent as LogoCamionLleno } from "../../assets/img/LogoCamionLleno.svg";
import { ReactComponent as LogoCamionVacio } from "../../assets/img/LogoCamionVacio.svg";
import { ReactComponent as LogoAlertaMedia } from "../../assets/img/alertaMedia.svg";
import { ReactComponent as LogoAlertaAlta } from "../../assets/img/alertaAlta.svg";

/* Componentes */
import {
  Tooltip as TooltipEmpty,
  TooltipCamiones,
  TooltipPad,
  TooltipPlanta,
} from "../Camiones/TooltipCamiones/TooltipCamiones";
// import { estadoCamiones } from "../../Services";

function Map() {
  const [isOpenPad, setIsOpenPad] = useState(null);
  const [isOpenPlantas, setIsOpenPlantas] = useState(null);
  const [isOpenCamionesPad, setIsOpenCamionesPad] = useState(null);
  const [isOpenCamionesVacios, setIsOpenCamionesVacios] = useState(null);
  const [isOpenCamionesCargaPlanta, setIsOpenCamionesCargaPlanta] =
    useState(null);
  const [isOpenCamionesLlenos, setIsOpenCamionesLlenos] = useState(null);

  const { pad, plantas, camiones } = useSelector(
    (state) => state.estadoCamiones
  );

  useEffect(() => {
    const listener = (e) => {
      if (e.key === "Escape") {
        setIsOpenPad(null);
        setIsOpenPlantas(null);
        setIsOpenCamionesPad(null);
        setIsOpenCamionesVacios(null);
        setIsOpenCamionesCargaPlanta(null);
        setIsOpenCamionesLlenos(null);
      }
    };
    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, []);

  const MAP_SETTINGS = {
    DEFAULT_MAP_OPTIONS: {
      scrollwheel: true,
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
    },
    DIRECTIONS_OPTIONS: { suppressMarkers: true, preserveViewport: true },
  };

  // const transitoVacioShoOff = () => {
  //   return (

  //   );
  // };

  return (
    <GoogleMap
      defaultZoom={10}
      defaultCenter={{ lat: -38.587693581747746, lng: -68.48344397827499 }}
      defaultOptions={MAP_SETTINGS.DEFAULT_MAP_OPTIONS}
      className="google-map-home"
    >
      {/* Pad */}
      {pad !== undefined && (
        <Marker
          position={{ lat: pad?.lat, lng: pad?.lng }}
          onClick={() => {
            setIsOpenPad(pad);
          }}
          icon={{
            url: require(`../../assets/img/PAD${pad.alerta.prioridad}.png`)
              .default,
            scaledSize: new window.google.maps.Size(32, 32),
          }}
        />
      )}
      {isOpenPad && (
        <InfoWindow
          onCloseClick={() => {
            setIsOpenPad(null);
          }}
          position={{ lat: isOpenPad.lat, lng: isOpenPad.lng }}
        >
          <Toltip>
            <TooltipPad nombre="PAD" {...isOpenPad} />
          </Toltip>
        </InfoWindow>
      )}

      {/* Plantas */}
      {plantas &&
        plantas.map((planta, index) => {
          return (
            planta.status !== "inactivo" && (
              <Marker
                key={index}
                position={{ lat: planta.lat, lng: planta.lng }}
                onClick={() => {
                  setIsOpenPlantas(planta);
                }}
                icon={{
                  url: require(`../../assets/img/Planta${planta.alerta.prioridad}.png`)
                    .default,
                  scaledSize: new window.google.maps.Size(32, 32),
                }}
              />
            )
          );
        })}
      {isOpenPlantas && (
        <InfoWindow
          onCloseClick={() => {
            setIsOpenPlantas(null);
          }}
          position={{ lat: isOpenPlantas.lat, lng: isOpenPlantas.lng }}
        >
          <Toltip>
            <TooltipPlanta {...isOpenPlantas} />
          </Toltip>
        </InfoWindow>
      )}

      {/* camiones en pad*/}
      {camiones &&
        camiones.camiones_pad.camiones.map((camionPad, index) => {
          return camionPad.showCar ? (
            <Marker
              key={index}
              position={{ lat: camionPad.lat, lng: camionPad.lng }}
              onClick={() => {
                setIsOpenCamionesPad(camionPad);
              }}
              icon={{
                url: require(`../../assets/img/CamionVacioPad${camionPad.alerta.prioridad}.png`)
                  .default,
                scaledSize: new window.google.maps.Size(37, 37),
              }}
            />
          ) : null;
        })}

      {isOpenCamionesPad && (
        <InfoWindow
          onCloseClick={() => {
            setIsOpenCamionesPad(null);
          }}
          position={{ lat: isOpenCamionesPad.lat, lng: isOpenCamionesPad.lng }}
        >
          <Toltip>
            <TooltipEmpty {...isOpenCamionesPad} />
          </Toltip>
        </InfoWindow>
      )}

      {/* camiones en transito vacio*/}
      {camiones &&
        camiones.camiones_transito_vacio?.camiones.map((camionVacio, index) =>
          camionVacio.showCar ? (
            <Marker
              key={index}
              position={{ lat: camionVacio.lat, lng: camionVacio.lng }}
              onClick={() => {
                setIsOpenCamionesVacios(camionVacio);
              }}
              icon={{
                url: require(`../../assets/img/CamionVacioPad${camionVacio.alerta.prioridad}.png`)
                  .default,
                scaledSize: new window.google.maps.Size(37, 37),
              }}
            />
          ) : null
        )}
      {isOpenCamionesVacios && (
        <InfoWindow
          onCloseClick={() => {
            setIsOpenCamionesVacios(null);
          }}
          position={{
            lat: isOpenCamionesVacios.lat,
            lng: isOpenCamionesVacios.lng,
          }}
        >
          <Toltip>
            <TooltipCamiones {...isOpenCamionesVacios} />
          </Toltip>
        </InfoWindow>
      )}

      {/* camiones carga en planta*/}
      {camiones &&
        camiones.camiones_carga_planta?.camiones.map(
          (camionCargaPlanta, index) =>
            camionCargaPlanta.showCar ? (
              <Marker
                key={index}
                position={{
                  lat: camionCargaPlanta.lat,
                  lng: camionCargaPlanta.lng,
                }}
                onClick={() => {
                  setIsOpenCamionesCargaPlanta(camionCargaPlanta);
                }}
                icon={{
                  url: require(`../../assets/img/CamionVacioPad${camionCargaPlanta.alerta.prioridad}.png`)
                    .default,
                  scaledSize: new window.google.maps.Size(37, 37),
                }}
              />
            ) : null
        )}
      {isOpenCamionesCargaPlanta && (
        <InfoWindow
          onCloseClick={() => {
            setIsOpenCamionesCargaPlanta(null);
          }}
          position={{
            lat: isOpenCamionesCargaPlanta.lat,
            lng: isOpenCamionesCargaPlanta.lng,
          }}
        >
          <Toltip>
            <TooltipCamiones {...isOpenCamionesCargaPlanta} />
          </Toltip>
        </InfoWindow>
      )}

      {/* camiones en transito llenos*/}
      {camiones &&
        camiones.camiones_transito_lleno?.camiones.map((camionLleno, index) =>
          camionLleno.showCar ? (
            <Marker
              key={index}
              position={{ lat: camionLleno.lat, lng: camionLleno.lng }}
              onClick={() => {
                setIsOpenCamionesLlenos(camionLleno);
              }}
              icon={{
                url: require(`../../assets/img/CamionLleno${camionLleno.alerta.prioridad}.png`)
                  .default,
                scaledSize: new window.google.maps.Size(37, 37),
              }}
            />
          ) : null
        )}
      {isOpenCamionesLlenos && (
        <InfoWindow
          onCloseClick={() => {
            setIsOpenCamionesLlenos(null);
          }}
          position={{
            lat: isOpenCamionesLlenos.lat,
            lng: isOpenCamionesLlenos.lng,
          }}
        >
          <Toltip>
            <TooltipCamiones {...isOpenCamionesLlenos} />
          </Toltip>
        </InfoWindow>
      )}
    </GoogleMap>
  );
}

export const Map2 = () => {
  const keyMapGoogle = useSelector((state) => state.keyMapGoogle.status);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Cuando la clave de Google Maps esté disponible, marca el estado como no cargando
    if (keyMapGoogle) {
      setLoading(false);
    }
  }, [keyMapGoogle]);
  const WrappedMap = withScriptjs(withGoogleMap(Map));

  return (
    <div
      style={{ width: "90%", height: "calc(100vh - 68px)", margin: "0 auto" }}
    >
      <Container id="--ssss" style={{ margin: "10px 0px", padding: "0px" }}>
        <Boxx display="flex" alignItems="center">
          <Boxx display="flex" alignItems="center" mr={6}>
            <Boxx mr="5px">
              {" "}
              <IconPad />{" "}
            </Boxx>
            <SpanTitle> Pad </SpanTitle>
          </Boxx>
          <Boxx display="flex" alignItems="center" mr={6}>
            <Boxx mr="5px">
              {" "}
              <LogoPlantaCarga />{" "}
            </Boxx>
            <SpanTitle> Planta de carga </SpanTitle>
          </Boxx>
          <Boxx display="flex" alignItems="center" mr={6}>
            <Boxx mr="5px">
              {" "}
              <LogoCamionLleno />{" "}
            </Boxx>
            <SpanTitle> Camión lleno </SpanTitle>
          </Boxx>
          <Boxx display="flex" alignItems="center" mr={6}>
            <Boxx mr="5px">
              {" "}
              <LogoCamionVacio />{" "}
            </Boxx>
            <SpanTitle> Camión vacío </SpanTitle>
          </Boxx>
          <Boxx display="flex" alignItems="center" mr={6}>
            <Boxx mr="5px">
              {" "}
              <LogoAlertaMedia />{" "}
            </Boxx>
            <SpanTitle> Alerta media </SpanTitle>
          </Boxx>
          <Boxx display="flex" alignItems="center" mr={6}>
            <Boxx mr="5px">
              {" "}
              <LogoAlertaAlta />{" "}
            </Boxx>
            <SpanTitle> Alerta alta </SpanTitle>
          </Boxx>
        </Boxx>
      </Container>
      {loading ? (
        <div>Cargando el mapa...</div>
      ) : (
        <WrappedMap
          // googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyC4R6AN7SmujjPUIGKdyao2Kqitzr1kiRg&v=3.exp&libraries=geometry,drawing,places&key=${keyMapGoogle}`}
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${keyMapGoogle}&v=3.exp&libraries=geometry,drawing,places`}
          loadingElement={
            <Box className="grid-google-map-home" style={{ height: `100%` }} />
          }
          containerElement={
            <Box
              className="grid-google-map-home"
              style={{ height: `80vh`, border: "1px solid black" }}
            />
          }
          mapElement={<div style={{ height: `100%` }} />}
        />
      )}
    </div>
  );
};
