import React from "react";
import { Input } from "./Styles";
import "./style.css";

export const Inputs = ({
  autoComplete,
  label,
  name,
  onChange,
  onBlur,
  value,
  type,
  error,
  InputProps,
  shrink,
  variant,
  select,
  SelectProps,
  id,
  maxLength,
  disabled,
}) => {
  return (
    <Input
      // className="InputBase"
      autoComplete={autoComplete}
      label={label}
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      type={type}
      error={error}
      variant={variant}
      InputProps={InputProps}
      select={select}
      SelectProps={SelectProps}
      id={id}
      disabled={disabled}
      InputLabelProps={{
        shrink: shrink,
      }}
      inputProps={{
        maxLength: maxLength,
      }}
    />
  );
};
