import { combineReducers } from "redux";

/* Reducers */
import { loginReducer } from "./loginReducer";
import { responseReducer } from "./responseReducer";
import { indicadoresReducer } from "./indicadoresReducer";
import { estadoCamionesReducer } from "./estadoCamionesReducer";
import { recomendadorReducer } from "./recomendadorReducer";
import { consultarEtapasReducer } from "./consultarEtapasReducer";
import { alertasReducer } from "./alertasReducer";
import { infoPlantasReducer } from "./infoPlantasReducer";
import { deshabilitarCamionReducer } from "./deshabilitarCamionReducer";
import { loadingReducer } from "./loadingReducer";
import { plantasReducer } from "./plantasReducer";
import { camionesInactivosReducer } from "./camionesInactivosReducer";
import { plantasInactivosReducer } from "./plantasInactivosReducer";
import { InfoPadReducer } from "./infoPadReducer";
import { escenariosReducer } from "./escenariosReducer";
import { estadortReducer } from "./estadortReducer";
import { SidebarReducer } from "./sidebarReducer";
import { CamionesHistoricosReducer } from "./camionesHistoricosReducer";
import { plantasHistoricasReducer } from "./plantasHistoricasReducer";
import { indicadoresClsReducer } from "./cls/indicadoresClsReducer";
import { planDespachoClsReducer } from "./cls/planDespachoClsReducer";
import { estadoCamionesClsReducer } from "./cls/estadoCamionesClsReducer";
import { alertasClsReducer } from "./cls/alertasClsReducer";
import { infoPlantasClsReducer } from "./cls/infoPlantasClsReducer";
import { periodoFacturacionClsReducer } from "./cls/periodoFacturacionClsReducer";
import { googleMapsReducer } from "./googleMapsReducer";
import { balanzaClsReducer } from "./balanza/balanzaClsReducer";
import { cargasReducer } from "./balanza/cargasReducer";
import { choferesReducer } from "./balanza/choferesReducer";
import { destinosReducer, lugaresReducer } from "./balanza/lugaresReducer";
import { origenesReducer } from "./balanza/lugaresReducer";
import { transportistasReducer } from "./balanza/transportistasReducer";
import { operacionesFinalizablesManualReducer } from "./balanza/operacionesFinalizablesReducer";
import { ultimasOperacionesReducer } from "./balanza/ultimasOperacionesReducer";
import { operacionIdReducer } from "./balanza/operacionIdReducer";
import { operacionesFinalizablesReducer } from "./balanza/operacionesFinalizablesReducer";
import { talonarioTiposReducer } from "./balanza/talonarioTiposReducer";
import { talonarioTiposAsociablesReducer } from "./balanza/talonarioTiposReducer";
import { chasisReducer } from "./balanza/chasisReducer";
import { acopladosReducer } from "./balanza/acopladosReducer";
import { operacionesAbiertasReducer } from "./balanza/operacionesFinalizablesReducer";
import { operacionesCerradasReducer } from "./balanza/operacionesFinalizablesReducer";
import { partesReducer } from "./balanza/partesReducer";
import { operacionesTodasReducer } from "./balanza/ultimasOperacionesReducer";
import { getOperacionesOfflineReducer } from "./balanza/operacionesFinalizablesReducer";
import { getOperacionesFallidasReducer } from "./balanza/operacionesFinalizablesReducer";

const appReducer = combineReducers({
  dataUser: loginReducer,
  response: responseReducer,
  indicadores: indicadoresReducer,
  estadoCamiones: estadoCamionesReducer,
  recomendador: recomendadorReducer,
  consultarEtapas: consultarEtapasReducer,
  alertas: alertasReducer,
  infoPlantas: infoPlantasReducer,
  infoPad: InfoPadReducer,
  deshabilitarCamion: deshabilitarCamionReducer,
  plantas: plantasReducer,
  camionesInactivos: camionesInactivosReducer,
  plantasInactivos: plantasInactivosReducer,
  loading: loadingReducer,
  escenarios: escenariosReducer,
  estadoRT: estadortReducer,
  sidebar: SidebarReducer,
  camionesHistoricos: CamionesHistoricosReducer,
  plantasHistoricas: plantasHistoricasReducer,
  indicadoresCls: indicadoresClsReducer,
  planDespachoCls: planDespachoClsReducer,
  estadoCamionesCls: estadoCamionesClsReducer,
  alertasCls: alertasClsReducer,
  infoPlantasCls: infoPlantasClsReducer,
  periodoFacturacionCls: periodoFacturacionClsReducer,
  keyMapGoogle: googleMapsReducer,
  balanza: balanzaClsReducer,
  cargas: cargasReducer,
  choferes: choferesReducer,
  destinos: destinosReducer,
  origenes: origenesReducer,
  transportistas: transportistasReducer,
  operaciones_iniciadas: operacionesFinalizablesReducer,
  operaciones_iniciadas_manual: operacionesFinalizablesManualReducer,
  ultimosViajes: ultimasOperacionesReducer,
  operacionId: operacionIdReducer,
  talonarioTipos: talonarioTiposReducer,
  talonarioTiposAsociables: talonarioTiposAsociablesReducer,
  chasis: chasisReducer,
  acoplados: acopladosReducer,

  operaciones: operacionesTodasReducer,
  operaciones_abiertas: operacionesAbiertasReducer,
  operaciones_cerradas: operacionesCerradasReducer,
  parte_diario: partesReducer,
  lugares: lugaresReducer,
  operaciones_offline: getOperacionesOfflineReducer,
  operaciones_fallidas: getOperacionesFallidasReducer,
});

export default appReducer;
