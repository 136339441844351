import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Grid } from "@material-ui/core";
import { FormControl, FormHelperText } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {
  Background,
  ModalWrapper,
  Head,
  IconContainer,
  ButtonContainer,
  StyledForm,
} from "./Styles";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

/* Componentes */
import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";

/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Inputs } from "../../Input/Inputs";
import {
  transferirTalonario,
  getTalonarioTiposAsociables,
  getChoferes,
} from "../../../Services";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  numero: yup.number().required("Campo requerido"),
  tipo_id: yup.string().required("Campo requerido"),
  chofer_id: yup.string(),
  status: yup.string().required("Campo requerido"),
});

export const ModalEditarTalonario = ({ onClose }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTalonarioTiposAsociables());
    dispatch(getChoferes());
  }, [dispatch]);
  const { choferes } = useSelector((state) => state.choferes);
  const { tipos } = useSelector((state) => state.talonarioTiposAsociables);
  const formik = useFormik({
    initialValues: {
      numero: null,
      tipo_id: "",
      chofer_id: "",
      status: "",
    },

    validationSchema: validationSchema,

    onSubmit: (values) => {
      const choferId = choferes.find(
        (chofer) => `${chofer.nombre} ${chofer.apellido}` === values.chofer_id
      )?.id;
      const talonarioId = tipos.find(
        (tipo) => tipo.nombre === values.tipo_id
      )?.id;
      const updatedValues = {
        ...values,
        chofer_id: choferId,
        tipo_id: talonarioId,
      };
      dispatch(transferirTalonario(updatedValues));
      onClose();
    },
  });
  const handleClose = () => {
    onClose();
  };
  const SelectInput = ({
    label,
    name,
    value,
    onChange,
    options,
    error,
    disabled,
  }) => {
    const handleSelectChange = (event, selectedOption) => {
      onChange({
        target: {
          name,
          value: selectedOption ? selectedOption.value : "",
        },
      });
    };

    return (
      <FormControl fullWidth>
        <Autocomplete
          options={options}
          getOptionLabel={(option) => option.label}
          value={options.find((option) => option.value === value) || null}
          onChange={handleSelectChange}
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={error}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  };
  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper onClose={() => handleClose()}>
        <Head>
          <p>Transferir talonario a otro chofer</p>
          <IconContainer>
            <FontAwesomeIcon icon={faTimes} onClick={onClose} />
          </IconContainer>
        </Head>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid container md={12} sm={8} justify="center">
            <Inputs
              label="Numero de talonario"
              name="numero"
              type="number"
              value={formik.values.numero}
              onChange={formik.handleChange}
              error={formik.errors.numero && formik.touched.numero}
            />
            <SelectInput
              label="Tipo de talonario"
              name="tipo_id"
              value={formik.values.tipo_id}
              onChange={formik.handleChange}
              options={tipos.map((tipo) => ({
                value: tipo.nombre,
                label: tipo.nombre,
              }))}
              error={formik.errors.tipo_id && formik.touched.tipo_id}
            />
            <SelectInput
              label="Chofer"
              name="chofer_id"
              value={formik.values.chofer_id}
              onChange={formik.handleChange}
              options={choferes.map((chofer) => ({
                value: `${chofer.nombre} ${chofer.apellido}`,
                label: `${chofer.nombre} ${chofer.apellido}`,
              }))}
              error={formik.errors.chofer_id && formik.touched.chofer_id}
            />
            <SelectInput
              label="Estado"
              name="status"
              value={formik.values.status}
              onChange={formik.handleChange}
              options={[
                { value: "En stock", label: "En stock" },
                {
                  value: "Asociado a un chofer",
                  label: "Asociado a un chofer",
                },
              ]}
              error={formik.errors.status && formik.touched.status}
            />

            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  type="button"
                  textArea="Cancelar" /* Acà se puede validar el tipo de boton */
                  onClick={() => handleClose()}
                  variant="outlined"
                  color="primary"
                  minwidth="true"
                />
              </ButtonContainer>
            </Grid>
            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  // disabled={validated}
                  type="submit"
                  textArea="Transferir"
                  variant="contained"
                  color="primary"
                  mright="true"
                />
              </ButtonContainer>
            </Grid>
          </Grid>
        </StyledForm>
      </ModalWrapper>
    </Background>,
    document.getElementById("root")
  );
};
