import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Container,
  Menu,
  Cabecera,
  NameSelect,
  Options,
  ListItemAnchor,
  Title,
  CategoryTitle,
  AccordionContainer,
  AccordionSummaryContainer,
  AccordionDetailsContainer,
  ListContainer,
} from "./Styles";
import { descargarReporteDiario } from "../../../Services";

export const OptionsRight = ({
  setShowModalRestartTurn,
  setShowModalParteQuincenal,
  setShowModalParteMineria,
  setShowModalParteCompleto,
  setShowModalParteTransportistas,
  setShowModalCrearParteDiario,
  setShowModalEditarParteDiario,
  setShowModalEnviarParteDiario,
  setShowModalVerPartesDiarios,
}) => {
  const dispatch = useDispatch();
  const [expandedCategory, setExpandedCategory] = useState(null);
  const handleCategoryChange = (category) => (_, isExpanded) => {
    setExpandedCategory(isExpanded ? category : null);
  };
  const closeMenuParteQuincenal = () => {
    setShowModalParteQuincenal(true);
  };
  const closeMenuParteMineria = () => {
    setShowModalParteMineria(true);
  };
  const closeMenuParteCompleto = () => {
    setShowModalParteCompleto(true);
  };
  const closeMenuParteTransportistas = () => {
    setShowModalParteTransportistas(true);
  };
  const closeMenuCrearParteDiario = () => {
    setShowModalCrearParteDiario(true);
  };
  const closeMenuEditarParteDiario = () => {
    setShowModalEditarParteDiario(true);
  };
  const closeMenuEnviarParteDiario = () => {
    setShowModalEnviarParteDiario(true);
  };
  const closeMenuVerPartesDiarios = () => {
    setShowModalVerPartesDiarios(true);
  };
  const closeRestartTurnConfirm = () => {
    setShowModalRestartTurn(true);
  }

  return (
    <div>
      <Container container xs={12} md={3} className="open768">
        <Menu>
          <Cabecera>
            <NameSelect>Partes</NameSelect>
          </Cabecera>
        </Menu>
        <Options className="open">
          <ListItemAnchor
            onClick={closeMenuCrearParteDiario}
            style={{ marginLeft: "-15px" }}
          >
            <Title>Iniciar turno</Title>
          </ListItemAnchor>
          <ListItemAnchor
            onClick={closeRestartTurnConfirm}
            style={{ marginLeft: "-15px" }}
          >
            <Title>Reiniciar Turno</Title>
          </ListItemAnchor>
          <AccordionContainer
            expanded={expandedCategory === "diario"}
            onChange={handleCategoryChange("diario")}
          >
            <AccordionSummaryContainer expandIcon={<ExpandMoreIcon />}>
              <CategoryTitle>Parte Diario</CategoryTitle>
            </AccordionSummaryContainer>
            <AccordionDetailsContainer>
              <ListContainer>
                <ListItemAnchor onClick={closeMenuVerPartesDiarios}>
                  <Title>Consultar Partes Diarios Históricos</Title>
                </ListItemAnchor>
                <ListItemAnchor onClick={closeMenuEditarParteDiario}>
                  <Title>Editar Parte Diario</Title>
                </ListItemAnchor>
                <ListItemAnchor
                  onClick={() => dispatch(descargarReporteDiario())}
                >
                  <Title>Previsualizar Parte Diario</Title>
                </ListItemAnchor>
                <ListItemAnchor onClick={closeMenuEnviarParteDiario}>
                  <Title>Enviar Parte Diario</Title>
                </ListItemAnchor>
              </ListContainer>
            </AccordionDetailsContainer>
          </AccordionContainer>
          <AccordionContainer
            expanded={expandedCategory === "quincenal"}
            onChange={handleCategoryChange("quincenal")}
          >
            <AccordionSummaryContainer expandIcon={<ExpandMoreIcon />}>
              <CategoryTitle>Parte Quincenal</CategoryTitle>
            </AccordionSummaryContainer>
            <AccordionDetailsContainer>
              <ListContainer>
                {/* <ListItemAnchor onClick={closeMenuPeriodoFacturacion}>
                  <Title>Editar Parte Quincenal</Title>
                </ListItemAnchor> */}
                <ListItemAnchor onClick={closeMenuParteQuincenal}>
                  <Title>Previsualizar Parte Quincenal</Title>
                </ListItemAnchor>
              </ListContainer>
            </AccordionDetailsContainer>
          </AccordionContainer>
          <AccordionContainer
            expanded={expandedCategory === "completo"}
            onChange={handleCategoryChange("completo")}
          >
            <AccordionSummaryContainer expandIcon={<ExpandMoreIcon />}>
              <CategoryTitle>Parte Completo</CategoryTitle>
            </AccordionSummaryContainer>
            <AccordionDetailsContainer>
              <ListContainer>
                <ListItemAnchor onClick={closeMenuParteCompleto}>
                  <Title>Previsualizar Parte Completo</Title>
                </ListItemAnchor>
              </ListContainer>
            </AccordionDetailsContainer>
          </AccordionContainer>
          <AccordionContainer
            expanded={expandedCategory === "mineria"}
            onChange={handleCategoryChange("mineria")}
          >
            <AccordionSummaryContainer expandIcon={<ExpandMoreIcon />}>
              <CategoryTitle>Parte de Minería</CategoryTitle>
            </AccordionSummaryContainer>
            <AccordionDetailsContainer>
              <ListContainer>
                <ListItemAnchor onClick={closeMenuParteMineria}>
                  <Title>Previsualizar Parte de Minería</Title>
                </ListItemAnchor>
              </ListContainer>
            </AccordionDetailsContainer>
          </AccordionContainer>
          <AccordionContainer
            expanded={expandedCategory === "transportistas"}
            onChange={handleCategoryChange("transportistas")}
          >
            <AccordionSummaryContainer expandIcon={<ExpandMoreIcon />}>
              <CategoryTitle>Parte de Transportistas</CategoryTitle>
            </AccordionSummaryContainer>
            <AccordionDetailsContainer>
              <ListContainer>
                <ListItemAnchor onClick={closeMenuParteTransportistas}>
                  <Title>Previsualizar Parte de Transportistas</Title>
                </ListItemAnchor>
              </ListContainer>
            </AccordionDetailsContainer>
          </AccordionContainer>
        </Options>
      </Container>
    </div>
  );
};
