import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { getBalanza } from "../../../Services";
import { Buton, Container, TableContainer, Title } from "./Styles";
import {
  Grid,
  Table,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#C8C8C8",
    color: "#000",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export const BalanzaComponent = ({
  setShowModalViajes,
  setShowModalViajesIniciados,
  setSelectedPesaje,
}) => {
  const { balanza } = useSelector((state) => state.balanza);
  const dispatch = useDispatch();
  const closeMenuViajes = (pesaje) => {
    setShowModalViajes(true);
    setSelectedPesaje(pesaje);
  };
  const closeMenuViajesIniciados = (pesaje) => {
    setShowModalViajesIniciados(true);
    setSelectedPesaje(pesaje);
  };

  useEffect(() => {
    dispatch(getBalanza());
  }, []);

  // Ordenar balanza por id de menor a mayor
  const sortedBalanza = balanza
    ?.slice()
    .sort((a, b) => b.id - a.id)
    .slice(0, 10);

  return (
    <Container>
      <Grid item md={12}></Grid>
      <TableContainer component={Paper}>
        <Grid item md={12}>
          <Title>Seleccionar pesaje</Title>
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Hora</StyledTableCell>
              <StyledTableCell>Fecha</StyledTableCell>
              <StyledTableCell>Peso</StyledTableCell>
              <StyledTableCell>Balanza</StyledTableCell>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedBalanza?.map((pesaje) => (
              <StyledTableRow key={pesaje.id}>
                <StyledTableCell>{pesaje.hora}</StyledTableCell>
                <StyledTableCell>{pesaje.fecha}</StyledTableCell>
                <StyledTableCell>{pesaje.peso.toFixed(2)} Tn.</StyledTableCell>
                <StyledTableCell>{pesaje.tipo}</StyledTableCell>
                <StyledTableCell>{pesaje.id}</StyledTableCell>
                {pesaje.tipo === "Entrada" ? (
                  <StyledTableCell>
                    <Buton onClick={() => closeMenuViajes(pesaje)}>
                      Asignar Patente
                    </Buton>
                  </StyledTableCell>
                ) : (
                  <StyledTableCell>
                    <Buton onClick={() => closeMenuViajesIniciados(pesaje)}>
                      Asignar Patente
                    </Buton>
                  </StyledTableCell>
                )}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};
