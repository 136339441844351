import React from "react";
import ReactDOM from "react-dom";
import {
  Grid,
  FormHelperText,
  InputLabel,
  Input,
  FormControl,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {
  Background,
  ModalWrapper,
  Head,
  IconContainer,
  FormGroup,
  ButtonContainer,
  GridItem,
  StyledForm,
} from "./Styles";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

/* Componentes */
import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";

/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { enviarReporteDiario } from "../../../Services";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  recipients: yup
    .array()
    .required("Debe seleccionar al menos un destinatario")
    .transform((value) => (value === null ? [] : value)), // Transform null to an empty array
  fecha: yup.string().required("La fecha de inicio es requerida"),
  turno: yup.string().required("La fecha de fin es requerida"),
});

export const ModalEnviarParteDiario = ({ onClose }) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      recipients: [],
      fecha: "dd/mm/aaaa",
      turno: "",
    },

    validationSchema: validationSchema,

    onSubmit: (values) => {
      console.log(values);
      dispatch(enviarReporteDiario(values));
      onClose();
    },
  });

  const handleClose = () => {
    onClose();
  };
  const SelectInput = ({
    label,
    name,
    value,
    onChange,
    options,
    error,
    disabled,
  }) => {
    const handleSelectChange = (event, selectedOption) => {
      onChange({
        target: {
          name,
          value: selectedOption ? selectedOption.value : "",
        },
      });
    };

    return (
      <FormControl fullWidth>
        <Autocomplete
          options={options}
          getOptionLabel={(option) => option.label}
          value={options?.find((option) => option.value === value) || null}
          onChange={handleSelectChange}
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={error}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  };

  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper onClose={() => handleClose()}>
        <Head>
          <p>Indique destinatarios para enviar el Parte</p>
          <IconContainer>
            <FontAwesomeIcon icon={faTimes} onClick={onClose} />
          </IconContainer>
        </Head>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <FormGroup>
                <InputLabel id="recipients">
                  Destinatarios (separados por comas)
                </InputLabel>
                <Input
                  id="recipients"
                  name="recipients"
                  placeholder="Ingrese correos separados por comas"
                  onChange={(e) => {
                    // Divide la cadena de correos electrónicos en un array
                    const emails = e.target.value.split(",");
                    formik.handleChange({
                      target: {
                        name: "recipients",
                        value: emails,
                      },
                    });
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.recipients.join(",")}
                  error={formik.errors.recipients && formik.touched.recipients}
                />
                {formik.errors.recipients && formik.touched.recipients && (
                  <FormHelperText
                    error={
                      formik.errors.recipients && formik.touched.recipients
                    }
                  >
                    {formik.errors?.recipients}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <InputLabel id="fecha">Fecha</InputLabel>
                <Input
                  id="fecha"
                  name="fecha"
                  type="date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fecha}
                  error={formik.errors.fecha && formik.touched.fecha}
                />
                {formik.errors.fecha && formik.touched.fecha && (
                  <FormHelperText
                    error={formik.errors.fecha && formik.touched.fecha}
                  >
                    {formik.errors?.fecha}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <SelectInput
                  label="Turno"
                  name="turno"
                  value={formik.values.turno}
                  onChange={formik.handleChange}
                  options={[
                    { label: "Día", value: "Día" },
                    { label: "Noche", value: "Noche" },
                  ]}
                  error={formik.errors.turno && formik.touched.turno}
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container md={12} sm={8} justify="center">
            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  type="button"
                  textArea="Cancelar"
                  onClick={() => handleClose()}
                  variant="outlined"
                  color="primary"
                  minwidth="true"
                />
              </ButtonContainer>
            </Grid>
            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  type="submit"
                  textArea="Enviar"
                  variant="contained"
                  color="primary"
                  mright="true"
                />
              </ButtonContainer>
            </Grid>
          </Grid>
        </StyledForm>
      </ModalWrapper>
    </Background>,
    document.getElementById("root")
  );
};
