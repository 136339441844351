import React from "react";
import ReactDOM from "react-dom";
import { Grid } from "@material-ui/core";
import { FormControl, FormHelperText } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {
  Background,
  ModalWrapper,
  Head,
  IconContainer,
  ButtonContainer,
  StyledForm,
} from "./Styles";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

/* Componentes */
import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";

/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Inputs } from "../../Input/Inputs";
import { editarChasis, getTransportistas, getChasis } from "../../../Services";

/* Validacion de inputs con Formik */
const validationSchema = yup.object({
  patente: yup.string().required("Campo requerido"),
  transportista_id: yup.string().required("Campo requerido"),
});

export const ModalEditarPatenteChasis = ({ onClose }) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getTransportistas());
    dispatch(getChasis());
  }, [dispatch]);
  const { transportistas } = useSelector((state) => state.transportistas);
  const { chasis } = useSelector((state) => state.chasis);
  const [selectedPatente, setSelectedPatente] = React.useState(null);
  const formik = useFormik({
    initialValues: {
      patente: "",
      transportista_id: "",
    },

    validationSchema: validationSchema,

    onSubmit: (values) => {
      const transportistaId = transportistas.find(
        (transportista) => transportista.nombre === values.transportista_id
      )?.id;
      const updatedValues = {
        ...values,
        transportista_id: transportistaId,
      };
      dispatch(editarChasis(updatedValues));
      onClose();
    },
  });
  const handleClose = () => {
    onClose();
  };
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    const selectedOption = chasis.find(
      (patente) => patente.patente === selectedValue
    );

    setSelectedPatente(selectedOption);

    formik.setValues((prevValues) => ({
      ...prevValues,
      patente: selectedValue,
      transportista_id: selectedOption?.transportista.nombre || "",
    }));
  };
  const SelectInput = ({
    label,
    name,
    value,
    onChange,
    options,
    error,
    disabled,
  }) => {
    const handleSelectChange = (event, selectedOption) => {
      onChange({
        target: {
          name,
          value: selectedOption ? selectedOption.value : "",
        },
      });
    };

    return (
      <FormControl fullWidth>
        <Autocomplete
          options={options}
          getOptionLabel={(option) => option.label}
          value={options.find((option) => option.value === value) || null}
          onChange={handleSelectChange}
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={error}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  };
  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper onClose={() => handleClose()}>
        <Head>
          <p>Editar una patente de un acoplado</p>
          <IconContainer>
            <FontAwesomeIcon icon={faTimes} onClick={onClose} />
          </IconContainer>
        </Head>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid container md={12} sm={8} justify="center">
            <SelectInput
              label="Patente"
              name="patente"
              value={formik.values.patente}
              onChange={handleSelectChange}
              error={formik.errors.patente && formik.touched.patente}
              options={chasis.map((patente) => ({
                value: patente.patente,
                label: patente.patente,
              }))}
            />
            {formik.errors.patente && formik.touched.patente && (
              <FormHelperText error={formik.errors.patente}>
                {formik.errors?.patente}
              </FormHelperText>
            )}

            <SelectInput
              label="Empresa Transportista"
              name="transportista_id"
              type="text"
              value={formik.values.transportista_id}
              onChange={formik.handleChange}
              error={
                formik.errors.transportista_id &&
                formik.touched.transportista_id
              }
              options={transportistas.map((transportista) => ({
                value: transportista.nombre,
                label: transportista.nombre,
              }))}
            />

            {formik.errors.nombre && formik.touched.nombre && (
              <FormHelperText error={formik.errors.nombre}>
                {formik.errors?.nombre}
              </FormHelperText>
            )}

            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  type="button"
                  textArea="Cancelar" /* Acà se puede validar el tipo de boton */
                  onClick={() => handleClose()}
                  variant="outlined"
                  color="primary"
                  minwidth="true"
                />
              </ButtonContainer>
            </Grid>
            <Grid item sm={4}>
              <ButtonContainer align="center">
                <ButtonPrimary
                  // disabled={validated}
                  type="submit"
                  textArea="Editar"
                  variant="contained"
                  color="primary"
                  mright="true"
                />
              </ButtonContainer>
            </Grid>
          </Grid>
        </StyledForm>
      </ModalWrapper>
    </Background>,
    document.getElementById("root")
  );
};
