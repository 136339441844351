import styled from "styled-components";
import { FormControl, Grid, Button } from "@material-ui/core";
import { Form } from "react-bootstrap";

export const Background = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Cambiar a flex-start para permitir el margin-top en el ModalWrapper */
  width: 100%;
  z-index: 1000;
  top: 0;
  bottom: 0;
`;

export const ModalWrapper = styled.div`
  background: #ffffff;
  border: 0.0625rem solid #e5e5e5;
  box-shadow: 0rem 1.25rem 2.5rem -0.75rem rgba(196, 196, 196, 0.35);
  box-sizing: border-box;
  padding: 1.25rem 1.875rem;
  width: calc(100vw - 75px);
  height: calc(100vh - 60px);
  overflow: hidden; /* Cambia overflow-y a hidden */
  z-index: 10;
  margin-left: 75px;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
`;

export const ModalContent = styled.div`
  overflow-y: auto;
  flex-grow: 1;
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.75rem;
  & p {
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0;
  }
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
`;

export const IconContainer = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: flex-end;
  & svg {
    font-size: 1.5rem;
    color: #4f4184;
  }
`;

export const FormGroup = styled(FormControl)`
  width: 100%;
  margin-bottom: 1rem;
`;

export const StyledForm = styled(Form)`
  width: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
  margin: 1.875rem auto 0 auto;
`;

export const Div = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Span = styled.span`
  font-weight: 600;
  margin: 5px 0;
`;

export const GridItem = styled(Grid)`
  display: flex;
  justify-content: ${(props) => (props.justify ? "flex-start" : "flex-end")};
  align-items: center;
`;

export const Buton = styled(Button)`
  && {
    font-family: "Roboto", sans-serif;
    color: var(--purplePrimary);
    border: 0.063rem solid var(--purplePrimary);
    border-radius: 5px;
    width: ${(props) => (props.width ? "11.188rem" : "6.25rem")};
    height: 2.5rem;
    text-transform: none;
    font-size: 14px;

    &:active:hover:not([disabled]) {
      border-color: var(--purplePrimary);
    }

    &:disabled {
      background-color: #c0dcf5;
      border-color: #c0dcf5;
      opacity: 1;
    }
  }
`;
