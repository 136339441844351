import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
  Grid,
  FormHelperText,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  FormControl,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import {
  Background,
  ModalWrapper,
  Head,
  IconContainer,
  FormGroup,
  Buton,
  ModalContent,
} from "./Styles";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

/* dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Inputs } from "../../Input/Inputs";
import { Link } from "react-router-dom";
import { ModalChofer } from "../ModalChofer/ModalChofer";
import { ModalCarga } from "../ModalCarga/ModalCarga";
import { ModalTransportista } from "../ModalTransportista/ModalTransportista";
import { ModalLugar } from "../ModalLugar/ModalLugar";
import { ModalPatenteChasis } from "../ModalPatenteChasis/ModalPatenteChasis";
import { ModalPatenteAcoplado } from "../ModalPatenteAcoplado/ModalPatenteAcoplado";
import { ModalTalonario } from "../ModalTalonario/ModalTalonario";

import {
  getOperacionesOffline,
  cargarOperacionOffline,
} from "../../../Services";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#C8C8C8",
    color: "#000",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

/* Validacion de inputs con Formik */
const validationSchema = yup.object({});

export const ModalCargarOperacion = ({ onClose }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOperacionesOffline());
  }, [dispatch]);

  const { failed_tickets } = useSelector((state) => state.operaciones_fallidas);
  const operaciones = useSelector((state) => state.operaciones_offline);
  const filteredOperaciones = operaciones?.filter((operacion) =>
    failed_tickets?.some((ticket) => ticket.ticket === operacion.numero_ticket)
  );
  const { cargas } = useSelector((state) => state.cargas);
  const { transportistas } = useSelector((state) => state.transportistas);
  const { choferes } = useSelector((state) => state.choferes);
  const { origenes } = useSelector((state) => state.origenes);
  const { destinos } = useSelector((state) => state.destinos);
  const { chasis } = useSelector((state) => state.chasis);
  const { acoplados } = useSelector((state) => state.acoplados);
  const [showModalChofer, setShowModalChofer] = useState(false);
  const [showModalTransportista, setShowModalTransportista] = useState(false);
  const [showModalLugar, setShowModalLugar] = useState(false);
  const [showModalPatenteChasis, setShowModalPatenteChasis] = useState(false);
  const [showModalPatenteAcoplado, setShowModalPatenteAcoplado] =
    useState(false);
  const [showModalCarga, setShowModalCarga] = useState(false);
  const [showModalTalonario, setShowModalTalonario] = useState(false);

  const EditableRow = ({ row, cargarOperacionOffline }) => {
    const extractChasisAndAcoplado = (patente) => {
      const [chasis, acoplado] = patente.split("/");
      return { chasis, acoplado };
    };

    const formatDate = (date) => {
      if (!date) return "";
      const d = new Date(date);
      const month = String(d.getMonth() + 1).padStart(2, "0");
      const day = String(d.getDate()).padStart(2, "0");
      return `${d.getFullYear()}-${month}-${day}`;
    };

    const errors =
      failed_tickets.find((ticket) => ticket.ticket === row.numero_ticket)
        ?.missing || {};

    const formik = useFormik({
      initialValues: {
        numero_ticket: row.numero_ticket,
        remito: row?.remito,
        peso_tn: row?.peso_tn,
        vol_m3: row?.vol_m3,
        hora_de_entrada: row?.hora_de_entrada,
        hora_de_salida: row?.hora_de_salida,
        chofer: row?.chofer?.toUpperCase(),
        empresa: row?.empresa?.toUpperCase(),
        material: row?.material?.toUpperCase(),
        origen: row?.origen?.toUpperCase(),
        destino: row?.destino?.toUpperCase(),
        guia: row?.guia_n,
        chasis: extractChasisAndAcoplado(row.patente).chasis?.toUpperCase(),
        acoplado: extractChasisAndAcoplado(row.patente).acoplado?.toUpperCase(),
        fecha: formatDate(row.fecha),
      },
      validationSchema: validationSchema,

      onSubmit: (values) => {
        dispatch(
          cargarOperacionOffline({
            numero_ticket: values.numero_ticket,
            fecha: values.fecha,
            guia_n: values.guia,
            remito: values.remito,
            chofer: values.chofer,
            dni: row.dni,
            empresa: values.empresa,
            material: values.material,
            origen: values.origen,
            destino: values.destino,
            peso_tn: values.peso_tn,
            vol_m3: values.vol_m3,
            hora_de_entrada: values.hora_de_entrada,
            hora_de_salida: values.hora_de_salida,
            patente: `${values.chasis}/${values.acoplado}`,
            peso_tn_entrada: row.peso_tn_entrada,
            peso_tn_salida: row.peso_tn_salida,
            is_offline: row.is_offline,
          })
        );

        onClose();
      },
    });

    const renderInputError = (field) => {
      const fieldError =
        errors[field] || (formik.errors[field] && formik.touched[field]);

      if (fieldError) {
        if (
          field === "empresa" ||
          field === "material" ||
          field === "origen" ||
          field === "destino" ||
          field === "chofer" ||
          field === "chasis" ||
          field === "acoplado"
        ) {
          let errorMessage = `${
            errors[field] || formik.errors[field]
          } no existe en el sistema. Debes cargarlo. `;

          // Definir el componente modal correspondiente según el campo
          let modalComponent = null;
          switch (field) {
            case "empresa":
              modalComponent = ModalTransportista;
              break;
            case "material":
              modalComponent = ModalCarga;
              break;
            case "origen":
            case "destino":
              modalComponent = ModalLugar;
              break;
            case "chofer":
              modalComponent = ModalChofer;
              break;
            case "chasis":
              modalComponent = ModalPatenteChasis;
              break;
            case "acoplado":
              modalComponent = ModalPatenteAcoplado;
              break;
            default:
              modalComponent = null;
          }

          // Renderizar el mensaje de error con el enlace correspondiente
          return (
            <FormHelperText error>
              {errorMessage}
              {modalComponent && (
                <Link
                  to="#"
                  onClick={() => {
                    if (field === "empresa") setShowModalTransportista(true);
                    if (field === "material") setShowModalCarga(true);
                    if (field === "origen" || field === "destino")
                      setShowModalLugar(true);
                    if (field === "chofer") setShowModalChofer(true);
                    if (field === "chasis") setShowModalPatenteChasis(true);
                    if (field === "acoplado") setShowModalPatenteAcoplado(true);
                  }}
                >
                  Cargar
                </Link>
              )}
            </FormHelperText>
          );
        } else {
          let errorMessage = `${
            errors[field] || formik.errors[field]
          }. Debes cargarlo. `;
          // Definir el componente modal correspondiente según el campo
          let modalComponent = null;
          switch (field) {
            case "guia":
              modalComponent = ModalTalonario;
              break;
            default:
              modalComponent = null;
          }
          return (
            <FormHelperText error>
              {errorMessage}
              {modalComponent && (
                <Link
                  to="#"
                  onClick={() => {
                    if (field === "guia") setShowModalTalonario(true);
                  }}
                >
                  Cargar
                </Link>
              )}
            </FormHelperText>
          );
        }
      }

      return null;
    };

    const SelectInput = ({
      label,
      name,
      value,
      onChange,
      options,
      error,
      disabled,
    }) => {
      const handleSelectChange = (event, selectedOption) => {
        onChange({
          target: {
            name,
            value: selectedOption ? selectedOption.value : "",
          },
        });
      };

      return (
        <FormControl fullWidth>
          <Autocomplete
            options={options}
            getOptionLabel={(option) => option.label}
            value={options.find((option) => option.value === value) || null}
            onChange={handleSelectChange}
            disabled={disabled}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                error={error}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: "100%" }}
              />
            )}
          />
          {error && <FormHelperText error>{error}</FormHelperText>}
        </FormControl>
      );
    };

    return (
      <TableRow>
        <TableCell>
          <FormGroup fullWidth>
            <Inputs
              type="number"
              name="numero_ticket"
              value={formik.values.numero_ticket}
              onChange={formik.handleChange}
              error={Boolean(
                errors.ticket ||
                  (formik.errors.numero_ticket && formik.touched.numero_ticket)
              )}
            />
            {renderInputError("ticket")}
          </FormGroup>
        </TableCell>
        <TableCell>
          <FormGroup>
            <Inputs
              type="date"
              name="fecha"
              value={formik.values.fecha}
              onChange={formik.handleChange}
              error={Boolean(
                errors.fecha || (formik.errors.fecha && formik.touched.fecha)
              )}
            />
            {renderInputError("fecha")}
          </FormGroup>
        </TableCell>
        <TableCell>
          <FormGroup>
            <Inputs
              type="time"
              name="hora_de_entrada"
              value={formik.values.hora_de_entrada}
              onChange={formik.handleChange}
              error={Boolean(
                errors.hora_de_entrada ||
                  (formik.errors.hora_de_entrada &&
                    formik.touched.hora_de_entrada)
              )}
            />
            {renderInputError("hora_de_entrada")}
          </FormGroup>
        </TableCell>
        <TableCell>
          <FormGroup>
            <Inputs
              type="time"
              name="hora_de_salida"
              value={formik.values.hora_de_salida}
              onChange={formik.handleChange}
              error={Boolean(
                errors.hora_de_salida ||
                  (formik.errors.hora_de_salida &&
                    formik.touched.hora_de_salida)
              )}
            />
            {renderInputError("hora_de_salida")}
          </FormGroup>
        </TableCell>
        <TableCell>
          <SelectInput
            name={"empresa"}
            type={"text"}
            value={formik.values.empresa}
            onChange={formik.handleChange}
            options={transportistas?.map((transportista) => ({
              label: transportista.nombre,
              value: transportista.nombre,
            }))}
            error={Boolean(
              errors.empresa ||
                (formik.errors.empresa && formik.touched.empresa)
            )}
          />
          {renderInputError("empresa")}
        </TableCell>
        <TableCell>
          <SelectInput
            name={"chasis"}
            value={formik.values.chasis}
            onChange={formik.handleChange}
            options={chasis?.map((chasis) => ({
              label: chasis.patente,
              value: chasis.patente,
            }))}
            error={Boolean(
              errors.chasis || (formik.errors.chasis && formik.touched.chasis)
            )}
          />
          {renderInputError("chasis")}
        </TableCell>
        <TableCell>
          <SelectInput
            name={"acoplado"}
            value={formik.values.acoplado}
            onChange={formik.handleChange}
            options={acoplados?.map((acoplado) => ({
              label: acoplado.patente,
              value: acoplado.patente,
            }))}
            error={Boolean(
              errors.acoplado ||
                (formik.errors.acoplado && formik.touched.acoplado)
            )}
          />
          {renderInputError("acoplado")}
        </TableCell>
        <TableCell>
          <SelectInput
            name={"chofer"}
            type={"text"}
            value={formik.values.chofer}
            onChange={formik.handleChange}
            options={choferes?.map((chofer) => ({
              label: chofer.nombre_apellido,
              value: chofer.nombre_apellido,
            }))}
            error={Boolean(
              errors.chofer || (formik.errors.chofer && formik.touched.chofer)
            )}
          />
          {renderInputError("chofer")}
        </TableCell>
        {/* <TableCell>
          <Inputs
            name={"dni"}
            type={"number"}
            value={formik.values.dni}
            onChange={formik.handleChange}
            error={Boolean(
              errors.dni || (formik.errors.dni && formik.touched.dni)
            )}
          />
          {renderInputError("dni")}
        </TableCell> */}
        <TableCell>
          <SelectInput
            name={"material"}
            type={"text"}
            value={formik.values.material}
            onChange={formik.handleChange}
            options={cargas?.map((carga) => ({
              label: carga.nombre,
              value: carga.nombre,
            }))}
            error={Boolean(
              errors.material ||
                (formik.errors.material && formik.touched.material)
            )}
          />
          {renderInputError("material")}
        </TableCell>
        <TableCell>
          <FormGroup>
            <Inputs
              type="number"
              name="peso_tn"
              value={formik.values.peso_tn}
              onChange={formik.handleChange}
              error={Boolean(
                errors.peso_tn ||
                  (formik.errors.peso_tn && formik.touched.peso_tn)
              )}
            />
            {renderInputError("peso_tn")}
          </FormGroup>
        </TableCell>
        <TableCell>
          <FormGroup>
            <Inputs
              type="number"
              name="vol_m3"
              value={formik.values.vol_m3}
              onChange={formik.handleChange}
              error={Boolean(
                errors.vol_m3 || (formik.errors.vol_m3 && formik.touched.vol_m3)
              )}
            />
            {renderInputError("vol_m3")}
          </FormGroup>
        </TableCell>
        <TableCell>
          <SelectInput
            name={"origen"}
            type={"text"}
            value={formik.values.origen}
            onChange={formik.handleChange}
            options={origenes?.map((origen) => ({
              label: origen.nombre,
              value: origen.nombre,
            }))}
            error={Boolean(
              errors.origen || (formik.errors.origen && formik.touched.origen)
            )}
          />
          {renderInputError("origen")}
        </TableCell>
        <TableCell>
          <SelectInput
            name={"destino"}
            type={"text"}
            value={formik.values.destino}
            onChange={formik.handleChange}
            options={destinos?.map((destino) => ({
              label: destino.nombre,
              value: destino.nombre,
            }))}
            error={Boolean(
              errors.destino ||
                (formik.errors.destino && formik.touched.destino)
            )}
          />
          {renderInputError("destino")}
        </TableCell>
        <TableCell>
          <FormGroup>
            <Inputs
              name="guia"
              value={formik.values.guia}
              onChange={formik.handleChange}
              error={Boolean(
                errors.guia || (formik.errors.guia && formik.touched.guia)
              )}
            />
            {renderInputError("guia")}
          </FormGroup>
        </TableCell>
        <TableCell>
          <FormGroup>
            <Inputs
              type="number"
              name="remito"
              value={formik.values.remito}
              onChange={formik.handleChange}
              error={Boolean(
                errors.remito || (formik.errors.remito && formik.touched.remito)
              )}
            />
            {renderInputError("remito")}
          </FormGroup>
        </TableCell>
        <TableCell>
          <Buton onClick={() => formik.handleSubmit()} variant="contained">
            Cargar
          </Buton>
        </TableCell>
      </TableRow>
    );
  };

  const handleClose = () => {
    onClose();
  };

  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper onClose={() => handleClose()}>
        <Head>
          <p>Validar operaciones offline</p>
          <IconContainer>
            <FontAwesomeIcon icon={faTimes} onClick={onClose} />
          </IconContainer>
        </Head>
        <ModalContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{ minWidth: 100 }}>
                      Número de ticket
                    </StyledTableCell>
                    <StyledTableCell>Fecha</StyledTableCell>
                    <StyledTableCell>Hora de entrada</StyledTableCell>
                    <StyledTableCell>Hora de salida</StyledTableCell>
                    <StyledTableCell style={{ minWidth: 175 }}>
                      Empresa
                    </StyledTableCell>
                    <StyledTableCell style={{ minWidth: 175 }}>
                      Chasis
                    </StyledTableCell>
                    <StyledTableCell style={{ minWidth: 175 }}>
                      Acoplado
                    </StyledTableCell>
                    <StyledTableCell style={{ minWidth: 300 }}>
                      Chofer
                    </StyledTableCell>
                    {/* <StyledTableCell style={{ minWidth: 150 }}>
                      DNI
                    </StyledTableCell> */}
                    <StyledTableCell style={{ minWidth: 200 }}>
                      Material
                    </StyledTableCell>
                    <StyledTableCell>Peso (tn)</StyledTableCell>
                    <StyledTableCell>Volumen (m3)</StyledTableCell>
                    <StyledTableCell style={{ minWidth: 225 }}>
                      Origen
                    </StyledTableCell>
                    <StyledTableCell style={{ minWidth: 225 }}>
                      Destino
                    </StyledTableCell>
                    <StyledTableCell style={{ minWidth: 150 }}>
                      Guía N°
                    </StyledTableCell>
                    <StyledTableCell style={{ minWidth: 100 }}>
                      Remito
                    </StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredOperaciones?.map((row) => (
                    <EditableRow
                      key={row.numero_ticket}
                      row={row}
                      cargarOperacionOffline={cargarOperacionOffline}
                    />
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </ModalContent>
        {showModalChofer && (
          <ModalChofer onClose={() => setShowModalChofer(!showModalChofer)} />
        )}
        {showModalTransportista && (
          <ModalTransportista
            onClose={() => setShowModalTransportista(!showModalTransportista)}
          />
        )}
        {showModalLugar && (
          <ModalLugar onClose={() => setShowModalLugar(!showModalLugar)} />
        )}
        {showModalPatenteChasis && (
          <ModalPatenteChasis
            onClose={() => setShowModalPatenteChasis(!showModalPatenteChasis)}
          />
        )}
        {showModalPatenteAcoplado && (
          <ModalPatenteAcoplado
            onClose={() =>
              setShowModalPatenteAcoplado(!showModalPatenteAcoplado)
            }
          />
        )}
        {showModalCarga && (
          <ModalCarga onClose={() => setShowModalCarga(!showModalCarga)} />
        )}
        {showModalTalonario && (
          <ModalTalonario
            onClose={() => setShowModalTalonario(!showModalTalonario)}
          />
        )}
      </ModalWrapper>
    </Background>,
    document.getElementById("root")
  );
};

export default ModalCargarOperacion;
