/* eslint-disable no-sequences */
import React from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  Background,
  ModalWrapper,
  HeadModal,
  ContainerButtons,
} from "./Styles";

/* Componentes */
import ButtonPrimary from "../../Components/ButtonPrimary/ButtonPrimary";

export const ModalErrorLogin = ({ onClose, response }) => {
  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper onClose={onClose}>
        <HeadModal>
          {response.status && <h2>Error de autenticación</h2>}
          {/* {response.status === 402 && <h2>No se ha podido iniciar la Sesión</h2>} */}
          <FontAwesomeIcon icon={faTimes} onClick={onClose} />
        </HeadModal>
        {response && (
          <div className="msm_error">
            {/* <FontAwesomeIcon icon={faExclamationTriangle} onClick={onClose}/>  */}
            <p>
              {response.status === 401 &&
                "Usuario o contraseña incorrecta. Vuelve a intentarlo o solicita soporte al equipo de soporte de IT de Vista"}
            </p>
          </div>
        )}
        {/* {
              response.status === 402 
              && <p>Ponte en contacto con el equipo de supply chain para obtener ayuda.</p>
            } */}
        <ContainerButtons>
          <ButtonPrimary
            type="primary"
            textArea={
              response.status ? "Reintentar" : "Aceptar"
            } /* Acà se puede validar el tipo de boton */
            onClick={onClose}
            variant="contained"
            color="primary"
          />
        </ContainerButtons>
      </ModalWrapper>
    </Background>,
    document.getElementById("root")
  );
};

export const ModalForgotPassword = ({ onClose, showForgot }) => {
  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper onClose={onClose} height="true">
        <HeadModal mbottom="true">
          {showForgot && <h2>No se ha podido iniciar la Sesión</h2>}
          <FontAwesomeIcon icon={faTimes} onClick={onClose} />
        </HeadModal>
        {showForgot && (
          <p mtop="true">
            Si Ud. olvidó su contraseña, por favor contactar al equipo de
            soporte de IT de Vista, en caso de estar en horario fuera de
            oficina, fin de semana o feriado, por favor contactar a la guardia
            de IT.
          </p>
        )}
        <ContainerButtons>
          <ButtonPrimary
            type="primary"
            textArea="Aceptar" /* Acà se puede validar el tipo de boton */
            onClick={onClose}
            variant="contained"
            color="primary"
          />
        </ContainerButtons>
      </ModalWrapper>
    </Background>,
    document.getElementById("root")
  );
};
